import { autoinject } from 'aurelia-framework'
import { ToastService } from '../../services/toast-service'

import styles from './toast-overlay.module.scss'

@autoinject()
export class ToastOverlay {
  styles = styles

  constructor(private readonly notificationService: ToastService) {}
}
