import { bindable, computedFrom } from 'aurelia-framework'
import styles from './fa-icon.module.scss'

export class FaIcon {
  @bindable icon: string
  @bindable alt: string
  @bindable innerClass: string
  @bindable size?: '1' | '2' | '3' = undefined

  /**
   * We often use a font size of 16px with a icon-size of 20px.
   * To keep icons aligned, they can be shifted 2px downwards
   **/
  @bindable correctBaseline = false

  styles = styles

  @computedFrom('size')
  get sizeClass() {
    if (!this.size) return undefined
    return styles[`size_${this.size}` as const]
  }
}
