import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Facility, InspectionConfig, NotificationConfig } from '@nubix/spica-cloud-backend-client'
import { SseHandler } from '_utils/sseHandler'
import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import Dropzone from 'dropzone'
import { SUPPORTED_COUNTRYS } from 'localization'
import * as _ from 'lodash'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { first } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { FacilityService } from 'services/facility-service'
import tippy, { Instance } from 'tippy.js'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { FormCache } from '../_utils/FormCache'
import { findNavigationToRoute } from '../_utils/routing'
import {
  excludeSecondsFromTimeString,
  formatWeekday,
  getWeekdayListFromSelection
} from '../_utils/timeUtils'
import { logError } from '../_utils/utils'
import { getFloorplanUploadUrl } from '../main'
import { cpFacilityStatic, cpFacilityStatus } from '../model/utils'
import { FacilityGroupService } from '../services/facility-group-service'
import { InspectionConfigService } from '../services/inspection-config-service'
import { MobileService } from '../services/mobile-service'

const LOG = getLogger('FacilityEdit')

export interface IFacilityParams {
  /** Id of the facility to edit */
  facilityId: string
  /**
   * Back navigation option
   */
  back?: 'facilities' | 'devices' | 'back'
}

/**
 * This route allows the user to edit an existing facility.
 */
@autoinject()
export class FacilityEdit extends subscriberMixin() {
  // region Refs

  public formRoot?: HTMLFormElement
  public dropzoneRoot?: HTMLDivElement
  public fileInput?: HTMLInputElement
  public dropzone?: Dropzone
  public popupFileRoot?: HTMLElement
  public popupFile: Instance

  // endregion

  public countryOptions = SUPPORTED_COUNTRYS

  private _floorplan: FileList
  private _cachedFile?: string

  private _cachedFacility: Facility
  private archivedFacility: Facility

  private _notificationConfig = new FormCache<NotificationConfig>()
  /** Email suggestions are shared between the different tag-fields */
  private _suggestedEmails: string[] = []

  private _cachedInspectionConfig: InspectionConfig

  private updateHandler?: SseHandler

  /**
   * Helper to trigger fileName calculation
   */
  private fileName = ''

  //region Params
  private back?: 'facilities' | 'devices' | 'back'
  private facilityId: number
  //endregion

  //region Derived State
  /**
   * Provides access to the stored facility for the HTML page (aurelia binding)
   */
  get details() {
    return this._cachedFacility
  }

  @computedFrom('_notificationConfig.workingCopy')
  get config() {
    return this._notificationConfig.workingCopy
  }

  @computedFrom('_cachedInspectionConfig')
  get inspectionConfigDescription(): {
    weekdays: string
    timeframe: string
    scheduledDurationTests: string[]
  } {
    const plan = this._cachedInspectionConfig
    const format = excludeSecondsFromTimeString

    return {
      weekdays: getWeekdayListFromSelection(plan.weekdays).map(formatWeekday).join(', '),
      timeframe: `${format(plan.startTime)} - ${format(plan.endTime)}`,
      scheduledDurationTests: plan.scheduledDurationTests.map((date) => moment(date).format('LL'))
    }
  }

  @computedFrom('_floorplan', 'fileName', '_cachedFacility.maps.floorPlanFile')
  get planName(): string | undefined {
    if (this._floorplan && this._floorplan.length > 0) {
      return this._floorplan.item(0)?.name
    }
    if (this._cachedFacility) {
      return this._cachedFacility.maps.floorPlanFile
    }

    return undefined
  }

  @computedFrom(
    '_cachedFacility.name',
    '_cachedFacility.address.country',
    '_cachedFacility.address.zipCode',
    '_cachedFacility.address.streetNumber',
    '_cachedFacility.address.street',
    '_cachedFacility.address.city',
    'archivedLuminaire',
    '_cachedFacility.manager.firstName',
    '_cachedFacility.manager.lastName',
    '_cachedFacility.manager.email',
    '_cachedFacility.manager.phone'
  )
  public get dirty(): boolean {
    if (!this._cachedFacility || !this.archivedFacility) return false

    return !(
      this._cachedFacility.name === this.archivedFacility.name &&
      this._cachedFacility.address.country === this.archivedFacility.address.country &&
      this._cachedFacility.address.zipCode === this.archivedFacility.address.zipCode &&
      this._cachedFacility.address.streetNumber === this.archivedFacility.address.streetNumber &&
      this._cachedFacility.address.street === this.archivedFacility.address.street &&
      this._cachedFacility.address.city === this.archivedFacility.address.city &&
      this._cachedFacility.manager.firstName === this.archivedFacility.manager.firstName &&
      this._cachedFacility.manager.lastName === this.archivedFacility.manager.lastName &&
      this._cachedFacility.manager.email === this.archivedFacility.manager.email &&
      this._cachedFacility.manager.phone === this.archivedFacility.manager.phone
    )
  }

  @computedFrom('backNavigation')
  get backLabel() {
    if (this.back === 'back') return 'Zurück'
    if (this.back === 'devices') return 'Geräteübersicht'

    return 'Objekte'
  }

  //endregion

  // region Lifecycle

  constructor(
    private readonly router: Router,
    private readonly authServ: AuthService,
    private readonly _facilityGroupService: FacilityGroupService,
    private readonly _facilityService: FacilityService,
    private readonly _mobileService: MobileService,
    private readonly _inspectionService: InspectionConfigService
  ) {
    super()
  }

  /**
   * Load and display information from server and begin autorefresh
   * @param params - Parameters to be passed via the url of the page. See {@link IFacilityParams}
   */
  public async activate(params: IFacilityParams) {
    this.facilityId = Number(params.facilityId)
    this.back = params.back

    const facility$ = this._facilityService.facilityEntityCache.get$(this.facilityId)
    const notificationConfig$ = this._facilityService.notificationConfigCache.get$(this.facilityId)
    const inspectionConfig$ = this._inspectionService.inspectionConfigEntityCache.get$(
      this.facilityId
    )

    this.subscribeUntilDeactivated({
      to: facility$,
      onNext: (it) => {
        if (it === undefined) return this.navigateUp()
        this.dirty ? this.updateNonEdit(it) : this.updateFull(it)
      }
    })

    this._notificationConfig.setSource(notificationConfig$)

    this.subscribeUntilDeactivated({
      to: inspectionConfig$,
      onNext: (it) => (this._cachedInspectionConfig = it),
      onError: logError(LOG)
    })

    // wait until each server request has some data to show
    await Promise.all([
      facility$.pipe(first()).toPromise(),
      notificationConfig$.pipe(first()).toPromise(),
      inspectionConfig$.pipe(first()).toPromise()
    ])

    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.revalidate(this.facilityId)
      this._facilityService.notificationConfigCache.revalidate(this.facilityId)
    }, 200)

    const fullRevalidation = async () => {
      // reset dirty state / all temporary changes are lost
      this.updateFull(this._cachedFacility)
      revalidateCache()
    }

    const listener4Del = (event: any) => {
      if (event.type === 'deletedfacility') {
        // revalidate cache since facility was deleted
        revalidateCache()
        // invalidate facility list
        this._facilityService.facilityQueryCache.invalidate(undefined)
        // leave page
        this.navigateUp()
      }
    }

    this.updateHandler = new SseHandler(`/events/facility/${this.facilityId}`, this.authServ)
    this.updateHandler.addEventListener('deletedfacility', listener4Del)
    this.updateHandler.addEventListener('statusfacility', revalidateCache)
    this.updateHandler.addEventListener('changefms', revalidateCache)
    this.updateHandler.addEventListener('datafacility', fullRevalidation)
    this.updateHandler.onReconnect(fullRevalidation)
    this.updateHandler.connectStream()
  }

  /**
   * Unregister SSE connection to the server.
   */
  public override deactivate() {
    super.deactivate()
    this._notificationConfig.dispose()
    this.updateHandler?.close()
  }

  attached() {
    this.dropzone = new Dropzone(this.dropzoneRoot!, {
      maxFiles: 1,
      maxFilesize: 10,
      autoProcessQueue: false,
      url: getFloorplanUploadUrl(this._cachedFacility.id, '', this.authServ.sessionToken),
      accept: (file, done) => {
        if (file) {
          const list = new DataTransfer()
          list.items.add(file)
          this._floorplan = list.files
          this.fileName = file.name

          done('no')
          // we have to remove all files since error does not prevent adding files...
          // we do not use dropzones file list
          this.dropzone?.removeAllFiles()
        } else {
          done()
        }
      }
    })
    // `click.delegate` breaks the file dialog. Therefore we manually add a listener here.
    this.dropzoneRoot?.addEventListener('click', () => {
      this.floorplanClicked()
      return true
    })

    // check validity every time the file input changes
    this.fileInput?.addEventListener('change', (e) => {
      this.fileInput?.setCustomValidity('')

      const fileList = (e.target as HTMLInputElement).files!

      const file = fileList.item(0)

      if (!file) {
        return
      }

      if (file.size > 10_485_760) {
        this.fileInput?.setCustomValidity('Datei ist größer als 8 MB.')
      }

      this.fileName = file.name
    })

    // create popup to let user accept picked file (after page reload)
    if (this.dropzoneRoot) {
      this.popupFile = tippy(this.dropzoneRoot, {
        trigger: 'manual',
        content: this.popupFileRoot,
        interactive: true,
        role: 'popover',
        placement: 'top',
        animation: 'shift-away',
        arrow: true
      }) as Instance
    }

    // trigger file picker, we do this because the mobile service tells us that there is a unfinished file picker
    // action, BUT you can't open file picker programmatic so we need to have the user click it again
    this._cachedFile = this._mobileService.getProperty('floorplan')
    if (this._cachedFile) {
      setTimeout(() => this.popupFile.show(), 1000)
    }
  }

  // endregion

  //region Events
  public floorplanClicked() {
    this.fileInput?.click()
  }

  public onDeleteFloorplan() {
    if (!this.planName || !this._cachedFacility.maps.floorPlanFile) {
      throw Error('Keine Datei zum Löschen.')
    }

    const results: Promise<void>[] = []

    results.push(this._facilityService.deleteFloorPlan(this._cachedFacility.id))

    return Promise.all(results).catch((e) => {
      throw new Error(e.message ?? 'Fehler beim Speichern')
    })
  }

  public onSaveFloorplan() {
    if (!this.planName || !this._floorplan || this._floorplan.length < 1) {
      throw Error('Fehlende Datei zum Hochladen.')
    }
    const file = this._floorplan.item(0)
    if (!file || file.size > 10_485_760) {
      throw Error('Datei ist größer als 10 MB')
    }

    const results: Promise<void>[] = []

    results.push(
      this._facilityService.uploadFloorPlan(this._cachedFacility.id, this._floorplan, this.planName)
    )

    return Promise.all(results).catch((e) => {
      throw new Error(e.message ?? 'Fehler beim Speichern')
    })
  }

  public onAcceptFloorplan() {
    this._cachedFile = undefined
    this.popupFile.hide()

    // open file picker to select cached file
    this.floorplanClicked()
  }

  public onCancelFlooplan() {
    this._cachedFile = undefined
    this.popupFile.hide()

    // remove cached file in mobile app
    this._mobileService.deleteProperty('floorplan')
  }

  /**
   * Submit changes to the server.
   */
  public async onSave(): Promise<ActionResult> {
    if (!this.formRoot || !FormCache.isSuccess(this._notificationConfig)) return

    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()
      throw new Error()
    }

    const results: Promise<unknown>[] = []

    const updateFacilityResult = this._facilityService.updateEntity(this._cachedFacility)
    results.push(updateFacilityResult)

    const config = this._notificationConfig.workingCopy
    const updateConfigResult = this._facilityService.updateConfig(this.facilityId, config)
    results.push(updateConfigResult)

    // reset dirty state / all temporary changes are lost
    this.updateFull(this._cachedFacility) // Return the Promise to the Button. It will show a success notification.

    await Promise.all(results)
  }

  private onTestPlanEditButtonClicked() {
    this.router.navigateToRoute('inspection-config-edit', { facilityId: this.facilityId })
  }

  //endregion

  /**
   * Navigate up to the facility list
   */
  public navigateUp() {
    switch (this.back) {
      case 'devices':
        findNavigationToRoute(this.router, 'devices', { id: this._cachedFacility.id })
        break
      case 'back':
        this.router.navigateBack()
        break
      default:
        findNavigationToRoute(this.router, 'facilities').catch(async () => undefined)
    }
  }

  /**
   * Update all shown information with data from backend. Might trigger a visible refresh of the page. Will
   * refresh data in editable input fields too. Will reset dirty state.
   */
  private updateFull(update?: Facility) {
    if (!update) return

    this._cachedFacility = cloneDeep(update)
    // we have to copy it
    this.archivedFacility = update
    // clear upload
    this._floorplan = new DataTransfer().files
  }

  private async updateNonEdit(update?: Facility) {
    if (!this._cachedFacility) return
    if (!update) return

    // state too
    cpFacilityStatus(this._cachedFacility, update)
    // non editable infos
    cpFacilityStatic(this._cachedFacility, update)
  }
}
