import { Facility } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { computedFrom } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { bindable } from 'aurelia-templating'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { ModalService } from '../_controls/presentation/layout/modal-service'
import { findNavigationToRoute } from '../_utils/routing'
import { AuthService } from '../services/auth-service'
import { FacilityService } from '../services/facility-service'
import { EmergencyBlockPrompt } from './emergency-block-prompt'

/**
 * A cell for a single facility to be displayed in {@link FacilityList}
 */
@autoinject
export class FacilityListCell {
  @bindable public facility: Facility

  public dropdownRoot: HTMLUListElement
  public dropdownTrigger: HTMLButtonElement

  @computedFrom('facility')
  get may() {
    return getPermissionTable(this.facility.myRole)
  }

  constructor(
    public readonly router: Router,
    private readonly facilityService: FacilityService,
    private readonly authService: AuthService,
    private readonly modalService: ModalService
  ) {}

  /**
   * Navigate to the facility-edit route.
   * @param facilityId - id of the facility to be edited
   */
  async onEditClicked(facilityId: number) {
    await findNavigationToRoute(this.router, 'facility-edit', { facilityId })
  }

  openBlockingModal() {
    this.modalService.showModal({
      viewModel: EmergencyBlockPrompt,
      model: { facility: this.facility }
    })
  }

  /**
   * Hide all open popups.
   */
  onCancel() {
    hideAll()
  }
}
