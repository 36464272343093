exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2ItRGORWc2TegsuqX1OAG-{position:fixed;top:0;right:0;bottom:0;left:0;z-index:9999999;overflow:scroll;background:rgba(73,89,101,.66);display:flex;flex-direction:column;align-items:center}@media(min-width:600px){._2ItRGORWc2TegsuqX1OAG-{padding-left:24px;padding-right:24px}}.tJnTGNMmH33oPPZgwlkeU{background:#fff;padding:24px}._3AGKEUUh787x9MCKNz2WS5{display:flex;justify-content:space-between;margin-bottom:24px}._1L8J_dBY8UGpSKwiiXpbm5 .tJnTGNMmH33oPPZgwlkeU{width:min(100%,60em);margin-top:40px}._197SB7vDdLCgjDAsGF6VfH .tJnTGNMmH33oPPZgwlkeU{max-width:60em}._2ItRGORWc2TegsuqX1OAG-._1L8J_dBY8UGpSKwiiXpbm5{justify-content:flex-start}._2ItRGORWc2TegsuqX1OAG-._197SB7vDdLCgjDAsGF6VfH{justify-content:center}", ""]);

// Exports
exports.locals = {
	"container": "_2ItRGORWc2TegsuqX1OAG-",
	"modal": "tJnTGNMmH33oPPZgwlkeU",
	"titleBar": "_3AGKEUUh787x9MCKNz2WS5",
	"widthWide": "_1L8J_dBY8UGpSKwiiXpbm5",
	"widthDefault": "_197SB7vDdLCgjDAsGF6VfH"
};