import { autoinject } from 'aurelia-framework'
import { bindable } from 'aurelia-templating'
import tippy, { Instance as TippyInstance } from 'tippy.js'
import { AureliaComponent } from '../../_utils/AureliaComponent'
import { Device, DeviceId, DeviceType, getDeviceId, isSameDeviceId } from '../../model/device'
import styles from './device-list-view.module.scss'

@autoinject()
export class DeviceListView extends AureliaComponent {
  styles = styles

  public addDropdownRoot?: HTMLElement
  public addTriggerRoot?: HTMLElement
  public tippy?: TippyInstance

  @bindable() selectedDevices:
    | undefined // Selection isn't supported
    | DeviceId[] // Selection is supported
    | [] // Selection is supported, but no devices are selected

  @bindable() onDeviceClick?: (p: { device: Device }) => void

  @bindable() devices: Device[]

  @bindable() onAdd?: (p: { type: DeviceType }) => void

  deviceMatcher(a?: Device, b?: Device) {
    if (!a || !b) return false
    return isSameDeviceId(getDeviceId(a), getDeviceId(b))
  }

  override attached() {
    super.attached()

    if (this.onAdd)
      this.tippy = tippy(this.addTriggerRoot!, {
        trigger: 'click',
        content: this.addDropdownRoot,
        interactive: true
      }) as TippyInstance
  }

  isDeviceSelected(selectedDevices: DeviceId[], device: Device) {
    if (selectedDevices === undefined) return false
    const x = selectedDevices.some((d) => isSameDeviceId(d, getDeviceId(device)))
    return x
  }
  override detached() {
    super.detached()
    this.tippy?.destroy()
  }

  private cardBodyClicked(device: Device) {
    if (this.selectedDevices === undefined) {
      this.onDeviceClick?.({ device })
      return
    }

    // Toggle device selection
    const deviceId = getDeviceId(device)
    if (this.isDeviceSelected(this.selectedDevices, device))
      this.selectedDevices = this.selectedDevices.filter((d) => !isSameDeviceId(d, deviceId))
    else this.selectedDevices = [...this.selectedDevices, deviceId]
  }
}
