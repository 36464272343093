import { bindingMode, ValueConverter } from 'aurelia-binding'
import { inject } from 'aurelia-dependency-injection'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'
import { SignalBindingBehavior } from 'aurelia-templating-resources'
// moment itself must be imported before the locales
import * as moment from 'moment'

import 'moment/locale/de'
import 'moment/locale/en-gb'
import Moment = moment.Moment

const LOG = getLogger('moment-format')

/**
 * Aurelia converter to convert a timestamp in a localized date text.
 */
@inject(I18N)
export class MomentFormatValueConverter {
  constructor(private readonly i18n: I18N) {}

  public toView(value: Moment | null | undefined, format?: string): string {
    if (value === null || value === undefined) {
      return ''
    }
    let result = ''
    let mom: moment.Moment = moment(value)

    if (mom.isValid()) {
      const locale = this.i18n.getLocale()
      const formatString = format || 'LLL'

      if (locale) {
        mom = mom.locale(locale)
      }

      result = mom.format(formatString)
    }

    return result
  }

  public fromView(value?: string | Date, format?: string): Date {
    return moment(value, format).toDate()
  }
}

/**
 * Aurelia Binding behaviour to make timestamp converter work.
 */
@inject(SignalBindingBehavior)
export class MomentFormatBindingBehavior {
  private readonly signaler: SignalBindingBehavior

  constructor(signalBindingBehavior: SignalBindingBehavior) {
    this.signaler = signalBindingBehavior
  }

  public bind(binding?: any, source?: any): any {
    // bind the signal behavior
    this.signaler.bind(binding, source, 'aurelia-translation-signal')
    binding.mode = bindingMode.toView

    // rewrite the expression to use the MomentFormatValueConverter.
    // pass through any args to the binding behavior to the MomentFormatValueConverter
    const sourceExpression = binding.sourceExpression

    LOG.debug(`binding moment format for ${sourceExpression}`)

    // do create the sourceExpression only once
    if (sourceExpression.rewritten) {
      return
    }
    sourceExpression.rewritten = true

    const expression = sourceExpression.expression
    sourceExpression.expression = new ValueConverter(
      expression,
      'momentFormat',
      sourceExpression.args,
      [expression, ...sourceExpression.args]
    )
  }

  public unbind(binding?: any, source?: any): any {
    // unbind the signal behavior
    this.signaler.unbind(binding, source)
  }
}
