import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { InspectionApi, InspectionConfig } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { FacilityId } from './facility-service'

@autoinject()
export class InspectionConfigService {
  public inspectionConfigEntityCache = new RxCache<FacilityId, InspectionConfig>({
    fetcher: (facilityId) => this.inspectionApi.getInspectionConfig({ facilityId })
  })

  constructor(private readonly inspectionApi: InspectionApi) {}

  public setInspectionConfig(facilityId: FacilityId, inspectionConfig: InspectionConfig) {
    const requestProgress = this.inspectionApi.setInspectionConfig({
      facilityId,
      body: inspectionConfig
    })

    this.inspectionConfigEntityCache.mutate({
      where: (key) => key === facilityId,
      requestProgress
    })

    return requestProgress
  }
}
