import { HttpResponseMessage, Headers } from 'aurelia-http-client'
import { isFunction } from 'lodash'
import * as Sentry from '@sentry/browser'

/**
 * 🤦 The aurelia-http-client throws response and {@link Sentry.captureException}
 * rightfully does not like non-Error errors.
 */
export const reportResponseErr = (
  error: HttpResponseMessage,
  opts: Record<any, any> | undefined,
  message: string | undefined
) => {
  const request = {
    method: error.requestMessage.method,
    url: error.requestMessage.buildFullUrl()
  }
  Sentry.captureMessage(message || errorMessageForResponse(error), {
    level: 'error',
    contexts: {
      request,
      response: {
        type: error.responseType,
        status_code: error.statusCode,
        headers: toHeaders(error.headers)
      }
    },
    extra: {
      ...opts,
      request,
      response: {
        content: JSON.stringify(error.content)
      }
    }
  })
}

function errorMessageForResponse(response: HttpResponseMessage): string {
  const content = response.content
  if (content) {
    if (typeof content === 'string') return content
    if ('toString' in content && isFunction(content.toString)) return content.toString()
  }
  return `${response.requestMessage.method} ${response.requestMessage.buildFullUrl()} ${
    response.statusCode
  }`
}

/**
 * 🤦 The public interface of aurelia {@link Header} is extremely limited.
 * Especially as there is no way to access the actual headers list.
 *
 * But when poking carefully into the implementation details we might find something.
 */
function toHeaders(headers: Headers): Record<string, string> {
  if (!('headers' in headers)) return {}
  const headerObj = headers.headers
  if (!headerObj) return {}
  if (typeof headerObj !== 'object') return {}
  const entries: [string, string][] = []
  for (const [k, v] of Object.keys(headerObj)) {
    if (typeof v !== 'string') continue
    entries.push([k, v])
  }
  return Object.fromEntries(entries)
}


