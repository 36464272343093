/*! modernizr 3.7.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-input-inputtypes-intl-addtest-printshiv-setclasses-testprop-teststyles !*/
!(function (e, t, n) {
  function r(e, t) {
    return typeof e === t
  }
  function i(e) {
    let t = b.className,
      n = Modernizr._config.classPrefix || ''
    if ((_ && (t = t.baseVal), Modernizr._config.enableJSClass)) {
      const r = new RegExp('(^|\\s)' + n + 'no-js(\\s|$)')
      t = t.replace(r, '$1' + n + 'js$2')
    }
    Modernizr._config.enableClasses &&
      (e.length > 0 && (t += ' ' + n + e.join(' ' + n)),
      _ ? (b.className.baseVal = t) : (b.className = t))
  }
  function o(e, t) {
    if ('object' == typeof e) for (const n in e) E(e, n) && o(n, e[n])
    else {
      e = e.toLowerCase()
      let r = e.split('.'),
        a = Modernizr[r[0]]
      if ((2 === r.length && (a = a[r[1]]), void 0 !== a)) return Modernizr
      ;(t = 'function' == typeof t ? t() : t),
        1 === r.length
          ? (Modernizr[r[0]] = t)
          : (!Modernizr[r[0]] ||
              Modernizr[r[0]] instanceof Boolean ||
              (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
            (Modernizr[r[0]][r[1]] = t)),
        i([(t && !1 !== t ? '' : 'no-') + r.join('-')]),
        Modernizr._trigger(e, t)
    }
    return Modernizr
  }
  function a(e, t) {
    return !!~('' + e).indexOf(t)
  }
  function l() {
    return 'function' != typeof t.createElement
      ? t.createElement(arguments[0])
      : _
      ? t.createElementNS.call(t, 'http://www.w3.org/2000/svg', arguments[0])
      : t.createElement.apply(t, arguments)
  }
  function s() {
    let e = t.body
    return e || ((e = l(_ ? 'svg' : 'body')), (e.fake = !0)), e
  }
  function u(e, n, r, i) {
    let o,
      a,
      u,
      c,
      f = 'modernizr',
      d = l('div'),
      p = s()
    if (parseInt(r, 10))
      for (; r--; ) (u = l('div')), (u.id = i ? i[r] : f + (r + 1)), d.appendChild(u)
    return (
      (o = l('style')),
      (o.type = 'text/css'),
      (o.id = 's' + f),
      (p.fake ? p : d).appendChild(o),
      p.appendChild(d),
      o.styleSheet ? (o.styleSheet.cssText = e) : o.appendChild(t.createTextNode(e)),
      (d.id = f),
      p.fake &&
        ((p.style.background = ''),
        (p.style.overflow = 'hidden'),
        (c = b.style.overflow),
        (b.style.overflow = 'hidden'),
        b.appendChild(p)),
      (a = n(d, e)),
      p.fake
        ? (p.parentNode.removeChild(p), (b.style.overflow = c), b.offsetHeight)
        : d.parentNode.removeChild(d),
      !!a
    )
  }
  function c(e) {
    return e
      .replace(/([A-Z])/g, function (e, t) {
        return '-' + t.toLowerCase()
      })
      .replace(/^ms-/, '-ms-')
  }
  function f(t, n, r) {
    let i
    if ('getComputedStyle' in e) {
      i = getComputedStyle.call(e, t, n)
      const o = e.console
      if (null !== i) r && (i = i.getPropertyValue(r))
      else if (o) {
        const a = o.error ? 'error' : 'log'
        o[a].call(
          o,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate'
        )
      }
    } else i = !n && t.currentStyle && t.currentStyle[r]
    return i
  }
  function d(t, r) {
    let i = t.length
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; i--; ) if (e.CSS.supports(c(t[i]), r)) return !0
      return !1
    }
    if ('CSSSupportsRule' in e) {
      for (var o = []; i--; ) o.push('(' + c(t[i]) + ':' + r + ')')
      return (
        (o = o.join(' or ')),
        u('@supports (' + o + ') { #modernizr { position: absolute; } }', function (e) {
          return 'absolute' === f(e, null, 'position')
        })
      )
    }
    return n
  }
  function p(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, t, n) {
        return t + n.toUpperCase()
      })
      .replace(/^-/, '')
  }
  function m(e, t, i, o) {
    function s() {
      c && (delete w.style, delete w.modElem)
    }
    if (((o = !r(o, 'undefined') && o), !r(i, 'undefined'))) {
      const u = d(e, i)
      if (!r(u, 'undefined')) return u
    }
    for (var c, f, m, h, v, g = ['modernizr', 'tspan', 'samp']; !w.style && g.length; )
      (c = !0), (w.modElem = l(g.shift())), (w.style = w.modElem.style)
    for (m = e.length, f = 0; f < m; f++)
      if (((h = e[f]), (v = w.style[h]), a(h, '-') && (h = p(h)), w.style[h] !== n)) {
        if (o || r(i, 'undefined')) return s(), 'pfx' !== t || h
        try {
          w.style[h] = i
        } catch (e) {}
        if (w.style[h] !== v) return s(), 'pfx' !== t || h
      }
    return s(), !1
  }
  function h(e, t) {
    return function () {
      return e.apply(t, arguments)
    }
  }
  function v(e, t, n) {
    let i
    for (const o in e)
      if (e[o] in t) return !1 === n ? e[o] : ((i = t[e[o]]), r(i, 'function') ? h(i, n || t) : i)
    return !1
  }
  function g(e, t, n, i, o) {
    let a = e.charAt(0).toUpperCase() + e.slice(1),
      l = (e + ' ' + z.join(a + ' ') + a).split(' ')
    return r(t, 'string') || r(t, 'undefined')
      ? m(l, t, i, o)
      : ((l = (e + ' ' + L.join(a + ' ') + a).split(' ')), v(l, t, n))
  }
  var y = [],
    C = {
      _version: '3.7.1',
      _config: { classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
      _q: [],
      on: function (e, t) {
        const n = this
        setTimeout(function () {
          t(n[e])
        }, 0)
      },
      addTest: function (e, t, n) {
        y.push({ name: e, fn: t, options: n })
      },
      addAsyncTest: function (e) {
        y.push({ name: null, fn: e })
      }
    },
    Modernizr = function () {}
  ;(Modernizr.prototype = C), (Modernizr = new Modernizr())
  var E,
    S = [],
    b = t.documentElement,
    _ = 'svg' === b.nodeName.toLowerCase()
  !(function () {
    const e = {}.hasOwnProperty
    E =
      r(e, 'undefined') || r(e.call, 'undefined')
        ? function (e, t) {
            return t in e && r(e.constructor.prototype[t], 'undefined')
          }
        : function (t, n) {
            return e.call(t, n)
          }
  })(),
    (C._l = {}),
    (C.on = function (e, t) {
      this._l[e] || (this._l[e] = []),
        this._l[e].push(t),
        Modernizr.hasOwnProperty(e) &&
          setTimeout(function () {
            Modernizr._trigger(e, Modernizr[e])
          }, 0)
    }),
    (C._trigger = function (e, t) {
      if (this._l[e]) {
        const n = this._l[e]
        setTimeout(function () {
          let e
          for (e = 0; e < n.length; e++) (0, n[e])(t)
        }, 0),
          delete this._l[e]
      }
    }),
    Modernizr._q.push(function () {
      C.addTest = o
    })
  const x = { elem: l('modernizr') }
  Modernizr._q.push(function () {
    delete x.elem
  })
  var w = { style: x.elem.style }
  Modernizr._q.unshift(function () {
    delete w.style
  })
  ;(C.testProp = function (e, t, r) {
    return m([e], n, t, r)
  }),
    (C.testStyles = u)
  _ ||
    (function (e, t) {
      function n(e, t) {
        const n = e.createElement('p'),
          r = e.getElementsByTagName('head')[0] || e.documentElement
        return (
          (n.innerHTML = 'x<style>' + t + '</style>'), r.insertBefore(n.lastChild, r.firstChild)
        )
      }
      function r() {
        const e = _.elements
        return 'string' == typeof e ? e.split(' ') : e
      }
      function i(e, t) {
        let n = _.elements
        'string' != typeof n && (n = n.join(' ')),
          'string' != typeof e && (e = e.join(' ')),
          (_.elements = n + ' ' + e),
          u(t)
      }
      function o(e) {
        let t = b[e[E]]
        return t || ((t = {}), S++, (e[E] = S), (b[S] = t)), t
      }
      function a(e, n, r) {
        if ((n || (n = t), v)) return n.createElement(e)
        r || (r = o(n))
        let i
        return (
          (i = r.cache[e]
            ? r.cache[e].cloneNode()
            : C.test(e)
            ? (r.cache[e] = r.createElem(e)).cloneNode()
            : r.createElem(e)),
          !i.canHaveChildren || y.test(e) || i.tagUrn ? i : r.frag.appendChild(i)
        )
      }
      function l(e, n) {
        if ((e || (e = t), v)) return e.createDocumentFragment()
        n = n || o(e)
        for (var i = n.frag.cloneNode(), a = 0, l = r(), s = l.length; a < s; a++)
          i.createElement(l[a])
        return i
      }
      function s(e, t) {
        t.cache ||
          ((t.cache = {}),
          (t.createElem = e.createElement),
          (t.createFrag = e.createDocumentFragment),
          (t.frag = t.createFrag())),
          (e.createElement = function (n) {
            return _.shivMethods ? a(n, e, t) : t.createElem(n)
          }),
          (e.createDocumentFragment = Function(
            'h,f',
            'return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(' +
              r()
                .join()
                .replace(/[\w\-:]+/g, function (e) {
                  return t.createElem(e), t.frag.createElement(e), 'c("' + e + '")'
                }) +
              ');return n}'
          )(_, t.frag))
      }
      function u(e) {
        e || (e = t)
        const r = o(e)
        return (
          !_.shivCSS ||
            h ||
            r.hasCSS ||
            (r.hasCSS = !!n(
              e,
              'article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}'
            )),
          v || s(e, r),
          e
        )
      }
      function c(e) {
        for (
          var t,
            n = e.getElementsByTagName('*'),
            i = n.length,
            o = RegExp('^(?:' + r().join('|') + ')$', 'i'),
            a = [];
          i--;

        )
          (t = n[i]), o.test(t.nodeName) && a.push(t.applyElement(f(t)))
        return a
      }
      function f(e) {
        for (
          var t,
            n = e.attributes,
            r = n.length,
            i = e.ownerDocument.createElement(w + ':' + e.nodeName);
          r--;

        )
          (t = n[r]), t.specified && i.setAttribute(t.nodeName, t.nodeValue)
        return (i.style.cssText = e.style.cssText), i
      }
      function d(e) {
        for (
          var t,
            n = e.split('{'),
            i = n.length,
            o = RegExp('(^|[\\s,>+~])(' + r().join('|') + ')(?=[[\\s,>+~#.:]|$)', 'gi'),
            a = '$1' + w + '\\:$2';
          i--;

        )
          (t = n[i] = n[i].split('}')),
            (t[t.length - 1] = t[t.length - 1].replace(o, a)),
            (n[i] = t.join('}'))
        return n.join('{')
      }
      function p(e) {
        for (let t = e.length; t--; ) e[t].removeNode()
      }
      function m(e) {
        function t() {
          clearTimeout(a._removeSheetTimer), r && r.removeNode(!0), (r = null)
        }
        var r,
          i,
          a = o(e),
          l = e.namespaces,
          s = e.parentWindow
        return !T || e.printShived
          ? e
          : (void 0 === l[w] && l.add(w),
            s.attachEvent('onbeforeprint', function () {
              t()
              for (var o, a, l, s = e.styleSheets, u = [], f = s.length, p = Array(f); f--; )
                p[f] = s[f]
              for (; (l = p.pop()); )
                if (!l.disabled && x.test(l.media)) {
                  try {
                    ;(o = l.imports), (a = o.length)
                  } catch (e) {
                    a = 0
                  }
                  for (f = 0; f < a; f++) p.push(o[f])
                  try {
                    u.push(l.cssText)
                  } catch (e) {}
                }
              ;(u = d(u.reverse().join(''))), (i = c(e)), (r = n(e, u))
            }),
            s.attachEvent('onafterprint', function () {
              p(i), clearTimeout(a._removeSheetTimer), (a._removeSheetTimer = setTimeout(t, 500))
            }),
            (e.printShived = !0),
            e)
      }
      var h,
        v,
        g = e.html5 || {},
        y = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
        C =
          /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
        E = '_html5shiv',
        S = 0,
        b = {}
      !(function () {
        try {
          const e = t.createElement('a')
          ;(e.innerHTML = '<xyz></xyz>'),
            (h = 'hidden' in e),
            (v =
              1 == e.childNodes.length ||
              (function () {
                t.createElement('a')
                const e = t.createDocumentFragment()
                return (
                  void 0 === e.cloneNode ||
                  void 0 === e.createDocumentFragment ||
                  void 0 === e.createElement
                )
              })())
        } catch (e) {
          ;(h = !0), (v = !0)
        }
      })()
      var _ = {
        elements:
          g.elements ||
          'abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video',
        version: '3.7.3',
        shivCSS: !1 !== g.shivCSS,
        supportsUnknownElements: v,
        shivMethods: !1 !== g.shivMethods,
        type: 'default',
        shivDocument: u,
        createElement: a,
        createDocumentFragment: l,
        addElements: i
      }
      ;(e.html5 = _), u(t)
      var x = /^$|\b(?:all|print)\b/,
        w = 'html5shiv',
        T =
          !v &&
          (function () {
            const n = t.documentElement
            return !(
              void 0 === t.namespaces ||
              void 0 === t.parentWindow ||
              void 0 === n.applyElement ||
              void 0 === n.removeNode ||
              void 0 === e.attachEvent
            )
          })()
      ;(_.type += ' print'),
        (_.shivPrint = m),
        m(t),
        'object' == typeof module && module.exports && (module.exports = _)
    })(void 0 !== e ? e : this, t)
  const T = l('input'),
    N = 'autocomplete autofocus list placeholder max min multiple pattern required step'.split(' '),
    j = {}
  Modernizr.input = (function (t) {
    for (let n = 0, r = t.length; n < r; n++) j[t[n]] = !!(t[n] in T)
    return j.list && (j.list = !(!l('datalist') || !e.HTMLDataListElement)), j
  })(N)
  const k =
      'search tel url email datetime date month week time datetime-local number range color'.split(
        ' '
      ),
    P = {}
  Modernizr.inputtypes = (function (e) {
    for (var r, i, o, a = e.length, l = 0; l < a; l++)
      T.setAttribute('type', (r = e[l])),
        (o = 'text' !== T.type && 'style' in T),
        o &&
          ((T.value = '1)'),
          (T.style.cssText = 'position:absolute;visibility:hidden;'),
          /^range$/.test(r) && T.style.WebkitAppearance !== n
            ? (b.appendChild(T),
              (i = t.defaultView),
              (o =
                i.getComputedStyle &&
                'textfield' !== i.getComputedStyle(T, null).WebkitAppearance &&
                0 !== T.offsetHeight),
              b.removeChild(T))
            : /^(search|tel)$/.test(r) ||
              (o = /^(url|email)$/.test(r)
                ? T.checkValidity && !1 === T.checkValidity()
                : '1)' !== T.value)),
        (P[e[l]] = !!o)
    return P
  })(k)
  var $ = 'Moz O ms Webkit',
    z = C._config.usePrefixes ? $.split(' ') : []
  C._cssomPrefixes = z
  var L = C._config.usePrefixes ? $.toLowerCase().split(' ') : []
  ;(C._domPrefixes = L), (C.testAllProps = g)
  const F = function (t) {
    let r,
      i = prefixes.length,
      o = e.CSSRule
    if (void 0 === o) return n
    if (!t) return !1
    if (((t = t.replace(/^@/, '')), (r = t.replace(/-/g, '_').toUpperCase() + '_RULE') in o))
      return '@' + t
    for (let a = 0; a < i; a++) {
      const l = prefixes[a]
      if (l.toUpperCase() + '_' + r in o) return '@-' + l.toLowerCase() + '-' + t
    }
    return !1
  }
  C.atRule = F
  const A = (C.prefixed = function (e, t, n) {
    return 0 === e.indexOf('@')
      ? F(e)
      : (-1 !== e.indexOf('-') && (e = p(e)), t ? g(e, t, n) : g(e, 'pfx'))
  })
  Modernizr.addTest('intl', !!A('Intl', e)),
    (function () {
      let e, t, n, i, o, a, l
      for (const s in y)
        if (y.hasOwnProperty(s)) {
          if (
            ((e = []),
            (t = y[s]),
            t.name &&
              (e.push(t.name.toLowerCase()),
              t.options && t.options.aliases && t.options.aliases.length))
          )
            for (n = 0; n < t.options.aliases.length; n++)
              e.push(t.options.aliases[n].toLowerCase())
          for (i = r(t.fn, 'function') ? t.fn() : t.fn, o = 0; o < e.length; o++)
            (a = e[o]),
              (l = a.split('.')),
              1 === l.length
                ? (Modernizr[l[0]] = i)
                : (!Modernizr[l[0]] ||
                    Modernizr[l[0]] instanceof Boolean ||
                    (Modernizr[l[0]] = new Boolean(Modernizr[l[0]])),
                  (Modernizr[l[0]][l[1]] = i)),
              S.push((i ? '' : 'no-') + l.join('-'))
        }
    })(),
    i(S),
    delete C.addTest,
    delete C.addAsyncTest
  for (let D = 0; D < Modernizr._q.length; D++) Modernizr._q[D]()
  e.Modernizr = Modernizr
})(window, document)
