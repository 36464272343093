/**
 * For each element in `arr` make an async computation and combine the latest emissions to array
 *
 * ```
 * -1--------2--------------
 * ----1-------------2------
 * ----[1,1]-[2,1]---[2,2]--
 * ```
 *
 * Unlike {@link combineLatest}, if an empty array is passed, there still is an emission.
 * It contains an empty array
 */
import { isEmpty } from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'

export function asyncMap<T, S>(arr: T[], mapper: (val: T) => Observable<S>): Observable<S[]> {
  if (isEmpty(arr)) return of([])

  return combineLatest(arr.map(mapper))
}
