import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import {
  ContactInvitation,
  ContactInvitationApi,
  ContactInvitationWithCompany
} from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { memoize } from 'lodash'
import { share } from 'rxjs/operators'
import { doWhileObserved } from '../_utils/doWhileObserved'
import { sse$ } from '../_utils/sseHandler'
import { AuthService } from './auth-service'
import { FacilityGroupService } from './facility-group-service'

@autoinject
export class ContactInvitationService {
  constructor(
    private readonly contactInvitationApi: ContactInvitationApi,
    private readonly facilityGroupService: FacilityGroupService
  ) {}

  //region Incoming
  private incomingInvitationQueryCache = new RxCache<undefined, ContactInvitationWithCompany[]>({
    fetcher: () => this.contactInvitationApi.getIncomingContactInvitations()
  })

  getIncomingInvitationsWithRemoteUpdates() {
    // get without arguments (`get$(undefined)`) and invalidate when `groupParticipationChange$`-events arrive but only while the final stream is subscribed/observed (`doWhileObserved(..)`)
    return this.incomingInvitationQueryCache.get$(undefined).pipe(
      doWhileObserved(() => {
        return this.facilityGroupService
          .groupParticipationChange$()
          .subscribe(() => this.incomingInvitationQueryCache.revalidate(undefined))
      })
    )
  }
  //endregion

  // region Outgoing
  outgoingInvitationQueryCache = new RxCache<undefined, ContactInvitation[]>({
    fetcher: () => this.contactInvitationApi.getOutgoingContactInvitations()
  })

  getOutgoingInvitationsWithRemoteUpdates() {
    return this.outgoingInvitationQueryCache.get$(undefined).pipe(
      doWhileObserved(() => {
        return this.facilityGroupService
          .groupParticipationChange$()
          .subscribe(() => this.outgoingInvitationQueryCache.revalidate(undefined))
      })
    )
  }
  // endregion
}
