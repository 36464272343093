import { arrayAddLast, arrayContains, arrayRemoveLast, arrayRemoveVal } from '_utils/array'
import { computedFrom } from 'aurelia-binding'
import { bindable } from 'aurelia-templating'
import { v4 as uuid } from 'uuid'

const CODE_ENTER = 13
const CODE_BACKSPACE = 8

/**
 * Input field for text as a component.
 */
export class TagField {
  public input: HTMLInputElement
  public fieldRoot: HTMLElement

  @bindable() public tags: string[] = []

  @bindable()
  public options: string[] = []

  @bindable public collapsible = 'false'

  public collapsed = false
  /**
   * generated id of HTML element. Bindable so the using page can set it.
   */
  public id = uuid()
  /**
   * Text label that is shown beside dropdown box. Bindable so the using page can set it.
   */
  @bindable public label: string
  /**
   * Text that is shown to the the user to show the intented use. Bindable so the using page can set it.
   */
  @bindable public help = ''
  /**
   * type of the text field. For example text, email or password. Bindable so the using page can set it.
   */
  @bindable public type = 'text'
  /**
   * Name of this text field. Bindable so the using page can set it.
   */
  @bindable public name = ''
  /**
   * true = a text is required in this field, false = the user does not need to enter a value. Bindable so the
   * using page can set it.
   */
  @bindable public required = false
  /**
   * true = the value of the feld can not be changed. Bindable so the using page can set it.
   */
  @bindable public readonly = false
  /**
   * enable or disable autocompletion of entered text. Bindable so the using page can set it.
   */
  @bindable public autocomplete = ''
  /**
   * function that is called when the user leaves the input box. Can be used to start validation or process the input
   * value. Bindable so the using page can set it.
   */
  @bindable public onblur: () => void
  /**
   * function that is called to validate the user input
   * @param value entered value from input
   * @returns true if entered value is valid
   */
  @bindable public validate?: (value: { value: string }) => boolean
  /**
   * entered text in input
   */
  public entered: string
  /**
   * CSS classes added to the input field.
   */
  public classesInput: string
  /**
   * maximum length of entered text. Bindable so the using page can set it.
   */
  @bindable public maxlength = 9999

  public doOnBlur() {
    this.fieldRoot.blur()
    this.addValidTag()
  }

  public async attached() {
    this.input.addEventListener('keydown', (e) => this.handleKeyEvents(e))

    this.collapsed = this.isCollapsible && (!this.tags || (this.tags && this.tags.length < 1))

    // can not be set in aurelia HTML
    this.input.setAttribute('list', `options${this.id}`)

    if (this.tags) {
      for (const tg of this.tags) {
        this.addOption(tg)
      }
    }
  }

  @computedFrom('collapsible')
  get isCollapsible() {
    return this.collapsible === 'true'
  }

  public toggleCollapse() {
    if (!this.isCollapsible) return

    this.collapsed = !this.collapsed
  }

  private deleteTag(tag: string) {
    arrayRemoveVal({ array: this.tags, value: tag })
  }

  private handleKeyEvents(event: KeyboardEvent) {
    switch (event.keyCode) {
      case CODE_ENTER: {
        // add tag on enter
        this.addValidTag()
        event.preventDefault()
        break
      }
      case CODE_BACKSPACE: {
        // remove last entered characters or tags on backspace
        if (!this.entered) {
          arrayRemoveLast({ array: this.tags })
          event.preventDefault()
        }
        break
      }
      default:
        if (!this.entered) this.entered = ''
    }
  }

  private addValidTag() {
    if (!this.entered) {
      this.classesInput = ''

      return
    }

    const valid = !this.validate || this.validate({ value: this.entered })

    if (!valid) {
      this.classesInput = 'invalid'

      return
    }
    if (!arrayContains({ array: this.tags, value: this.entered })) {
      arrayAddLast({ array: this.tags, value: this.entered })
      this.addOption(this.entered)
    }

    this.entered = ''
    this.classesInput = ''
  }

  private addOption(opt: string) {
    if (!arrayContains({ array: this.options, value: opt })) {
      arrayAddLast({ array: this.options, value: opt })
    }
  }
}
