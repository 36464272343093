import { computedFrom } from 'aurelia-binding'
import { bindable } from 'aurelia-templating'
import tippy, { Instance, Placement } from 'tippy.js'
import { reportErr } from '../errorReporting'
import { ActionResult } from './presentation/widget/base-button'

/**
 * Save button that shows progress, success or failure of any operation.
 * The progress animation will be started on click when an action is specified, or manually by calling display progress.
 */
export class ResultButton {
  /**
   * Text that is shown at the button before the user clicked it. Bindable so the using page can set it.
   */
  @bindable public text = ''
  /**
   * Button click action that is called when the user is clicking the button. Bindable so the using page can set it.
   */
  @bindable public action?: () => Promise<void>
  /**
   * Button type of this button. Bindable so the using page can set it.
   */
  @bindable public buttontype: '' | 'submit' = ''
  // if the type is 'submit', you may manually set a promise to be displayed via displayProgress()

  @bindable public placement: Placement = 'top'
  /**
   * class of button.
   */
  @bindable public buttonclazz = 'primary'

  @bindable public disabled = false

  public buttonRoot: HTMLElement
  public toolTip: Instance

  private result: 'idle' | 'running' | 'failure' | 'success' = 'idle'

  public attached() {
    this.toolTip = tippy(this.buttonRoot, {
      trigger: 'manual',
      placement: this.placement,
      animation: 'shift-away'
    }) as Instance
  }

  public detached() {
    this.toolTip?.destroy()
  }

  /**
   * computes the style of the button based on the state of the computation
   * @returns a css-class-name representing the style to display
   */
  @computedFrom('result')
  public get buttonClass(): string {
    switch (this.result) {
      case 'success':
        return 'success invisible-text'
      case 'failure':
        return 'failure invisible-text'
      case 'running':
        return `${this.buttonclazz} invisible-text running`
      default:
        return this.buttonclazz
    }
  }

  /**
   * Displays the progress of a promise.
   * Will display a loading indicator while the promise is open,
   * and then indicate either success or failure through symbols, color and an optional tooltip.
   * If the promise fails with an error, it's message will be displayed.
   * @param process the promise whose progress to display.
   */
  public displayProgress(process: Promise<ActionResult>) {
    if (this.result === 'running' || this.result === 'success') return

    this.result = 'running'
    process
      .then(() => this.onSuccess())
      .catch((e) => this.onError(e))
      .then(() => this.onSettle())
  }

  //region Events
  /**
   * If an action is specified, call this action and use its returned promise to display the progress.
   * Else, do nothing. In this case
   */
  public onButtonClicked() {
    if (!this.action) return

    return this.displayProgress(this.action())
  }

  private onSuccess() {
    this.result = 'success'
  }

  private onError(e: Error) {
    reportErr(e)
    this.result = 'failure'

    if (e.message) {
      this.toolTip.setContent(e.message)
      this.toolTip.show()
    }
  }

  private onSettle() {
    setTimeout(() => {
      this.result = 'idle'
    }, 2000)
  }
  //endregion
}
