import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-framework'
import { I18N } from 'aurelia-i18n'
import { PermissionTableEntry } from 'spica-cloud-shared/lib/model/permissions'
import { AureliaComponent } from '../../../_utils/AureliaComponent'
import { getErrorPresentation } from '../../../_utils/error-handling/getErrorPresentation'
import { wait } from '../../../_utils/wait'
import { reportErr } from '../../../errorReporting'

import styles from './base-button.module.scss'
import clickableStyles from './clickable-layouts.module.scss'
import variants from './clickable-variants.module.scss'

import { Popover } from './popover'

export type ButtonState =
  | { type: 'success' }
  | { type: 'idle' }
  | { type: 'failure'; message?: string }
  | { type: 'running' }

/** void is the same as an successful result */
export type ActionResult = undefined | void | { hasError: true; errorMessage?: string }

/** A unified button that provides all the required functionality for Aurelia */
@autoinject
export class BaseButton extends AureliaComponent {
  styles = styles
  // region Refs

  _errorPopover?: Popover
  _confirmationPopover?: Popover
  _root?: HTMLButtonElement

  // endregion
  // region Props

  /** if set, the user needs to confirm a popup before onClick is executed */
  @bindable requireConfirmation = false

  @bindable confirmationButtonText?: string

  @bindable variant: keyof typeof variants = 'secondary'
  @bindable layout: 'toolbar' | 'default' = 'default'

  /** Only show an icon to save space */
  @bindable collapseOnMobile = false

  @bindable alignment: 'left' | 'center' = 'center'

  /**
   * If onClick returns a Promise or an ActionResult, then progress, success and errors will be shown.
   */
  @bindable onClick?: () => Promise<ActionResult> | Promise<undefined> | ActionResult | undefined

  // if a string is passed, it explains
  @bindable disabled: false | true | string = false

  @computedFrom('disabled', 'hasPermissionToClick')
  get isDisabled() {
    return (
      (!this.hasPermissionToClick && 'Sie haben nicht die notwendigen Berechtigungen') ||
      this.disabled
    )
  }

  // endregion
  // region Permissions

  @bindable permission: PermissionTableEntry | undefined = undefined

  @computedFrom('permission')
  get hasPermissionToClick() {
    if (!this.permission) return true
    return this.permission.hasPermission
  }

  // endregion
  // region State

  public state: ButtonState = { type: 'idle' }

  // endregion
  // region Derived State

  /**
   * computes the style of the button based on the state of the computation
   * @returns a css-class-name representing the style to display
   */
  @computedFrom('state', 'isDisabled', 'layout', 'variant')
  public get buttonClass(): string {
    const layout = clickableStyles[this.layout]

    const colorScheme = () => {
      if (this.state.type === 'success') return variants.success
      if (this.state.type === 'failure') return variants.failure

      if (this.isDisabled) return variants.disabled

      return variants[this.variant]
    }

    return `${styles.container} ${layout} ${colorScheme()}`
  }

  @computedFrom('state')
  public get stateIcon(): string | undefined {
    switch (this.state.type) {
      case 'success':
        return 'check'
      case 'failure':
        return 'xmark'
      case 'running':
        return 'spinner-third fa-spin'
      default:
        return undefined
    }
  }

  @computedFrom('usedSlots')
  private get hasIcon() {
    return this.usedSlots.includes('icon')
  }

  // endregion
  // region Lifecycle

  constructor(element: Element, private readonly i18n: I18N) {
    super(element)
  }

  override attached() {
    super.attached()
    this._root!.addEventListener('click', (e) => {
      // Aurelias `click.delegate` will break the submitting behavior in forms (`type="submit"`).
      // Since it's not possible to conditionally bind attributes (brilliant framework), the listener needs to be set in code.
      e.stopPropagation()
      e.preventDefault()
      this.onButtonClicked()
    })
  }

  // endregion

  public displayProgress(promise: Promise<ActionResult | undefined>) {
    this.state = { type: 'running' }
    this._confirmationPopover?.setOpen(false)

    const displayError = (message?: string) => {
      this.state = {
        type: 'failure',
        message
      }
      if (!message) return
      if (this._errorPopover) {
        this._errorPopover.setOpen(true)
      }
    }

    promise
      .then(async (result) => {
        if (result && 'hasError' in result && result.hasError) {
          displayError(result.errorMessage)
        } else {
          this.state = { type: 'success' }
        }
        await wait(1000)
        this.state = { type: 'idle' }
      })
      .catch(async (e) => {
        reportErr(e)
        const presentation = getErrorPresentation(e, this.i18n)
        displayError(presentation.errorMessage)
        await wait(10000)
        this.state = { type: 'idle' }
      })
  }

  private invokeHandler() {
    if (this.state.type === 'running') return

    const handlerResult = this.onClick?.()

    if (handlerResult !== undefined) this.displayProgress(Promise.resolve(handlerResult))
  }

  // region Event listeners

  public onButtonClicked() {
    if (this.isDisabled) return

    if (this.requireConfirmation) {
      // Popup will be opened by dropdown-menu component
    } else {
      this.invokeHandler()
    }
  }

  public onConfirmButtonClicked() {
    this.invokeHandler()
  }

  // endregion
}

export function isSlotUsed(element: Element, name: string) {
  return (element as any).au.controller.view.slots[name].projections > 0
}
