import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { autoinject } from 'aurelia-framework'

/** Base class for all Aurelia ViewModels to provide TypeSafety and utility functions */
@autoinject()
export class AureliaComponent extends subscriberMixin() {
  protected usedSlots: string[] = []

  public attached() {
    this.computeUsedSlots()
  }

  public constructor(private element: Element) {
    super()
  }

  /**
   * @returns names of **named** `<slot>`-Elements that have at least one usage.
   * Slots with no name are called `__au-default-slot-key__`, but for some reason their projections are always 0.
   * To debug this, set a breakpoint here and look at the object itself.
   */
  protected computeUsedSlots() {
    const slots = (this.element as any).au.controller.view.slots
    this.usedSlots = Object.entries(slots)
      .filter(([_key, value]) => (value as any).projections > 0)
      .map(([key, _value]) => key)
  }
}
