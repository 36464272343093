import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Facility, FacilityApi, NotificationConfig } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { HttpClient } from 'aurelia-fetch-client'
import { getLogger } from 'aurelia-logging'
import { encodeOsSaveFileName, uploadToUrl } from '../_utils/upload-helper'
import { getFloorplanUploadUrl } from '../main'
import { AuthService } from './auth-service'

export type FacilityId = Facility['id']

const LOG = getLogger('faci-srvc')

@autoinject
export class FacilityService {
  public facilityEntityCache = new RxCache<FacilityId, Facility | undefined>({
    fetcher: (id) => this.facilityApi.getFacilityById({ id })
  })

  public notificationConfigCache = new RxCache<FacilityId, NotificationConfig>({
    fetcher: (id) => this.facilityApi.getNotificationConfig({ id })
  })

  public facilityQueryCache = new RxCache<undefined, Facility[]>({
    fetcher: async () => {
      const facilities = await this.facilityApi.getFacilities()
      facilities.forEach((it) => this.facilityEntityCache.prime(it.id, it))

      return facilities
    }
  })

  constructor(
    private readonly facilityApi: FacilityApi,
    private readonly authServ: AuthService,
    private readonly httpClient: HttpClient
  ) {}

  /** @deprecated */
  public async queryEntities(): Promise<Facility[]> {
    return this.facilityQueryCache.get(undefined)
  }

  public async getEntities(ids: number[]): Promise<(Facility | undefined)[]> {
    return await Promise.all(ids.map((id) => this.facilityEntityCache.get(id)))
  }

  public async getEntity(id: FacilityId): Promise<Facility | undefined> {
    return this.facilityEntityCache.get(id)
  }
  public async removeEntity(id: FacilityId) {
    // delete entity in DB but ignore errors since we remove all traces anyway
    await this.facilityApi.deleteFacility({ id }).catch(() => undefined)
    this.facilityEntityCache.prime(id, undefined)
    this.notificationConfigCache.invalidate(id)
    this.facilityQueryCache.invalidate(undefined)
  }

  public async updateEntity(changed: Facility): Promise<Facility> {
    const requestProgress = this.facilityApi.updateFacility({
      id: changed.id,
      body: changed
    })

    this.facilityEntityCache.mutate({
      where: (k) => k === changed.id,
      requestProgress
    })

    this.facilityQueryCache.mutate({
      requestProgress
    })

    return changed
  }

  public async updateConfig(
    id: FacilityId,
    changed: NotificationConfig
  ): Promise<NotificationConfig> {
    const requestProgress = this.facilityApi.setNotificationConfig({ id, body: changed })

    await this.notificationConfigCache.mutate({
      where: (k) => k === id,
      requestProgress
    })

    return changed
  }

  public async setBlocked(_id: FacilityId, _blocked: boolean) {
    // Emergency blocking was removed. See SPICA-938
    throw new Error()
  }

  public async setActive(id: FacilityId, deactivated: boolean) {
    return this.facilityApi.setFacilityDeactivated({ id, deactivated }).then(() => {
      this.facilityEntityCache.revalidate(id)
    })
  }

  public async uploadFloorPlan(id: FacilityId, files: FileList, fileName?: string) {
    let error = 200
    let msg = undefined
    const result: Response | undefined = await uploadToUrl(
      this.httpClient,
      files,
      getFloorplanUploadUrl(
        id,
        fileName ? encodeOsSaveFileName(fileName) : 'unknown.bin',
        this.authServ.sessionToken
      )
    )
      .then((response) => {
        return response ? response : undefined
      })
      .catch(async (e) => {
        error = e.status ?? e.statusCode ?? 407
        msg = e.message

        return undefined
      })

    this.facilityEntityCache.invalidate(id)

    // handle unexpected result of type Response
    const response = result as any
    if (response && (response.status || response.statusCode)) {
      msg = response.statusText
      error = response.status ?? response.statusCode
    }

    if (error && error !== 200 && error !== 204) {
      LOG.warn('error: %d %s during upload floorplan of facility %d', error, msg, id)
      throw new Error(`${error} ${msg}`)
    }
  }

  public async deleteFloorPlan(id: FacilityId) {
    return this.facilityApi.deleteFacilityFloorPlan({ id }).then(() => {
      this.facilityEntityCache.revalidate(id)
    })
  }
}
