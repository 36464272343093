import { FacilityGroup } from '@nubix/spica-cloud-backend-client'

export class FacilityGroupDescriptionValueConverter {
  static format(company: FacilityGroup) {
    return company.name || 'Unbenannte Gruppe'
  }

  constructor() {
    // empty
  }

  public toView(company: FacilityGroup | undefined): string {
    if (!company) return ''

    return FacilityGroupDescriptionValueConverter.format(company)
  }
}
