/**
 * This service stores any data in a key-value kind of centralized storage. This storage can be used to share data
 * between different pages in the web app.
 */
export class DataStorage {
  private readonly data: Map<string, any> = new Map()

  public constructor() {
    // nothing here
  }

  public getItem(key: string) {
    return this.data.get(key)
  }

  public setItem(key: string, item: any) {
    this.data.set(key, item)
  }

  public removeItem(key: string) {
    this.data.delete(key)
  }

  public hasItem(key: string): boolean {
    return this.data.has(key)
  }
}
