import { assertUnreachable } from '../_utils/utils'
import emergencyExitLightOnIcon from '../images/01_piktogramm_96x96.png'
import safetyLightOnIcon from '../images/01_sicherheitsleuchte_96x96.png'
import emergencyExitLightOffIcon from '../images/03_piktogramm_96x96.png'
import safetyLightOffIcon from '../images/03_sicherheitsleuchte_96x96.png'
import cpsIcon from '../images/Devices/cps.svg'
import fmsIcon from '../images/Devices/fms.svg'
import gatewayIcon from '../images/Devices/gateway.svg'
import { Device } from './device'

export function getIconForDeviceType(d: Device) {
  switch (d.deviceType) {
    case 'luminaire':
      switch (d.type) {
        case 'EmergencyExitLight':
          return d.state.brightness === 0 ? emergencyExitLightOffIcon : emergencyExitLightOnIcon
        case 'SafetyLighting':
          return d.state.brightness === 0 ? safetyLightOffIcon : safetyLightOnIcon
        default:
          assertUnreachable(d.type)
      }
      break
    case 'gateway':
      return gatewayIcon
    case 'fms':
      return fmsIcon
    case 'cps':
      return cpsIcon
  }
}
