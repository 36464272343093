import { Binding, Scope } from 'aurelia-binding'
import { getLogger } from 'aurelia-logging'
import { isObservable, Observable, Subscription } from 'rxjs'

const LOG = getLogger('subscribe-binding-behavior')

export class SubscribeBindingBehavior {
  private subscription?: Subscription

  bind(binding: Binding, _scope: Scope) {
    if (!binding.updateTarget) {
      throw new Error(
        'Only property bindings and string interpolation bindings can be signaled. ' +
          'Trigger, delegate and call bindings cannot be observed.'
      )
    }

    const syncUpdateTarget = binding.updateTarget.bind(binding)
    ;(binding as IAsyncBinding).syncUpdateTarget = syncUpdateTarget

    binding.updateTarget = (o: Observable<unknown> | unknown) => {
      if (!isObservable(o)) {
        console.warn(
          "ObservableBindingBehavior didn't receive an Observable. Simply displaying value instead"
        )
        syncUpdateTarget!(o)
        return
      }

      this.subscription?.unsubscribe()
      this.subscription = o.subscribe(
        (next) => syncUpdateTarget(next),
        (e) => {
          LOG.error(e)
          syncUpdateTarget(undefined)
        }
      )
    }
  }

  unbind(binding: IAsyncBinding, _scope: any) {
    this.subscription?.unsubscribe()
    this.subscription = undefined
    binding.updateTarget = binding.syncUpdateTarget
    delete binding.syncUpdateTarget
  }
}

interface IAsyncBinding extends Binding {
  // @ts-ignore
  syncUpdateTarget?: (value: any) => void
}
