// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// we want font-awesome to load as soon as possible to show the fa-spinner

import '@fortawesome/fontawesome-pro/css/all.css'
import {
  AuthStorage,
  ContactApi,
  ContactInvitationApi,
  CpsApi,
  FacilityApi,
  FacilityGroupApi,
  FmsApi,
  GatewayApi,
  InspectionApi,
  LuminaireApi,
  UserApi,
  Api
} from '@nubix/spica-cloud-backend-client'

import { EventAggregator } from 'aurelia-event-aggregator'
import { Aurelia, LogManager } from 'aurelia-framework'
import { ErrorHttpResponseMessage, HttpClient } from 'aurelia-http-client'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'
import { ConsoleAppender } from 'aurelia-logging-console'
import { PLATFORM } from 'aurelia-pal'
import { Router } from 'aurelia-router'
import { ValidationMessageProvider, validationMessages } from 'aurelia-validation'

import { InitOptions } from 'i18next'
import XHR, { I18NextXhrBackend } from 'i18next-xhr-backend'
import 'isomorphic-fetch'
import 'jquery-ui/themes/base/theme.css'

import 'materialize-css'
import 'Modernizr.js'
import * as moment from 'moment'

import 'popper.js'
import { DataStorage } from 'services/data-storage'

import 'stylesheets/app.scss'
import tippy from 'tippy.js'

import 'tippy.js/dist/svg-arrow.css'
import { encodeOsSaveFileName } from './_utils/upload-helper'
import { isProduction } from './_utils/utils'
import { initErrorReporting } from './errorReporting'
import { AuthService } from './services/auth-service'
import './tippy.scss'

LogManager.addAppender(new ConsoleAppender())
LogManager.setLevel(LogManager.logLevel.debug)

/**
 * Main configuration function of GUI backbone. Configures the use of Aurelia, i18n translation, logging, input
 * validation, tooltips.
 * @param aurelia Aurelia instannce
 */
export async function configure(aurelia: Aurelia) {
  aurelia.use
    .defaultBindingLanguage()
    .defaultResources()
    .eventAggregator()
    .router()
    .developmentLogging()
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/fa-icon'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/widget/base-button'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/widget/base-select'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/layout/subheader-layout'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/layout/route-layout'))
    .globalResources(PLATFORM.moduleName('_controls/presentation/widget/popover'))
    .globalResources(PLATFORM.moduleName('_utils/subscribe-binding-behavior'))
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance: any) => {
      // register backend plugin
      instance.i18next.use(XHR)

      const backend: I18NextXhrBackend.BackendOptions = {
        loadPath,
        crossDomain: true,
        allowMultiLoading: false // translations are spread across multiple locations, so we can't do multi-loading
      }

      // adapt options to your needs (see http://i18next.com/docs/options/)
      const options: InitOptions = {
        backend,
        lng: 'de-DE',
        fallbackLng: 'de',
        ns: ['translation', 'validation', 'error'],
        defaultNS: 'translation',
        debug: false,
        interpolation: {
          escapeValue: false,
          format: (value: any, format?: string, lng?: string): string => {
            let val = value

            if (val instanceof Date) {
              val = moment(value)
            }

            if (moment.isMoment(val)) {
              if (lng) {
                val = val.locale(lng)
              }

              return val.format(format)
            }

            return val
          }
        }
      }
      instance.setup(options)
    })
    .feature(PLATFORM.moduleName('_utils/validation/index'))
    .feature(PLATFORM.moduleName('_utils/app-history/index'))

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'))

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'))

  aurelia = integrateValidationWithI18n(aurelia)

  async function setRoot(a: Aurelia) {
    return a.setRoot(PLATFORM.moduleName('index'))
  }

  async function setDefaultLocale(a: Aurelia) {
    const i18n = a.container.get(I18N)

    const language = 'de'

    return i18n.setLocale(language).catch((reason: any) => {
      const i18nLog = getLogger('aurelia-i18n')
      i18nLog.error('failed to set locale', reason)
    })
  }

  aurelia = await configureComponents(aurelia).then(handleLocaleChanges)

  await aurelia.start().then(setRoot).then(setDefaultLocale)

  configureTippy() // REVIEW: does this really belong here?
}

/**
 * Loading function of i18n translation resources.
 */
function loadPath(lngs: string[], namespaces: string[]): string {
  if (namespaces.length > 1) throw new Error('please disable i18next multi-loading')

  if (namespaces.some((ns) => ns === 'logbook')) {
    return `${backendUrl()}/locales/{{lng}}/{{ns}}.json`
  }

  return './locales/{{lng}}/{{ns}}.json'
}

interface II18nLocaleChanged {
  oldValue: string
  newValue: string
}

function handleLocaleChanges(aurelia: Aurelia) {
  const eventAggregator = aurelia.container.get(EventAggregator)

  const i18nLog = getLogger('aurelia-i18n')
  eventAggregator.subscribe('i18n:locale:changed', (args: II18nLocaleChanged) => {
    i18nLog.debug('i18n:locale:changed', args)
    moment.locale(args.newValue)
  })

  return aurelia
}

function integrateValidationWithI18n(aurelia: Aurelia) {
  const i18n = aurelia.container.get(I18N)
  const log = getLogger('validation-i18n')

  ValidationMessageProvider.prototype.getMessage = function (key) {
    const messageKey = `errorMessages.${key}`
    let translation = i18n.tr(`validation:${messageKey}`)

    if (translation === messageKey) {
      translation = validationMessages[key]
    }
    log.debug(`Found message '${translation}' for key '${key}'`)

    return this.parser.parse(translation)
  }

  ValidationMessageProvider.prototype.getDisplayName = (propertyName, displayName) => {
    let key: string | number

    if (displayName !== null && displayName !== undefined) {
      key = displayName instanceof Function ? displayName() : displayName
    } else {
      key = propertyName
    }

    const result = i18n.tr(`${key}`)
    log.debug(`Found display name ${result} for property ${key}`)

    return result
  }

  return aurelia
}

/**
 * Get Backend URL.
 * @returns base path of backend URL
 */
function backendUrl() {
  if (window.location.protocol !== 'https:') {
    return `${PLATFORM.location.protocol}//${PLATFORM.location.hostname}:59090`
  }

  return `${PLATFORM.location.protocol}//${PLATFORM.location.hostname}/api/backend`
}

/**
 * Get Backend URL.
 * @returns base path of backend URL
 */
function backendBasePath() {
  return `${backendUrl()}`
}

/**
 * Configure injection with Aurelia. Registers Backend client instances and all other injected objects.
 * @param aurelia Aurelia instance, we use Aurelia injection in GUI
 * @returns configured aurelia Aurelia instance
 */
async function configureComponents(aurelia: Aurelia) {
  const container = aurelia.container

  // backend client API and configuration

  // Grab all the API-Singletons and set their dependencies
  // This isn't ideal but still better than doing it in DataService
  const APIs: (typeof Api)[] = [
    LuminaireApi,
    UserApi,
    FacilityGroupApi,
    FmsApi,
    CpsApi,
    FacilityApi,
    InspectionApi,
    GatewayApi,
    ContactInvitationApi,
    ContactApi
    // DefaultApi & DebugApi not used
  ]
  const client: HttpClient = aurelia.container.get(HttpClient)
  const auth: AuthStorage = aurelia.container.get(AuthStorage)
  for (const key of APIs) {
    const api = container.get(key)
    api.basePath = backendBasePath()
    api.httpClient = client
    api.authStorage = auth
  }

  container.registerSingleton(DataStorage, DataStorage)

  const authService = container.get(AuthService) as AuthService
  client.configure((b) => {
    b.withInterceptor({
      responseError: (r) => {
        const isSessionKeyExpired = r.statusCode === 401 && authService.isAuthenticated

        if (isSessionKeyExpired) {
          const router = container.get(Router) as Router
          const instruction = router.currentInstruction
          authService.logout()
          router.navigateToRoute(
            'home',
            instruction.getWildcardPath().indexOf('logout') >= 0
              ? {}
              : { target: `${instruction.getBaseUrl()}${instruction.getWildcardPath()}` },
            { replace: true }
          )
        }

        throw r
      }
    })
  })

  initErrorReporting(isProduction ? 'production' : 'development', backendBasePath())

  return aurelia
}

/**
 * Configure default behaviour of tooltips. With shift animation and as speech bubble.
 */
function configureTippy() {
  tippy.setDefaultProps({
    arrow: true,
    animation: 'shift-away',
    theme: 'base-dark'
  })
}

/**
 * Get SSE source of used backend.
 * @param path
 *  path at the backend for the required source
 * @param sessionToken
 *  Token of running session
 * @returns new created SSE source
 */
export function getBackendEventSource(path: string, sessionToken?: string): EventSource {
  return new EventSource(`${backendBasePath()}${path}?access_token=${sessionToken}`)
}

/**
 * Get backend URL for download of Prüfbuch.
 * @param facilityId
 *  ID of a facility
 * @param fileName
 *  wanted file name of download file
 * @param sessionToken
 *  Token of running session
 * @returns complete URL of the download link
 */
export function getInspectionDownloadUrl(
  facilityId: number,
  fileName: string,
  sessionToken?: string
): string {
  return `${backendBasePath()}/download/inspections/facility/${facilityId}/${encodeOsSaveFileName(
    fileName
  )}?access_token=${sessionToken}`
}

/**
 * Get backend URL for download of Gebäudeplan.
 * @param facilityId
 *  ID of a facility
 * @param fileName
 *  wanted file name of download file
 * @param sessionToken
 *  Token of running session
 * @returns complete URL of the download link
 */
export function getFloorplanDownloadUrl(
  facilityId: number,
  fileName: string,
  sessionToken?: string
): string {
  return `${backendBasePath()}/download/floorplan/facility/${facilityId}/${encodeOsSaveFileName(
    fileName
  )}?access_token=${sessionToken}`
}

/**
 * Get backend URL for upload of Gebäudeplan.
 * @param facilityId
 *  ID of a facility
 * @param fileName
 *  wanted file name of download file
 * @param sessionToken
 *  Token of running session
 * @returns complete URL of the upload link
 */
export function getFloorplanUploadUrl(
  facilityId: number,
  fileName: string,
  sessionToken?: string
): string {
  return `${backendBasePath()}/upload/floorplan/facility/${facilityId}/${encodeOsSaveFileName(
    fileName
  )}?access_token=${sessionToken}`
}
