import { Binding, bindingBehavior, BindingBehavior, Scope } from 'aurelia-binding'
import { getLogger } from 'aurelia-logging'
import { reportErr } from 'errorReporting'

const LOG = getLogger('await-binding-behavior')

@bindingBehavior('await')
export class AwaitBindingBehavior extends BindingBehavior {
  public bind(binding: IAsyncBinding, _source: Scope) {
    if (!binding.updateTarget) {
      throw new Error(
        'Only property bindings and string interpolation bindings can be signaled. ' +
          'Trigger, delegate and call bindings cannot be awaited.'
      )
    }

    binding.syncUpdateTarget = binding.updateTarget

    binding.updateTarget = (a) => {
      const p = Promise.resolve(a)

      p.then((v) => {
        if (binding.syncUpdateTarget) {
          binding.syncUpdateTarget(v)
        }
      }).catch((e) => reportErr(e))
    }
  }

  public unbind(binding: IAsyncBinding, _source: Scope) {
    binding.updateTarget = binding.syncUpdateTarget
    binding.syncUpdateTarget = undefined
  }
}

interface IAsyncBinding extends Binding {
  syncUpdateTarget?(value: any): void
}
