exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1GaxvpFCqGKRFSo-ugXy38{z-index:99999999;position:fixed;left:0;bottom:0;right:0;top:0;pointer-events:none;align-items:center;justify-content:center}._1zd664MyueJe5OP9N6fiaA,._1GaxvpFCqGKRFSo-ugXy38{display:flex;flex-direction:column}._3a6tXrAWH9zL9GN9dggzlV{flex-basis:60vh;flex-shrink:1}.aYhHln542jw-8PuhMG7BZ{margin-top:12px;pointer-events:auto;background:#545461;color:#fff;padding:12px 16px}", ""]);

// Exports
exports.locals = {
	"outerContainer": "_1GaxvpFCqGKRFSo-ugXy38",
	"innerContainer": "_1zd664MyueJe5OP9N6fiaA",
	"spacer": "_3a6tXrAWH9zL9GN9dggzlV",
	"toast": "aYhHln542jw-8PuhMG7BZ"
};