import { UserApi } from '@nubix/spica-cloud-backend-client'

import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'

/**
 * Parameters of this page.
 */
interface IUndoParams {
  /**
   * ID of a user
   */
  id: number
  /**
   * Username of this user
   */
  username: string
  /**
   * security bean of this user, secret shared over email
   */
  bean?: string
}

const LOG = getLogger('confirm')

/**
 * This route allows the user to undo a password change via email
 */
@autoinject
export class UndoChange {
  private userEmail: string

  constructor(private readonly router: Router, private readonly userApi: UserApi) {}

  public async activate(params: IUndoParams) {
    let code = 204
    this.userEmail = params.username
    const result: Response | undefined = await this.userApi
      .undoLoginUser({
        id: params.id,
        username: params.username,
        bean: params.bean
      })
      .catch(async (error) => {
        reportErr(error, 'Error during undo change')
        code = error.status ?? error.statusCode ?? 407

        return undefined
      })

    // handle unexpected result of type Response
    if (result && result.status) {
      LOG.debug('error durring undo change', result.status, result.statusText)
      code = result.status
    }
    if (code && code !== 204 && code !== 200) {
      // do not use return for navigateToRoute
      findNavigationToRoute(this.router, 'confirm-failure', { code }).catch((e) => reportErr(e))
    } else {
      findNavigationToRoute(this.router, 'confirm-email', {
        userEmail: this.userEmail
      }).catch(async (e) => reportErr(e))
    }
  }
}
