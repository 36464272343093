import { PLATFORM, autoinject } from 'aurelia-framework'
import { ModalViewModel } from '../_controls/presentation/layout/modal-service'
PLATFORM.moduleName('../_controls/presentation/layout/modal-service')

@autoinject()
export class GatewayDeletionPrompt implements ModalViewModel<{ confirm: () => void }> {
  confirm: () => void
  close: () => void

  activate(p: { confirm: () => void; close: () => void }) {
    this.confirm = p.confirm
    this.close = p.close
  }

  confirmAndClose() {
    this.confirm()
    this.close()
  }
}
