exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1L6W1c-W-6sHpEvRNyVqC1{display:grid;grid-gap:16px;overflow-wrap:anywhere}._1L6W1c-W-6sHpEvRNyVqC1 .VIO151nbRQIzrXnyil5SP{grid-area:image;-o-object-fit:cover;object-fit:cover;align-self:stretch}._1L6W1c-W-6sHpEvRNyVqC1 ._3vNNRKLvlCRFIRX47D5YT0{grid-area:info}._1L6W1c-W-6sHpEvRNyVqC1 ._2fEGKeEr7GYd44D0et0Haz{grid-area:status}@media(min-width:1024px){._1L6W1c-W-6sHpEvRNyVqC1{grid-template-areas:\"image\" \"info\" \"status\";grid-template-rows:9em auto auto}._1L6W1c-W-6sHpEvRNyVqC1 .VIO151nbRQIzrXnyil5SP{width:calc(100% + 48px);margin-top:-24px;margin-left:-24px;margin-right:-24px}}@media(max-width:1023px){._1L6W1c-W-6sHpEvRNyVqC1{grid-template-areas:\"image info\" \"image status\";grid-template-columns:1fr 1.61803fr}._1L6W1c-W-6sHpEvRNyVqC1 .VIO151nbRQIzrXnyil5SP{height:calc(100% + 48px);width:100%;justify-self:stretch;margin-top:-24px;margin-bottom:-24px}._1L6W1c-W-6sHpEvRNyVqC1 ._3vNNRKLvlCRFIRX47D5YT0{align-self:end}._1L6W1c-W-6sHpEvRNyVqC1 ._2fEGKeEr7GYd44D0et0Haz{align-self:start}}", ""]);

// Exports
exports.locals = {
	"container": "_1L6W1c-W-6sHpEvRNyVqC1",
	"image": "VIO151nbRQIzrXnyil5SP",
	"info": "_3vNNRKLvlCRFIRX47D5YT0",
	"status": "_2fEGKeEr7GYd44D0et0Haz"
};