import { HttpResponseMessage } from 'aurelia-http-client'
import { ErrorHandler } from './ErrorHandler'

export const handleConnectionError: ErrorHandler = (e) => {
  if (!(e instanceof HttpResponseMessage) || e.statusCode !== 0) return undefined
  return {
    errorMessage:
      'Die Verbindung zum Server wurde unterbrochen. Prüfen Sie ihre Internetverbindung und wiederholen sie den Vorgang'
  }
}
