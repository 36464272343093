import { Facility } from '@nubix/spica-cloud-backend-client'
import { bindable, computedFrom } from 'aurelia-framework'
import { ImsiFormatValueConverter } from '../../_utils/format/imsi'
import { hasDefined } from '../../_utils/objectUtils'
import { isCircuitEmpty } from '../../model/circuitLabel'
import { Device, getDeviceType } from '../../model/device'
import { getIconForDeviceType } from '../../model/device-icon'
import styles from './device-header.module.scss'

export class DeviceHeader {
  styles = styles

  @bindable device?: Device
  @bindable facility: Facility

  @computedFrom('device')
  get icon() {
    if (!this.device) return ''
    return getIconForDeviceType(this.device)
  }

  @computedFrom('device')
  get shouldShowCircuit() {
    if (!this.device) return false
    if (!hasDefined(this.device, 'circuit')) return false
    return !isCircuitEmpty(this.device.circuit)
  }

  @computedFrom('device')
  get title() {
    if (!this.device) return ''
    if (this.device.name) return this.device.name
    if (this.device.deviceType === 'luminaire') return getDeviceType(this.device)
    return new ImsiFormatValueConverter().toView(this.device.imsi)
  }
}
