import { HistoryMode } from '_utils/app-history/mode'
import { findChildNavigationInstruction } from '_utils/routing'
import { FrameworkConfiguration, inject } from 'aurelia-framework'
import { History as AuHistory } from 'aurelia-history'
import { BrowserHistory, DefaultLinkHandler, LinkHandler } from 'aurelia-history-browser'
import { NavigationInstruction, Next, PipelineStep, Router } from 'aurelia-router'

export interface IHistoryRoute {
  /**
   * routes name
   */
  name?: string
  /**
   * routes history config
   */
  includeMode: HistoryMode
}

/**
 * Browser history tracker that handles browser history navigation too. Handles the browser history of the web app.
 */
@inject(LinkHandler)
export class AppHistory extends BrowserHistory implements PipelineStep {
  /**
   * determine whether or not a route should be skipped when navigating backwards
   * the behavior for each route can be changed by editing 'h' in the route-settings
   * @param targetRoute the route to evaluate
   * @param lastRoute the last route that was displayed to the user
   * @returns whether or not the route should be skipped
   */
  private static shouldSkipRoute(targetRoute: IHistoryRoute, lastRoute: IHistoryRoute) {
    switch (targetRoute.includeMode) {
      case HistoryMode.Full:
        return false

      case HistoryMode.Skip:
        return true

      case HistoryMode.Sequence:
        return lastRoute.includeMode !== HistoryMode.Sequence

      default:
        // One-time
        return lastRoute.name === targetRoute.name
    }
  }

  private _router: Router
  private _homeRoute: string
  private _homeIdx = -1

  private lastRoute: IHistoryRoute
  private isNavigatingBack = false

  // properties of super
  // tslint:disable:prefer-readonly
  private history: History
  private _checkUrlCallback: EventListenerOrEventListenerObject
  private _checkUrl: () => boolean
  // tslint:enable:prefer-readonly

  constructor(linkHandler: LinkHandler) {
    super(linkHandler)

    this._checkUrlCallback = this.checkRoute.bind(this)
  }

  public setObservedRouter(router: Router) {
    this._router = router
  }

  public setHome(route: string) {
    this._homeRoute = route
  }

  /**
   * will update the state of the current history entry on page-load
   * @see PipelineStep
   */
  public async run(instruction: NavigationInstruction, next: Next): Promise<any> {
    const entry = this.createEntry(instruction)

    if (this._homeRoute === entry.name) {
      this._homeIdx = this.history.length - 1
      console.log('HOME', this._homeIdx)
    }

    this.setState('entry', entry)
    this.lastRoute = entry

    return next()
  }

  public override navigateBack() {
    this.isNavigatingBack = true

    if (!this.hasHistory()) {
      // do not allow enter to go back to pages before home page was visited
      this.isNavigatingBack = false
      return
    }

    this.history.back()
  }

  /**
   * was index of start page stored?
   */
  public hasHistory() {
    return this._homeIdx > -1 && this.history.length > this._homeIdx
  }

  /**
   * delete index of start page
   */
  public deleteHistory() {
    this._homeIdx = -1
  }

  /**
   * Add a new state to the browser history.
   * If no new values are provided, the last state will be copied
   * @param override specifies which values should be overridden
   * @param custom a custom object
   */
  public pushState(override?: any, custom?: any) {
    const o = override || {}

    const entry: IHistoryRoute = {
      name: o.name || history.state.entry.name,
      includeMode: o.includeMode || history.state.entry.includeMode
    }

    history.pushState({ entry, custom }, '', '')
  }

  /**
   * check if the new state has already been visited and, if so, check if it should be skipped.
   * if not, load the page
   */
  private checkRoute() {
    if (this.isNavigatingBack && this.history.state && this.history.state.entry) {
      if (AppHistory.shouldSkipRoute(this.history.state.entry, this.lastRoute)) {
        this.isNavigatingBack = false

        return
      } else {
        this.isNavigatingBack = false
      }
    }

    this._checkUrl()
  }

  /**
   * Create an entry of IHistoryRoute
   */
  private createEntry(instruction: NavigationInstruction): IHistoryRoute {
    const childNavigationInstruction = findChildNavigationInstruction(instruction)

    return {
      name: childNavigationInstruction.config.name,
      includeMode: childNavigationInstruction.config.settings.h
    }
  }
}

/**
 * Register AppHistory as a singleton
 * This will make it so that every new router, will use this class as it's history-object
 */
export function configure(config: FrameworkConfiguration) {
  config.singleton(AuHistory, AppHistory)
  config.transient(LinkHandler, DefaultLinkHandler)
}
