export class VersionFormatValueConverter {
  constructor() {
    // empty
  }

  public toView(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return 'n.v.'
    }
    const major = (value >> 8) & 0xff
    const minor = value & 0xff

    return `${major}.${minor}`
  }
}
