import { autoinject } from 'aurelia-dependency-injection'

declare let AndroidApi: any

@autoinject()
export class MobileService {
  private _isAvailableDroid = true

  constructor() {
    try {
      // test call to js to check if we can use the API
      AndroidApi.getVersion()

      this._isAvailableDroid = true
    } catch (error) {
      this._isAvailableDroid = false
    }
  }

  public showToast(message: string) {
    if (this._isAvailableDroid) {
      AndroidApi.showToast(message)
    } else {
      console.log(message)
    }
  }

  /**
   * Ask for defined properties.
   * @returns value of property, undefined if property does not exists or no value is stored
   */
  public getProperty(key: 'floorplan'): string | undefined {
    if (this._isAvailableDroid) {
      return AndroidApi.getProperty(key)
    }

    return undefined
  }

  public deleteProperty(key: 'floorplan') {
    if (this._isAvailableDroid) {
      return AndroidApi.deleteProperty(key)
    }
  }
}
