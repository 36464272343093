import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Facility } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { cloneDeep, compact } from 'lodash'
import { ResultButton } from '../../_controls/result-button'
import { DateString, TimeString, WeekdaySelection } from '../../_utils/timeUtils'
import { firstEmission } from '../../_utils/utils'
import { FacilityService } from '../../services/facility-service'
import { InspectionConfigService } from '../../services/inspection-config-service'

const defaultDraft = (): InspectionConfigDraft => ({
  weekdays: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true
  },
  startTime: '00:00',
  endTime: '00:00',
  enableScheduledDurationTests: false,
  day1: '',
  day2: '',
  day3: ''
})

export interface InspectionConfigEditParams {
  facilityId: string
}

export interface InspectionConfigDraft {
  weekdays: WeekdaySelection
  startTime: TimeString
  endTime: TimeString

  enableScheduledDurationTests: boolean

  day1: DateString
  day2: DateString
  day3: DateString
}

@autoinject()
export class InspectionConfigEdit extends subscriberMixin() {
  //region Refs
  private form: HTMLFormElement
  private resultButton: ResultButton
  //endregion

  //region Props
  private draft!: InspectionConfigDraft
  private facility!: Facility
  private facilityId: number
  //endregion
  
  private warningOn: boolean

  @computedFrom('draft.endTime', 'draft.startTime')
  private get isEndTimeOnNextDay() {
    return this.draft.startTime >= this.draft.endTime
  }

  @computedFrom('draft')
  private get disableScheduledDurationTests() {
    return !this.draft.enableScheduledDurationTests
  }

  private set disableScheduledDurationTests(it: boolean) {
    this.draft.enableScheduledDurationTests = !it
  }

  //region Lifecycle
  constructor(
    private readonly inspectionService: InspectionConfigService,
    private readonly facilityService: FacilityService,
    private readonly router: Router
  ) {
    super()
  }

  attached(){
    this.checkIfWarningOn()
  }

  public async activate(params: InspectionConfigEditParams) {
    this.facilityId = Number(params.facilityId)

    const facility$ = this.facilityService.facilityEntityCache.get$(this.facilityId)
    this.subscribeUntilDeactivated({
      to: facility$,
      onNext: (next) => (this.facility = next!)
    })

    await this.loadInspectionConfig()
    await firstEmission(facility$)
  }

  //endregion

  /** Loads the inspection plan. @throws if the server request fails. Since this a form, data doesn't refresh automatically. */
  public async loadInspectionConfig() {
    this.inspectionService.inspectionConfigEntityCache.invalidate(this.facilityId)

    const inspectionConfig = await this.inspectionService.inspectionConfigEntityCache.get(
      this.facilityId
    )

    this.draft = {
      weekdays: cloneDeep(inspectionConfig.weekdays),
      startTime: inspectionConfig.startTime,
      endTime: inspectionConfig.endTime,
      enableScheduledDurationTests: inspectionConfig.enableScheduledDurationTests,
      day1: inspectionConfig.scheduledDurationTests[0],
      day2: inspectionConfig.scheduledDurationTests[1],
      day3: inspectionConfig.scheduledDurationTests[2]
    }
  }

  //region Events
  onSubmit() {
    if (!this.form.checkValidity()) {
      this.form.reportValidity()

      return
    }

    const requestProgress = this.inspectionService
      .setInspectionConfig(this.facilityId, {
        weekdays: this.draft.weekdays,
        startTime: this.draft.startTime,
        endTime: this.draft.endTime,
        enableScheduledDurationTests: this.draft.enableScheduledDurationTests,
        scheduledDurationTests: compact([this.draft.day1, this.draft.day2, this.draft.day3])
      })
      .then(() => this.loadInspectionConfig())

    this.resultButton.displayProgress(requestProgress)
  }

  onSelectDefaultPlanClicked() {
    this.draft = defaultDraft()
  }
  // blendet Warnhinweis ein, wenn Zeitfenster zum Testen zu kurz ist
  private checkIfWarningOn() {
    setTimeout(() => {
      const weekDaysValues = Object.values(this.draft.weekdays)
      const startTime = this.convertToTimestamp(this.draft.startTime)
      const endTime = this.convertToTimestamp(this.draft.endTime)
      let sum = 0
      let timeResult = 0
      //console.log(this.convertToTimestamp('24:00')-this.convertToTimestamp('00:00'))
      weekDaysValues.forEach(elem => {
        if (elem === true) {
          sum++
        }
      })

      // 86400000 entspricht 24 stunden differenz im timestamp
      timeResult = (startTime < endTime) ? (endTime - startTime): (86400000 + endTime - startTime)

      // 14400000 entspricht 4 Stunden differenz im Timestamp
      this.warningOn = (sum >= 2 && timeResult >= 14400000) ? false : true

    }, 300)

  }

  // nimmt einen Uhrzeit-String "00:00" oder "00:00:00" entgegen und gibt den timestamp zurück
  private convertToTimestamp(timeString: string): number {
    const [hours, minutes, seconds=0] = timeString.split(':').map(Number)
    // Aktuelles Datum (beliebiges Datum, weil nur Uhrzeit relevant ist)
    const date = new Date()

    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(seconds)
    const timestamp = date.getTime()

    return timestamp
}

  public navigateUp() {
    history.back()
  }

  //endregion
}
