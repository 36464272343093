import { bindable } from 'aurelia-templating'
import { SUPPORTED_COUNTRYS, SUPPORTED_SOCIALFORMS } from 'localization'

export interface ICompanyInfo {
  name: string
  socialForm: string
  street: string
  number: string
  town: string
  zip: string
  country: string
  state: string
}

export class CompanyInfoForm {
  /**
   * Available social forms
   */
  public socialFormOptions = SUPPORTED_SOCIALFORMS

  /**
   * Available countries
   */
  public countryOptions = SUPPORTED_COUNTRYS

  @bindable public value: ICompanyInfo
}
