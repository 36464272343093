import { bindable } from 'aurelia-templating'
import jsQR from 'jsqr'
import { reportErr } from '../errorReporting'

/**
 * Component that connects to the device camera to scan and decode a visible QR code. This component shows the cameras
 * video feed and scans it for a visible QR code. If a QR code is found the content of the code is put in a public field
 * so that the surounding page can access it and a validation method is called to verify the content.
 */
export class QrCodeReader {
  @bindable() private onCodeScanned: (args: { code: string }) => void

  /**
   * Flag if device camera video is accessible, true = access to video of device camera is possible
   */
  public hasVideoAccess = true

  private video: HTMLVideoElement

  private timer: ReturnType<typeof setInterval>

  public attached() {
    this.initQrReader()
    this.timer = setInterval(() => this.detectQrCode(), 200)
  }

  public detached() {
    clearInterval(this.timer)
    this.video.pause()
    this.video.src = ''
    // tslint:disable:no-null-keyword
    this.video.srcObject = null
  }

  public detectQrCode() {
    const image = getFrameFromVideo(this.video)
    if (!image) return

    const code = jsQR(image.data, image.width, image.height)
    if (code) this.onCodeScanned({ code: code.data })
  }

  /**
   * Initializes the QR code reader. It tries to get access to the devices camera video.
   * If access to the camera is prohibited, then flags are set to show error messages.
   */
  private initQrReader() {
    if (!navigator.mediaDevices) {
      this.hasVideoAccess = false
      return
    }

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then(async (stream: MediaStream) => {
        this.video.srcObject = stream
        // no full screen for iOS
        this.video.setAttribute('playsinline', 'true')
        await this.video.play()
        // requestAnimationFrame(this.drawVideoFrame.bind(this))
      })
      .catch(async (e) => {
        reportErr(e)
        this.hasVideoAccess = false
      })
  }
}

function getFrameFromVideo(video: HTMLVideoElement): ImageData | undefined {
  const w = video.videoWidth
  const h = video.videoHeight
  if (w === 0) return undefined
  const canvas = document.createElement('canvas')
  canvas.width = w
  canvas.height = h
  const context = canvas.getContext('2d')!
  context.drawImage(video, 0, 0, w, h)
  return context.getImageData(0, 0, w, h)
}
