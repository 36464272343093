export const DEFAULT_COUNTRY = 'Deutschland'

export const SUPPORTED_COUNTRYS = [DEFAULT_COUNTRY, 'Österreich']

export const SUPPORTED_SOCIALFORMS = [
  '-',
  'GbR',
  'OHG',
  'UG',
  'KG',
  'KGaA',
  'AG',
  'PartG',
  'GmbH',
  'GmbH & Co. KG',
  'Stille Gesellschaft',
  'Stiftung',
  'Genossenschaft',
  'VVaG'
]

export const SUPPORTED_TITLES = ['', 'Professor', 'Doktor']

export const SUPPORTED_ADDRESSFORMS = ['Herr', 'Frau']
