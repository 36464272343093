import { User } from '@nubix/spica-cloud-backend-client'
import { bindable } from 'aurelia-templating'
import { SUPPORTED_ADDRESSFORMS, SUPPORTED_TITLES } from 'localization'

export class ContactInfoForm {
  /**
   * Available options for titles
   */
  public titleOptions = SUPPORTED_TITLES

  /**
   * Available options for form of address
   */
  public formOfAddressOptions = SUPPORTED_ADDRESSFORMS

  @bindable public value: User
}
