import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Contact, UserApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { doWhileObserved } from '../_utils/doWhileObserved'
import { FacilityGroupService } from './facility-group-service'

@autoinject()
export class ContactService {
  constructor(
    private readonly contactApi: UserApi,
    private readonly facilityGroupService: FacilityGroupService
  ) {}

  contactQueryCache = new RxCache<undefined, Contact[]>({
    fetcher: () => this.contactApi.getAllContacts()
  })

  getContactsWithRemoteUpdates() {
    return this.contactQueryCache.get$(undefined).pipe(
      doWhileObserved(() => {
        return this.facilityGroupService
          .groupParticipationChange$()
          .subscribe(() => this.contactQueryCache.revalidate(undefined))
      })
    )
  }
}
