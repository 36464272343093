import { User, UserApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { ActionResult, BaseButton } from '../../_controls/presentation/widget/base-button'

@autoinject
export class UserEdit {
  submitButton: BaseButton
  formRoot: HTMLFormElement

  user: User

  constructor(private readonly userApi: UserApi, private readonly router: Router) {}

  async activate() {
    this.user = await this.userApi.getCurrentUser()
  }

  navigateUp() {
    this.router.navigateToRoute('all-options')
  }

  onSubmitClicked() {
    const progress = this.submit()
    this.submitButton.displayProgress(progress)
  }

  async submit(): Promise<ActionResult> {
    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()
      return { hasError: true }
    }

    await this.userApi.updateCurrentUserInfo({ body: this.user })
  }
}
