import { GatewayApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { ActionResult, BaseButton } from '../../_controls/presentation/widget/base-button'
import { GatewayDevice } from '../../model/device'
import { GatewayService } from '../../services/gateway-service'

export interface GatewayReplaceParams {
  imsi: string
}

@autoinject
export class GatewayReplace {
  submitButton: BaseButton
  formRoot: HTMLFormElement

  newImsi = ''
  imsi!: string

  gateway?: GatewayDevice

  constructor(
    private readonly router: Router,
    private readonly gatewayService: GatewayService,
    private readonly gatewayApi: GatewayApi
  ) {}

  async activate(p: GatewayReplaceParams) {
    this.imsi = p.imsi
    this.gateway = await this.gatewayService.gatewayEntityCache.get(p.imsi)
    if (!p.imsi || !this.gateway) throw Error('Das Gateway wurde nicht gefunden.')
  }

  navigateUp() {
    this.router.navigateToRoute('gateway-details', { imsi: this.imsi })
  }

  async onSubmitClicked() {
    const progress = this.submit()
    this.submitButton.displayProgress(progress)
  }

  async submit(): Promise<ActionResult> {
    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()
      throw new Error()
    }
    const newImsi = this.newImsi
    await this.gatewayApi.replace({ imsi: this.imsi, body: { newImsi } })
    await this.router.navigateToRoute('gateway-details', { imsi: newImsi })
  }
}
