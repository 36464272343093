import { IExtRouteConfig } from '_utils/routing'

import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { NavigationInstruction, RouteConfig, Router, RouterConfiguration } from 'aurelia-router'
import { AuthService } from 'services/auth-service'
import { LocaleService } from 'services/locale-service'

/**
 * The registered and logged in Member area page. This is more of a frame showing member pages inside. This frame is
 * setting up all member pages routes and checks the session in a fixed interval.
 */
@autoinject
export class App {
  public _router: Router

  constructor(private readonly locServ: LocaleService, public readonly authService: AuthService) {}

  /**
   * Configures all member pages from the starting page of the member area.
   */
  public configureRouter(config: RouterConfiguration, router: Router) {
    config.title = 'Fischer Service Cloud'

    this._router = router

    const routes: IExtRouteConfig[] = [
      // facility pages, starting home page of member area
      { route: '', redirect: 'facilities' },
      {
        route: 'facilities',
        name: 'facilities',
        moduleId: PLATFORM.moduleName('facilities/index'),
        nav: true,
        title: 'Objektverwaltung',
        settings: {
          requiresAuthentication: true
        }
      },
      {
        route: [':facilityId', 'facility-edit'],
        name: 'facility-edit',
        moduleId: PLATFORM.moduleName('./facilities/facility-edit'),
        title: 'Objekt bearbeiten',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      {
        route: 'inspection-config-edit',
        name: 'inspection-config-edit',
        moduleId: PLATFORM.moduleName('./facilities/facility-edit/inspection-config-edit'),
        title: 'Testplan bearbeiten',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      },
      // luminaire pages
      { route: 'luminaires', redirect: 'devices' },
      {
        route: 'devices',
        name: 'devices',
        moduleId: PLATFORM.moduleName('./devices/index'),
        nav: true,
        title: 'Objektverwaltung',
        settings: {
          requiresAuthentication: true
        }
      },
      // account pages
      {
        route: 'account',
        name: 'account',
        moduleId: PLATFORM.moduleName('./account/index'),
        title: 'Abmelden',
        settings: {
          requiresAuthentication: true
        }
      },
      {
        route: 'error',
        name: 'error',
        moduleId: PLATFORM.moduleName('./error'),
        title: 'Es ist ein Fehler aufgetreten',
        settings: {
          requiresAuthentication: true
        }
      },
      { route: 'unknown', name: 'unknown', redirect: 'facilities' },
      {
        route: ['delete-facility-group'],
        name: 'delete-facility-group',
        moduleId: PLATFORM.moduleName('./facility-groups/delete-facility-group'),
        title: 'Objektgruppe löschen',
        settings: {
          b: true,
          requiresAuthentication: true
        }
      }
    ]
    config.map(routes)
    config.fallbackRoute('unknown')
    config.mapUnknownRoutes((_instruction: NavigationInstruction): RouteConfig => {
      return { route: 'logout', name: 'logout', redirect: '#/' }
    })
  }

  public async activate() {
    await this.locServ.loadLanguage()
  }
}
