import { autoinject, computedFrom } from 'aurelia-framework'
import { ModalService } from './modal-service'
import * as styles from './modal.module.scss'

@autoinject()
export class Modal {
  styles = styles

  @computedFrom('modalService.openModal')
  get model() {
    if (!this.modalService.openModal) return
    return {
      ...this.modalService.openModal.model,
      close: () => this.close()
    }
  }

  @computedFrom('modalService.openModal')
  get widthOption() {
    switch (this.modalService.openModal?.desiredWidth) {
      case undefined:
      case 'default':
        return styles.widthDefault
      case 'wide':
        return styles.widthWide
    }
  }

  close() {
    this.modalService.openModal = undefined
  }

  constructor(private readonly modalService: ModalService) {}
}
