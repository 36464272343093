import { Cps, Luminaire } from '@nubix/spica-cloud-backend-client'
import { hasDefined } from '../_utils/objectUtils'
import {
  Device,
  FmsDevice,
  GatewayConnectedDevice,
  GatewayDevice,
  isGatewayConnectedDevice,
  isSpicaConnectedDevice,
  LuminaireDevice,
  SpicaConnectedDevice
} from './device'

/** Represents a status of a device. Displayed in one of the device-detail pages and in the device-card */
export type DeviceStatus =
  | {
      importance: Importance
      label: string
      faIcon: string
      description: string
    }
  | {
      /** The status is hidden, both from the card and the details page */
      importance: 'hidden'
    }

type Importance =
  | 'normal' // Show only in DeviceStatusSection
  | 'hidden' // Don't show, even in DeviceStatusSection
  | 'important' // Show as black, including in DeviceCard and header
  | 'ok' // Show in the DeviceStatusSection as green, don't show in DeviceCard and header
  | 'error' // Show as red, including in DeviceCard and header
  | 'warn' // Show as yellow, including in DeviceCard and header

//region ConnectionStatus
export function getConnectionStatus(d: Device): DeviceStatus {
  const base = { label: 'Verbindung' } as const
  if (d.state.deactivated)
    return { ...base, faIcon: 'ban', description: 'Deaktiviert', importance: 'normal' } as const

  if (isSpicaConnectedDevice(d)) return { ...base, ...getSpicaSignalStrength(d) }

  if (isGatewayConnectedDevice(d)) {
    const pairStatus = getGatewayPairStatus(d)
    if (pairStatus) return { ...base, ...pairStatus }
  }

  return {
    ...base,
    faIcon: 'question',
    description: 'Verbindungsstatus unbekannt',
    importance: 'error'
  }
}

export function getGatewayPairStatus(d: GatewayConnectedDevice) {
  const p = d.pairedGateway?.isPaired

  if (!p)
    return {
      faIcon: '',
      description: 'Nicht mit Gateway gekoppelt',
      importance: 'normal'
    } as const

  if (!p.value && p.isConfirmed)
    return {
      faIcon: 'spinner-third fa-spin',
      description: 'Entkopplung von Gateway läuft...',
      importance: 'normal'
    } as const

  if (p.value && !p.isConfirmed)
    return {
      faIcon: 'spinner-third fa-spin',
      description: 'Kopplung mit Gateway läuft...',
      importance: 'normal'
    } as const

  if (!d.state.connected)
    return {
      faIcon: 'circle-nodes',
      description: 'Verbindungsfehler festgestellt',
      importance: 'error'
    } as const

  if (p.value && p.isConfirmed)
    return {
      faIcon: 'circle-nodes',
      description: 'Verbunden über Gateway',
      importance: 'normal'
    } as const

  return {
    faIcon: '',
    description: 'Nicht mit Gateway gekoppelt',
    importance: 'normal'
  } as const
}

export function getSpicaSignalStrength(d: SpicaConnectedDevice) {
  const s = d.state.signal

  if ('definedOffline' in d && d.definedOffline)
    return {
      faIcon: 'signal-slash',
      importance: 'normal',
      description: 'Temporärer Offlinemodus'
    } as const

  if (!s || !d.state.connected || s > 32)
    return {
      faIcon: 'signal-slash',
      description: 'Verbindungsfehler festgestellt',
      importance: 'error'
    } as const
  if (s <= 2)
    return { faIcon: 'signal-weak', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 8)
    return { faIcon: 'signal-fair', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 14)
    return { faIcon: 'signal-good', description: `OK (${s}/32)`, importance: 'ok' } as const
  if (s <= 20)
    return { faIcon: 'signal-strong', description: `OK (${s}/32)`, importance: 'ok' } as const

  return { faIcon: 'signal-perfect', description: `OK (${s}/32)`, importance: 'ok' } as const
}

//endregion

function getBatteryDeviceStatus(state: { powerSupply: boolean }): DeviceStatus {
  const base = { label: 'Versorgung' }
  if (state.powerSupply)
    return {
      ...base,
      faIcon: 'plug',
      importance: 'ok',
      description: 'Netzanschluss'
    } as const
  return {
    ...base,
    faIcon: 'battery-exclamation',
    importance: 'warn',
    description: 'Batterie'
  } as const
}

export function getInspectionStatus(d: Luminaire): DeviceStatus {
  const base = { label: 'Tests' }
  if (d.state.lastFunctionTestResult === 't' || d.state.lastDurationTestResult === 't')
    return {
      ...base,
      faIcon: 'clipboard-list',
      description:
        'Einer der letzten Tests konnte nicht durchgeführt werden, da die Leuchte nicht auf die Anfrage reagierte.',
      importance: 'warn'
    } as const
  return { ...base, importance: 'hidden' } as const
}

function getFailureStatus(dev: LuminaireDevice | GatewayDevice): DeviceStatus {
  const hasBattFailure = dev.state.failure.some((it) =>
    [
      'BATTERYCAPACITY',
      'BATTERYCRITICAL',
      'BATTERYFAILURE',
      'BATTERYMISSING',
      'BATTERYSHORTED'
    ].includes(it)
  )

  const hasLedFailure = dev.state.failure.some((it) =>
    ['LEDFAILURE', 'LEDMISSING', 'LEDINCOMPATIBLE', 'LEDSHORTED'].includes(it)
  )

  const hasTestFailure = dev.state.failure.some((it) => it === 'TESTFAILURE')

  const description =
    hasBattFailure && hasLedFailure
      ? 'Totalausfall'
      : hasBattFailure && !hasLedFailure
      ? 'Batteriefehler'
      : !hasBattFailure && hasLedFailure
      ? 'Leuchtmittelfehler'
      : hasTestFailure
      ? 'Ein Test ist Fehlgeschlagen'
      : undefined

  const base = { label: 'Funktionalität' }
  if (description)
    return {
      ...base,
      faIcon: 'triangle-exclamation',
      importance: 'error',
      description
    } as const
  return {
    ...base,
    faIcon: 'circle-check',
    importance: 'ok',
    description: 'Keine Defekte'
  } as const
}

function getUpdateStatus(state: { isUpdateNeeded: boolean }): DeviceStatus {
  const base = { label: 'Update' }
  if (state.isUpdateNeeded)
    return {
      ...base,
      faIcon: 'cloud-arrow-up',
      importance: 'important',
      description: 'Update verfügbar'
    } as const
  return { ...base, importance: 'hidden' } as const
}

function getTrafficStatus(state: { trafficState?: 'L' | 'W' | string }): DeviceStatus {
  const base = { label: 'Datenvolumen' }
  if (state.trafficState === 'L')
    return {
      ...base,
      faIcon: 'gauge-max',
      importance: 'error',
      description:
        'Extreme Volumenüberschreitung. Temporäre Einschränkung der Netzwerkverbindung möglich.'
    } as const
  if (state.trafficState === 'W')
    return {
      ...base,
      faIcon: 'gauge-high',
      importance: 'warn',
      description: 'Monatliches Inklusiv-Volumen fast erreicht'
    } as const
  return { ...base, faIcon: 'gauge-min', importance: 'ok', description: 'Ok' } as const
}

function getLastMessageStatus(
  d: Extract<Device, { deviceType: 'luminaire' | 'cps' | 'gateway' }>
): DeviceStatus {
  const base = { label: 'Letzte Meldung' }
  if (!d.lastMessage)
    return {
      ...base,
      faIcon: 'message-question',
      description: 'Letzte Nachricht ist unbekannt',
      importance: 'normal'
    } as const
  return {
    ...base,
    faIcon: 'message-check',
    description: 'Letzte Nachricht',
    importance: 'normal'
  } as const
}

function getCpsWarningStatus(d: Cps): DeviceStatus {
  // CPS isn't yet integrated into the device-detail page. The following status will only be shown in DeviceStatusIcons.
  const base = { label: 'Fehler' }
  if (
    (d.config.enableOperation && !d.state.operation) ||
    (d.config.enableLuminaireFailure && d.state.luminaireFailure) ||
    (d.config.enableMalfunction && d.state.malfunction)
  )
    return {
      ...base,
      faIcon: 'triangle-exclamation',
      description: 'Fehler festgestellt',
      importance: 'error'
    }

  return { ...base, faIcon: 'check', description: 'Keine Fehler', importance: 'normal' }
}

function getCpsBatteryStatus(d: Cps): DeviceStatus {
  // CPS isn't yet integrated into the device-detail page. The following status will only be shown in DeviceStatusIcons.
  const base = { label: 'Batteriebetrieb' }
  if (d.config.enableBatteryActive && d.state.batteryActive)
    return { ...base, faIcon: 'battery-half', description: 'Aktiv', importance: 'normal' }

  return { ...base, faIcon: 'check', description: 'Nicht Aktiv', importance: 'normal' }
}

type DeviceStatusKey =
  | 'failure'
  | 'traffic'
  | 'batteryPower'
  | 'inspection'
  | 'update'
  | 'connection'
  | 'lastMessage'
  | 'cpsWarning'
  | 'cpsBattery'

/** Return all statuses as a keyed object to make them better readable in Aurelia */
export function getDeviceStatuses(d: Device) {
  const statuses: { [k in DeviceStatusKey]?: DeviceStatus } = {}

  if (d.deviceType === 'gateway' || d.deviceType === 'luminaire') {
    statuses.failure = getFailureStatus(d)
    statuses.batteryPower = getBatteryDeviceStatus(d.state)
  }
  if (d.deviceType === 'luminaire') {
    if (hasDefined(d.state, 'isUpdateNeeded')) statuses.update = getUpdateStatus(d.state)
    statuses.traffic = getTrafficStatus(d.state)
    statuses.inspection = getInspectionStatus(d)
  }
  if (d.deviceType === 'luminaire' || d.deviceType === 'cps' || d.deviceType === 'gateway') {
    statuses.connection = getConnectionStatus(d)
    statuses.lastMessage = getLastMessageStatus(d)
  }
  if (d.deviceType === 'cps') {
    statuses.cpsWarning = getCpsWarningStatus(d)
    statuses.cpsBattery = getCpsBatteryStatus(d)
  }

  return statuses
}
