export class ImsiFormatValueConverter {
  constructor() {
    // empty
  }

  public toView(value: string | null | undefined): string {
    if (value === null || value === undefined) {
      return ''
    }

    // Konvertierung der GatewayID, die auch als IMSI übermittelt wird. 
    // 0001000000000007 zu 00 00 00 00 00 07
    // GatewayID ist 16 Zeichen lang, eine IMSI ist 15 Zeichen lang
    if (value.length >= 16) {
      const rightPart = value.slice(-12) // Die rechten 12 Zeichen des Strings extrahieren
      let formattedStr = ''
       
      for (let i = 0; i < rightPart.length; i++) {
        // Füge jedes Zeichen zum formatierten String hinzu
        formattedStr += rightPart[i]
        // Füge nach jedem zweiten Zeichen ein Leerzeichen hinzu, außer beim letzten Zeichen
        if ((i + 1) % 2 === 0 && i !== rightPart.length - 1) {
            formattedStr += ' '
        }
      }   
      return formattedStr
    }

    const split = 5
    return `${value.substring(0, value.length - split)}-${value.substring(
      value.length - split,
      value.length
    )}`
  }
}
