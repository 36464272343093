import { compact, map, toPairs } from 'lodash/fp'
import { chainFlow } from './chainFlow'

export type TimeString = string // "19:00:00"
export type DateString = string // "yyyy-mm-dd"

export function isTimeString(it: string) {
  const expr = /^[0-9]{1,2}:[0-9]{1,2}(:[0-9]{1,2})?$/i

  return it.match(expr)
}

export function isValidDate(d: Date) {
  // noinspection SuspiciousTypeOfGuard
  return d instanceof Date && !isNaN(d.getTime())
}

export function toISODateString(d: Date) {
  return d.toISOString().split('T')[0] // "yyyy-mm-dd"
}

export function doesBrowserSupportDateInput() {
  const input = document.createElement('input')
  input.setAttribute('type', 'date')

  const notADateValue = 'not-a-date'
  input.setAttribute('value', notADateValue)

  return input.value !== notADateValue && input.type === 'date'
}

export interface WeekdaySelection {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export type Weekday = keyof WeekdaySelection

export function formatWeekday(day: Weekday) {
  switch (day) {
    case 'monday':
      return 'Mo'
    case 'tuesday':
      return 'Di'
    case 'wednesday':
      return 'Mi'
    case 'thursday':
      return 'Do'
    case 'friday':
      return 'Fr'
    case 'saturday':
      return 'Sa'
    case 'sunday':
      return 'So'
  }
}

/**
 * From {@link WeekdaySelection} to `["monday", "tuesday"]`
 */
export function getWeekdayListFromSelection(selection: WeekdaySelection): Weekday[] {
  return chainFlow(
    selection,
    toPairs,
    map(([weekday, isSelected]: [string, boolean]) => (isSelected ? weekday : undefined)),
    compact
  ) as Weekday[]
}

export const excludeSecondsFromTimeString = (it: TimeString) => it.split(':').slice(0, 2).join(':')
