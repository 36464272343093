import { autoinject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import { LocaleService } from 'services/locale-service'
import { v4 as uuid } from 'uuid'

/**
 * Dropdown list box to select a single entry from the list.
 */
@autoinject()
export class Selector {
  /**
   * generated id of HTML element. Bindable so the using page can set it.
   */
  @bindable public id: string = uuid()

  /**
   * Text label that is shown beside dropdown box. Bindable so the using page can set it.
   */
  @bindable public label: string

  /**
   * selected value. Bindable so the using page can set it.
   */
  @bindable public value: string

  /**
   * list with all values that are shown in the drop down box. Bindable so the using page can set it.
   */
  @bindable public options: string[]

  /**
   * Translation key of options
   */
  @bindable public translationkey?: string

  /**
   * true = it is required to select an entry from the box, false = no entry needed to be selected. Bindable so the
   * using page can set it.
   */
  @bindable public required = false

  /**
   * true = selected value of the box can not be changed. Bindable so the using page can set it.
   */
  @bindable public readonly = false

  /**
   * true = show label in select box
   */
  @bindable public singleline = false

  /**
   * class that will be applied to the used select
   */
  @bindable public selectclass?: string

  public constructor(private locale: LocaleService) {
    // empty
  }

  public textMatcher = (a: string, b: string) => a === b

  public getOptionsText(option: string): string {
    if (!this.translationkey) return option

    return this.locale.translate(this.translationkey, { context: option })
  }
}
