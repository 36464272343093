export type DeepPartial<O> = {
  [K in keyof O]?: O[K] extends Record<any, any> ? DeepPartial<O[K]> : O[K]
}

function isObject(it: unknown): it is Record<any, any> {
  return typeof it === 'object' && it !== null
}

export function diffDeep<T extends Record<any, any>>(oldObject: T, newObject: T): DeepPartial<T> {
  const changes: DeepPartial<T> = {}

  const sharedKeys = new Set<keyof T>([...Object.keys(oldObject), ...Object.keys(newObject)])
  for (const key of sharedKeys.keys()) {
    const valO = oldObject[key]
    const valN = newObject[key]

    if (valO === valN) continue

    if (isObject(valO) && isObject(valN)) {
      const subDiff = diffDeep(valO, valN)
      // @ts-ignore
      if (Object.keys(subDiff).length) changes[key] = subDiff
      continue
    }

    changes[key] = valN
  }

  return changes
}
