import { NotificationFrequency, SubscriptionGroup } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { bindable, containerless } from 'aurelia-templating'

const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const AVAILABLE_FREQUENCIES: { index: NotificationFrequency; description: string }[] = [
  { index: 'immediate', description: 'Sofort' },
  { index: 'daily', description: 'Täglich' },
  { index: 'weekly', description: 'Wöchentlich' }
]

@containerless()
export class SubscriptionGroupField {
  @bindable() readonly = false
  @bindable() workingCopy?: SubscriptionGroup
  @bindable() remoteState: SubscriptionGroup
  @bindable() label: string
  @bindable() suggestions: string[]

  @computedFrom('workingCopy.frequency', 'remoteState.frequency')
  get mayCauseImmediateNotification() {
    if (!this.workingCopy?.frequency) return false
    const wasChanged = this.workingCopy.frequency !== this.remoteState.frequency
    return wasChanged && this.workingCopy.frequency === 'immediate'
  }

  availableFrequencies = AVAILABLE_FREQUENCIES

  isEmailPattern(value: string): boolean {
    return EMAIL_REGEX.test(value)
  }
}
