import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Gateway, GatewayApi, GatewayLuminaireConnection } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-framework'
import { ErrorHttpResponseMessage } from 'aurelia-http-client'
import { memoize } from 'lodash'
import { Observable } from 'rxjs'
import { share } from 'rxjs/operators'
import { doWhileObserved } from '../_utils/doWhileObserved'
import { sse$ } from '../_utils/sseHandler'
import { GatewayDevice } from '../model/device'
import { AuthService } from './auth-service'
import { FacilityId } from './facility-service'

export type GatewayId = number

export type GatewayQuery = {
  facilityId: FacilityId
}

@autoinject()
export class GatewayService {
  gatewayEntityCache = new RxCache<string, GatewayDevice | undefined>({
    fetcher: async (imsi) => {
      const gateway = await this.gatewayApi.getGatewayByImsi({ imsi }).catch((e) => {
        if (e instanceof ErrorHttpResponseMessage && e.statusCode === 404) return undefined
        throw e
      })
      if (!gateway) return undefined
      return { ...gateway, deviceType: 'gateway' }
    }
  })

  gatewayQueryCache = new RxCache<GatewayQuery, Gateway[]>({
    fetcher: (query) => this.gatewayApi.getGatewaysByFacility({ id: query.facilityId })
  })

  gatewayLuminaireCache = new RxCache<number, GatewayLuminaireConnection[]>({
    fetcher: async (id) => this.gatewayApi.luminaries({ id })
  })

  // `memoize` in combination with `share` ensures that each imsi is only subscribed once
  gatewayChange$ = memoize((imsi) =>
    sse$(`/events/gateway/${imsi}`, 'changegateway', this.authService).pipe(share())
  )

  // `memoize` in combination with `share` ensures that each imsi is only subscribed once
  gatewayLumConnectionChange$ = memoize((imsi) =>
    sse$(`/events/gateway/${imsi}`, 'changegatewaylumconnection', this.authService).pipe(share())
  )

  constructor(private readonly gatewayApi: GatewayApi, private readonly authService: AuthService) {}

  getLuminairesWithRemoteUpdates$(id: number, imsi: string) {
    return this.gatewayLuminaireCache
      .get$(id)
      .pipe(
        doWhileObserved(() =>
          this.gatewayLumConnectionChange$(imsi).subscribe(() =>
            this.gatewayLuminaireCache.revalidate(id)
          )
        )
      )
  }

  getGatewayWithRemoteUpdates$(imsi: string): Observable<GatewayDevice | undefined> {
    return this.gatewayEntityCache
      .get$(imsi)
      .pipe(
        doWhileObserved(() =>
          this.gatewayChange$(imsi).subscribe(() => this.gatewayEntityCache.revalidate(imsi))
        )
      )
  }
}
