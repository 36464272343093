import { CircuitLabel } from '@nubix/spica-cloud-backend-client'

export class CircuitLabelFormatValueConverter {
  /** this is a copy of {@link formatCircuitLabel} in backend */
  public toView(circuit: CircuitLabel | undefined): string {
    if (!circuit) return '-'
    // `||` is used instead of `??` to replace empty strings with `-`
    return `${circuit.powerDistributor || '-'} | ${circuit.circuitNumber || '-'} | ${
      circuit.luminaireNumber || '-'
    }`
  }
}
