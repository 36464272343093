/**
 * Typescript complicates retrieval of properties, if the target is only defined in some members of a union.
 * See https://github.com/microsoft/TypeScript/issues/1260
 *
 * ```ts
 * const obj: { a: number | undefined } | { b: number | undefined }
 * const default = 0
 *
 * const a1 = obj.a                                        // TypeError
 * const a2 = ('a' in obj) ? obj.a : default               // number | undefined
 * const a3 = ('a' in obj) ? obj.a ?? default : default    // number
 *
 * const a3 =  get(obj, 'a') ?? default                    // number
 * ```
 */
export function get<O extends Record<string, unknown>, K extends O extends O ? keyof O : never>(
  obj: O,
  key: K
): K extends keyof O ? O[K] : keyof O extends K ? O[keyof O] | undefined : never {
  if (key in obj) {
    // @ts-ignore
    return obj[key]
  } else {
    // @ts-ignore
    return undefined
  }
}

export function getDynamic<O>(
  obj: O,
  key: string | number | symbol | undefined
): O extends O ? O[keyof O] | undefined : never {
  // @ts-ignore
  return obj[key]
}

export function hasDefined<O extends Record<any, any>, K extends O extends O ? keyof O : never>(
  obj: O,
  key: K
): obj is O & (O extends { [k in K]: O[k] } ? { [k in K]: O[k] } : never) {
  return key in obj && key !== undefined && key !== null
}
