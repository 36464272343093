import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { FacilityGroup, FacilityGroupApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { memoize } from 'lodash'
import { share, tap } from 'rxjs/operators'
import { sse$ } from '../_utils/sseHandler'
import { AuthService } from './auth-service'

export type FacilityGroupId = FacilityGroup['id']

@autoinject()
export class FacilityGroupService {
  public facilityGroupEntityCache = new RxCache<FacilityGroupId, FacilityGroup | undefined>({
    fetcher: (id) => this.facilityGroupApi.getGroupById({ id })
  })

  public facilityGroupQueryCache = new RxCache<undefined, FacilityGroup[]>({
    fetcher: async () => {
      const companies = await this.facilityGroupApi.getGroups()
      companies.forEach((it) => this.facilityGroupEntityCache.prime(it.id, it))

      return companies
    }
  })

  constructor(
    private readonly facilityGroupApi: FacilityGroupApi,
    private readonly authService: AuthService
  ) {}

  // memoize in combination with share ensures that the stream is only subscribed once
  public groupParticipationChange$ = memoize(() =>
    sse$('/events/groupParticipation/', 'changegroupparticipation', this.authService).pipe(
      share(),
      tap()
    )
  )
}
