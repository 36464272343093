import { inject } from 'aurelia-dependency-injection'
import { I18N } from 'aurelia-i18n'
import * as moment from 'moment'
import { getDynamic } from './objectUtils'

const LOCALE_MAPPING = {
  normal: 'de',
  short: 'de-short-relative'
} as const

type Format = keyof typeof LOCALE_MAPPING

moment.defineLocale('de-short-relative', {
  parentLocale: 'de',
  relativeTime: {
    future: '-%s',
    past: '%s',
    s: '0s',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1D',
    dd: '%dD',
    // weeks aren't supported for some reason
    // w:  "1W",
    // ww: "%dW",
    M: '1M',
    MM: '%dM',
    y: '1J',
    yy: '%dJ'
  }
})

/**
 * Aurelia converter to show the time difference to a timestamp in the past as text in the GUI. This converter will show
 * the time difference in the kind of "1 minute ago", "some seconds ago", "3 hours ago".
 */
@inject(I18N)
export class TimeAgoValueConverter {
  constructor(private readonly i18n: I18N) {}

  public toView(value: Date | undefined, format?: Format) {
    if (!value) {
      return '?'
    }

    let mom: moment.Moment = moment(value)

    if (mom.isValid()) {
      const locale = getDynamic(LOCALE_MAPPING, format) ?? this.i18n.getLocale()

      if (locale) {
        mom = mom.locale(locale)
      }
    }

    return mom.from(moment())
  }
}
