import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { mutateAllCaches } from '@nubix/npm-utils/src/cache/GlobalControl'
import { FacilityGroup, FacilityGroupApi } from '@nubix/spica-cloud-backend-client'
import { autoinject, computedFrom, useView } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { Router } from 'aurelia-router'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { FacilityGroupDescriptionValueConverter } from '../_utils/format/facilityGroupDescription'
import { FacilityGroupId, FacilityGroupService } from '../services/facility-group-service'

export interface DeleteFacilityGroupParams {
  id: FacilityGroupId
}

@autoinject()
@useView(PLATFORM.moduleName('facility-groups/delete-facility-group.html'))
export class DeleteFacilityGroup extends subscriberMixin() {
  @computedFrom('company')
  get expectedConfirmationText() {
    return FacilityGroupDescriptionValueConverter.format(this.company)
  }

  company: FacilityGroup
  confirmationText = ''

  constructor(
    private readonly companyService: FacilityGroupService,
    private readonly facilityGroupApi: FacilityGroupApi,
    private readonly router: Router
  ) {
    super()
  }

  async activate(params: DeleteFacilityGroupParams) {
    const company = await this.companyService.facilityGroupEntityCache.get(params.id)
    if (!company) {
      this.navigateUp()
      return
    }
    this.company = company
  }

  navigateUp() {
    this.router.navigateToRoute('facilities')
  }

  async submit(): Promise<ActionResult> {
    if (this.confirmationText !== this.expectedConfirmationText) {
      return { hasError: true, errorMessage: 'Bitte geben sie den Bestätigungstext korrekt ein.' }
    }

    const requestProgress = this.facilityGroupApi.deleteGroup({ id: this.company.id })
    mutateAllCaches({ requestProgress })
    await requestProgress
    this.navigateUp()
  }
}
