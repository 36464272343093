import { HistoryMode } from '_utils/app-history/mode'
import { NavigationInstruction, NavModel, RouteConfig, Router } from 'aurelia-router'

/**
 * t?: translation
 * p?: permissions
 * h?: history: how will the be navigated to when going back default: one-time
 * b?: back: is back-button visible? default: true
 * d?: disabled: is header readonly?
 */
export interface IRouteConfigSettings {
  t?: string
  requiresAuthentication?: boolean
  h?: HistoryMode
  b?: boolean
  d?: boolean
}

export interface IExtRouteConfig extends RouteConfig {
  settings?: IRouteConfigSettings
}

export interface IExtNavModel extends NavModel {
  settings: IRouteConfigSettings
}

export function findChildNavigationInstruction(
  navigationInstructions: NavigationInstruction
): NavigationInstruction {
  if (navigationInstructions.viewPortInstructions.default.childNavigationInstruction) {
    return findChildNavigationInstruction(
      navigationInstructions.viewPortInstructions.default.childNavigationInstruction
    )
  }

  return navigationInstructions
}

/**
 * Attempts to navigate to a route.
 * If the route doesn't exist in the current router, checks parent routes recursively.
 */
export async function findNavigationToRoute(
  router: Router,
  route: string,
  params?: any
): Promise<boolean> {
  if (router.routes) {
    for (const config of router.routes) {
      if (config.name === route) {
        return router.navigateToRoute(route, params)
      }
    }
  }
  if (router.parent && router.parent.routes) {
    for (const config of router.parent.routes) {
      if (config.name === route) {
        return router.parent.navigateToRoute(route, params)
      }
    }
  }

  // Default, might cause a route not found error
  return router.navigateToRoute(route, params)
}

export type Route = {
  name: string
  params: Record<string, string>
}
