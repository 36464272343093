import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import {
  Contact,
  ContactInvitation,
  FacilityGroup,
  User,
  UserApi
} from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import { PermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { loadingResult, Result } from '../_utils/FormCache'
import { findNavigationToRoute } from '../_utils/routing'

/**
 *
 */
interface FacilityGroupWithParticipants {
  company: FacilityGroup
  contacts: Contact[]
  invitations: ContactInvitation[]
  may: PermissionTable
}

/**
 * Start page of account pages. Shows an overview of the account of the logged in user. The user can navigate to
 * subpages to edit login, contact and company data from this page.
 */
@autoinject
export class AllOptions extends subscriberMixin() {
  currentUser: User
  companiesWithParticipants: Result<FacilityGroupWithParticipants[]> = loadingResult()

  constructor(private readonly router: Router, private readonly userApi: UserApi) {
    super()
  }

  /**
   * Load account details from server and display them
   */
  public async activate() {
    this.currentUser = await this.userApi.getCurrentUser()
  }

  /**
   * Navigate to the contact-edit form.
   * @param id The id of the contact to edit.
   */
  public async editUser() {
    await findNavigationToRoute(this.router, 'user-edit')
  }

  /**
   * Navigate to the facilities overview.
   */
  public async navigateUp() {
    await findNavigationToRoute(this.router, 'facilities')
  }

  /**
   * Navigate to login-change screen.
   */
  public async changeLogin() {
    await findNavigationToRoute(this.router, 'change-login')
  }

  //endregion
}
