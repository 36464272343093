import { Facility, GatewayApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { DeviceType } from 'model/device'
import { findNavigationToRoute } from '../_utils/routing'
import { assertUnreachable } from '../_utils/utils'
import { reportErr } from '../errorReporting'
import { extractImsiFromDeviceUrl } from '../model/device-add'
import { CpsService } from '../services/cps-service'
import { FacilityService } from '../services/facility-service'
import { FmsService } from '../services/fms-service'
import { GatewayService } from '../services/gateway-service'
import { LuminaireService } from '../services/luminaire-service'

interface IDeviceAddParams {
  /**
   * initial value for imsi
   */
  imsi?: string

  /**
   * initial value for facility id
   */
  facilityId?: number

  /**
   * initial value for device type
   */
  deviceType?: DeviceType
}

const LOG = getLogger('add-device')

/**
 * This page allows the user to add a new Device.
 * The device is either a Luminaire, CPS or a FMS.
 * It can be selected either by entering it's IMSI, or scanning a QR-code.
 */
@autoinject()
export class DeviceAdd {
  public imsi: string

  public formRoot: HTMLFormElement

  public cachedFacilities: Facility[]
  public facilityId?: number
  public deviceType: DeviceType = 'luminaire'

  public showQrReader = false
  public qrData: string

  constructor(
    private readonly router: Router,
    private readonly _facilityService: FacilityService,
    private readonly _cpsService: CpsService,
    private readonly _fmsService: FmsService,
    private readonly _luminaireService: LuminaireService,
    private readonly gatewayApi: GatewayApi,
    private readonly gatewayService: GatewayService
  ) {}

  /**
   * Navigate back to the luminaire-list for the facility that is selected in the facility-field.
   */
  public async navigateUp(overrideFacilityId?: number) {
    const facilityId = this.facilityId
      ? this.facilityId
      : overrideFacilityId !== undefined
      ? overrideFacilityId
      : undefined

    if (facilityId !== undefined) {
      await findNavigationToRoute(this.router, 'device-list', { id: facilityId })
    } else {
      await findNavigationToRoute(this.router, 'facilities')
    }
  }

  public get selectedFacility() {
    if (this.facilityId) {
      for (const faci of this.cachedFacilities) {
        if (faci.id === this.facilityId) {
          return faci
        }
      }
    }

    return undefined
  }

  /**
   * On activation, load available facilities from the server so they can be displayed in the dropdown menu.
   * Set initial values based on params
   * @param params - Parameters to be passed via the url of the page. See {@link IDeviceAddParams}
   */
  public async activate(params: IDeviceAddParams) {
    this.facilityId = params.facilityId ? parseInt(params.facilityId.toString(), 10) : undefined
    if (params.imsi !== undefined) this.imsi = params.imsi
    if (params.deviceType !== undefined) this.deviceType = params.deviceType
    this.cachedFacilities = await this._facilityService
      .queryEntities()
      .then(async (facis: Facility[]) => {
        return facis.filter((value) => !value.state.deactivated)
      })
  }

  public facilityMatcher = (a: number, b: number) => a === b

  /**
   * Submit the form to the server.
   */
  public async addDevice() {
    if (!this.formRoot.checkValidity()) {
      this.formRoot.reportValidity()

      throw new Error()
    }

    if (!this.facilityId) throw new Error('kein Objekt ausgewählt')

    try {
      switch (this.deviceType) {
        case 'luminaire':
          await this._luminaireService.addEntity(this.imsi, this.facilityId)
          break
        case 'fms':
          await this._fmsService.addEntity(this.imsi, this.facilityId)
          break
        case 'cps':
          await this._cpsService.addEntity(this.imsi, this.facilityId)
          break
        case 'gateway': {
          const requestProgress = this.gatewayApi.addGateway({
            imsi: this.imsi,
            facilityId: this.facilityId
          })
          this.gatewayService.gatewayQueryCache.mutate({ requestProgress })
          await requestProgress
          break
        }
        default:
          assertUnreachable(this.deviceType)
      }
    } catch (e) {
      reportErr(e, 'Error adding device')
      throw new Error(
        'Das Gerät konnte nicht angemeldet werden. ' +
          'Eventuelle Ursachen finden sie in den FAQ. ' +
          ' Für weiter Unterstützung kontaktieren Sie den technischen Support: +49 2131 52310-89'
      )
    }

    this.navigateUp()
  }

  /**
   * Navigate to the luminaire list
   */
  public async home() {
    await findNavigationToRoute(this.router, 'device-list', { id: this.facilityId })
  }

  public onCodeScanned(code: string) {
    const imsi = extractImsiFromDeviceUrl(code, window.location)
    if (!imsi) return
    this.imsi = imsi
    this.showQrReader = false
  }
}
