import { bindable } from 'aurelia-framework'
import { DeviceList } from '../devices/device-list'
import { FacilityList } from '../facilities/facility-list'

import * as clickableStyles from './presentation/widget/clickable-layouts.module.scss'

export class ListFilter {
  readonly clickableStyles = clickableStyles
  @bindable() model: FacilityList | DeviceList
}
