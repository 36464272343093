import { Facility } from '@nubix/spica-cloud-backend-client'
import { computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-templating'
import styles from './facility-page-header.module.scss'

/**
 * Displays information of a facility as well as it's image.
 */
@autoinject()
export class FacilityPageHeader {
  styles = styles

  @bindable public image: string
  @bindable public facility: Facility

  /**
   * Get the path of an image based on it's location in the source directory.
   */
  @computedFrom('image')
  public get resolvedImage() {
    // `../images/` is important, because that's how Webpack knows to replace this path
    return import(`../images/${this.image}`).then((imported) => imported.default)
  }
}
