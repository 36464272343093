exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1DYeq8b7NerxiO88hAwf1w{padding:12px;display:flex;gap:12px}.QDqS-k-fOTWEx5Rtknl61{flex:1}._1QIi-zZHE8XEAKMnPJZj8C{align-self:center}._yrCMqDeGclAHJz79d4bF{border:1px dashed #e1e1e7}._2QbYPvhlXdPWmda8MkczsR{background-color:rgba(255,163,0,.1)}._2Q-z9IjoiB6IEARkpUbBD8 [slot=icon]{color:#ffa300}._2Q-z9IjoiB6IEARkpUbBD8{background-color:rgba(225,39,33,.12)}._2Q-z9IjoiB6IEARkpUbBD8 [slot=icon]{color:#b30000}", ""]);

// Exports
exports.locals = {
	"container": "_1DYeq8b7NerxiO88hAwf1w",
	"message": "QDqS-k-fOTWEx5Rtknl61",
	"action": "_1QIi-zZHE8XEAKMnPJZj8C",
	"dashed": "_yrCMqDeGclAHJz79d4bF",
	"warn": "_2QbYPvhlXdPWmda8MkczsR",
	"error": "_2Q-z9IjoiB6IEARkpUbBD8"
};