import { IExtRouteConfig } from '_utils/routing'
import { autoinject } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { activationStrategy, Router, RouterConfiguration } from 'aurelia-router'

/**
 * This class specifies all routes related to the signup process.
 * The user will navigate from
 */
@autoinject
export class App {
  public router: Router

  // tslint:disable-next-line:max-func-body-length
  public configureRouter(config: RouterConfiguration, router: Router) {
    const routes: IExtRouteConfig[] = [
      { route: '', redirect: 'start' },
      {
        route: 'start',
        name: 'start',
        moduleId: PLATFORM.moduleName('signup/registration'),
        title: 'Registrierung',
        activationStrategy: activationStrategy.invokeLifecycle,
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'success-notification',
        name: 'success-notification',
        moduleId: PLATFORM.moduleName('signup/success-notification'),
        title: 'Bestätigung',
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'confirm-email',
        name: 'confirm-email',
        moduleId: PLATFORM.moduleName('signup/confirm-email'),
        title: 'Bestätigungs-Email versendet',
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'confirm/:id',
        name: 'confirm',
        moduleId: PLATFORM.moduleName('signup/confirm'),
        title: 'Registrierung wird geprüft',
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'confirm-failure',
        name: 'confirm-failure',
        moduleId: PLATFORM.moduleName('signup/confirm-failure'),
        title: 'Registrierung fehlgeschlagen',
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'confirm-success',
        name: 'confirm-success',
        moduleId: PLATFORM.moduleName('signup/confirm-success'),
        title: 'Registrierung abgeschlossen',
        settings: {
          b: false,
          d: true
        }
      },
      {
        route: 'reset-password',
        name: 'reset-password',
        moduleId: PLATFORM.moduleName('signup/reset-password'),
        title: 'Passwort zurücksetzen',
        settings: {
          b: true,
          d: true
        }
      },
      {
        route: 'undo/:id',
        name: 'undo',
        moduleId: PLATFORM.moduleName('signup/undo'),
        title: 'Login zurücksetzen',
        settings: {
          b: false,
          d: true
        }
      }
    ]

    config.map(routes)

    this.router = router
  }
}
