import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Cps, CpsApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { CpsDevice } from '../model/device'
import { FacilityId } from './facility-service'

export type CpsId = Cps['id']
export type CpsImsi = Cps['imsi']

const LOG = getLogger('cps-srvc')

@autoinject
export class CpsService {
  public cpsEntityCache = new RxCache<CpsId, CpsDevice | undefined>({
    fetcher: async (id) => {
      const cps = await this.cpsApi.getCpsById({ id })
      this.cpsEntityImsiCache.prime(cps.imsi, cps.id)

      return { ...cps, deviceType: 'cps' }
    }
  })

  public cpsEntityImsiCache = new RxCache<CpsImsi, CpsId | undefined>({
    fetcher: async (imsi) => {
      const cps = await this.cpsApi.getCpsByImsi({ imsi })
      this.cpsEntityCache.prime(cps.id, { ...cps, deviceType: 'cps' })

      return cps?.id
    }
  })

  public cpsFacilityCache = new RxCache<number, CpsId[]>({
    fetcher: async (facilityId) => {
      const cps = await this.cpsApi.getCpsByFacility({ id: facilityId })
      cps.forEach((it) => {
        this.cpsEntityCache.prime(it.id, { ...it, deviceType: 'cps' })
        this.cpsEntityImsiCache.prime(it.imsi, it.id)
      })

      return cps.map((it) => it.id)
    }
  })

  constructor(private readonly cpsApi: CpsApi) {}

  public async getEntities(ids: number[]): Promise<(Cps | undefined)[]> {
    return await Promise.all(ids.map((id) => this.cpsEntityCache.get(id)))
  }

  public async getEntity(id: CpsId): Promise<Cps | undefined> {
    return this.cpsEntityCache.get(id)
  }

  public async getEntityByImsi(imsi: CpsImsi): Promise<Cps | undefined> {
    const id = await this.cpsEntityImsiCache.get(imsi)
    if (!id) return undefined

    return this.cpsEntityCache.get(id)
  }

  /**
   * Register device at another facility.
   * @param imsi
   *  IMSI of device
   * @param facilityId
   *  ID of new facility
   */
  public async addEntity(imsi: CpsImsi, facilityId: FacilityId) {
    // add on backend
    const requestProgress = this.cpsApi.addCps({ imsi, facilityId })

    this.cpsEntityCache.mutate({ requestProgress })
    this.cpsFacilityCache.mutate({ requestProgress })
    this.cpsEntityImsiCache.mutate({ requestProgress })

    return requestProgress
  }

  public async removeEntity(id: CpsId) {
    const cps = await this.getEntity(id)

    // delete entity in DB but ignore errors since we remove all traces anyway
    const requestProgress = this.cpsApi.deleteCps({ id })
    this.cpsEntityCache.mutate({ requestProgress })
    await requestProgress

    if (cps) {
      this.cpsEntityImsiCache.prime(cps.imsi, undefined)
      if (cps.location.facilityId) {
        this.cpsFacilityCache.invalidate(cps.location.facilityId)
      }
    }
  }

  public async updateEntity(changed: Cps): Promise<Cps> {
    let error = 200
    let msg = undefined
    const result: Response | undefined = await this.cpsApi
      .updateCps({
        id: changed.id,
        body: changed
      })
      .catch(async (e) => {
        error = e.status ?? e.statusCode ?? 407
        msg = e.message

        return undefined
      })

    this.cpsEntityCache.invalidate(changed.id)

    // handle unexpected result of type Response
    const response = result as any
    if (response && (response.status || response.statusCode)) {
      msg = response.statusText ?? response.statusCode
      error = response.status
    }

    if (error && error !== 200 && error !== 204) {
      LOG.warn('error: %d %s during update of cps %d', error, msg, changed.id)
      throw new Error(`${error} ${msg}`)
    }

    return changed
  }
}
