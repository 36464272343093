import { getLogger } from 'aurelia-logging'
import { getBackendEventSource } from 'main'
import { Observable } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { clearTimeout, setTimeout } from 'timers'
import { reportErr } from '../errorReporting'

const LOG = getLogger('sse-handler')

interface IStoredListener {
  event: string
  call: EventListenerOrEventListenerObject
}

/**
 * SSE handler that handles general events. This handler will connect to the Backend and listen for
 * all events. It handles connection losses, reconnects and stopping of the handler. Other components can register
 * custom event listeners to this handler that will be triggered if a matching event is received.
 */
export class SseHandler {
  public autoUpdate = false

  private sseSource?: EventSource

  private reconnectTimer?: NodeJS.Timeout

  private readonly storedListeners: IStoredListener[] = []

  private reconnectCall: () => void

  constructor(public readonly streamPath: string, private readonly authServ: AuthService) {
    // empty
  }

  public close() {
    this.clearReconnectTimer()

    this.closeStream()
  }

  public connect() {
    this.clearReconnectTimer()

    this.connectStream()
  }

  public connectStream() {
    LOG.debug('start event tracking', this.streamPath)
    const listener4Msg = (event: any) => {
      if (event.type === 'open') {
        // stop reconnect
        this.clearReconnectTimer()
        // mark source as usable
        this.autoUpdate = true
      }
    }
    const listener4Conn = (event: any) => {
      const type = event.type

      LOG.info(this.streamPath, `${type}: ${event.data || '-'}`)

      if (type === 'error') {
        this.clearReconnectTimer()
        // reconnect after 3 seconds if no successfull connection is found
        this.reconnectTimer = setTimeout(async () => {
          this.reconnectStream()
          LOG.debug('RECONNECT needed...', this.streamPath)
        }, 3000)

        return
      }

      this.close()
    }
    this.sseSource = getBackendEventSource(this.streamPath, this.authServ.sessionToken)
    this.sseSource.addEventListener('open', listener4Msg)
    this.sseSource.addEventListener('message', listener4Msg)
    this.sseSource.addEventListener('error', listener4Conn)
    this.sseSource.addEventListener('logout', listener4Conn)
    this.sseSource.addEventListener('close', listener4Conn)

    for (const stored of this.storedListeners) {
      this.sseSource.addEventListener(stored.event, stored.call)
    }
  }

  public addEventListener(event: string, call: EventListenerOrEventListenerObject) {
    this.storedListeners.push({
      event,
      call
    })
    if (this.sseSource) {
      this.sseSource.addEventListener(event, call)
    }
  }

  public onReconnect(call: () => void) {
    this.reconnectCall = call
  }

  protected clearReconnectTimer() {
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer)
    }
  }

  protected closeStream() {
    LOG.debug('stop event tracking', this.streamPath)
    if (this.sseSource) {
      this.sseSource.close()
      this.sseSource = undefined
      this.autoUpdate = false
    }
  }

  protected reconnectStream(): boolean {
    try {
      this.close()
      this.connectStream()
      LOG.debug('RECONNECTED!', this.streamPath)

      if (this.reconnectCall) {
        try {
          this.reconnectCall()
        } catch (error) {
          reportErr(error, { path: this.streamPath }, 'Error during reconnecting')
        }
      }

      return true
    } catch (error) {
      reportErr(error, { path: this.streamPath }, 'Error during reconnecting')
      this.reconnectTimer = setTimeout(async () => {
        this.reconnectStream()
      }, 20000)
    }

    return false
  }
}

export function sse$(path: string, eventName: string, authService: AuthService) {
  return new Observable<void>((subscriber) => {
    const handler = new SseHandler(path, authService)
    handler.addEventListener(eventName, () => subscriber.next())
    handler.onReconnect(() => subscriber.next())
    handler.connect()

    return () => handler.close()
  }).pipe(debounceTime(200))
}
