export enum HistoryMode {
  /**
   * (default) subsequent duplicates will be ignored. Will use parameters of latest route.
   */
  OneTime,
  /**
   * route will appear in history
   */
  Full,
  /**
   * route will not appear in history
   */
  Skip,
  /**
   * route will be included if it is part of a sequence that the user hasn't left yet.
   */
  Sequence
}
