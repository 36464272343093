import { bindable, computedFrom } from 'aurelia-framework'
import { omit } from 'lodash'
import { Device } from 'model/device'
import { getDeviceStatuses } from '../../../model/device-status'

import styles from './device-status-icons.module.scss'

export class DeviceStatusIcons {
  styles = styles

  @bindable device?: Device

  @computedFrom('device')
  get statuses() {
    if (!this.device) return undefined

    // Last message and signal are displayed at the top right of the card instead
    return Object.values(omit(getDeviceStatuses(this.device), ['connection', 'lastMessage']))
      .filter(isNotUndefined)
      .filter(
        (s) => s.importance === 'warn' || s.importance === 'error' || s.importance === 'important'
      )
  }
}

function isNotUndefined<T>(v: T | undefined): v is T {
  return v !== undefined
}
