import { autoinject } from 'aurelia-framework'
import {
  activationStrategy,
  RoutableComponentActivate,
  RoutableComponentDetermineActivationStrategy,
  Router
} from 'aurelia-router'

import { AuthService } from 'services/auth-service'
import { LocaleService } from 'services/locale-service'
import { deleteHistory } from './_utils/app-history/utils'

/**
 * The main starting page of the cloud. Shows a login to the user.
 */
@autoinject
export class Home
  implements RoutableComponentActivate, RoutableComponentDetermineActivationStrategy
{
  /**
   * URL path where to navigate after successfull login.
   */
  public target: string

  constructor(
    public readonly router: Router,
    private readonly locServ: LocaleService,
    private readonly authServ: AuthService
  ) {
    // empty
  }

  public determineActivationStrategy() {
    return activationStrategy.replace
  }

  /**
   * When activted the current data is fetched from the database and put into this model.
   */
  public async activate(params: any) {
    this.target = params.target

    // logout user
    if (this.authServ.isAuthenticated) {
      this.authServ.logout()
    }

    // reset start page index
    deleteHistory(this.router)

    await this.locServ.loadLanguage()
  }
}
