import { HttpClient } from 'aurelia-fetch-client'
import url from 'url'
import { mimeByExtension } from './mime-types'

export async function uploadToUrl(
  httpClient: HttpClient,
  files: FileList,
  fullUrl: string,
  method = 'POST'
): Promise<Response | void> {
  if (files.length < 1) return

  const urlObj = url.parse(fullUrl, true)
  const fetchOptions: RequestInit = Object.assign({}, { method })

  let contentTypeHeader: Dictionary<string> = {}
  // default mime type
  let mimeTp = 'application/octet-stream'
  // use mime type of first file in list
  // Currently, only single file upload is supported
  if (files.length > 0) {
    const file = files.item(0)
    if (!file) return

    const tmp = mimeByExtension(file.name)
    if (tmp) {
      mimeTp = tmp
    }
  }
  contentTypeHeader = { accept: mimeTp }

  if (contentTypeHeader) {
    fetchOptions.headers = Object.assign({}, contentTypeHeader, fetchOptions.headers)
  }

  const formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    formData.append('dataFile', files[i])
  }
  fetchOptions.body = formData

  return httpClient.fetch(url.format(urlObj), fetchOptions)
}

/**
 * Replaces forbidden characters and words in file name with underscore _. Some characters are forbidden to be used in
 * filenames by OS filesystems.
 * https://stackoverflow.com/questions/1976007/what-characters-are-forbidden-in-windows-and-linux-directory-names
 * https://stackoverflow.com/questions/280712/javascript-unicode-regexes
 * Forbidden characters in file name:
 * WINDOWS = > < : " / \ | ? * CON PRN AUX NUL COM LPT
 * WINDOWS = ASCII 0-31
 * WINDOWS = name ends with dot or space
 * LINUX = /
 * LINUX = ASCII 0
 * LINUX = $PATH 1>&2 (not forbidden but could cause problems)
 * MACOS = : /
 */
export function encodeOsSaveFileName(filename: string): string {
  return filename.replace(/[<>:"/\\|?*$ ]|(CON|PRN|AUX|NUL|COM|LPT)|[. ]$/gmu, '_')
}
