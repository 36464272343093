import { UserApi } from '@nubix/spica-cloud-backend-client'

import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { findNavigationToRoute } from '../_utils/routing'
import { reportErr } from '../errorReporting'

/**
 * Parameter of this page.
 */
interface IConfirmParams {
  /**
   * ID of a user
   */
  id: number
  /**
   * secret shared over email
   */
  bean?: string
}

const LOG = getLogger('confirm')

/**
 * Page to finish registration and activate a user account.
 */
@autoinject
export class ConfirmRegistration {
  constructor(private readonly router: Router, private readonly userApi: UserApi) {}

  public async activate(params: IConfirmParams) {
    let code = 204
    const result: Response | undefined = await this.userApi
      .activateUser({ id: params.id, bean: params.bean })
      .catch(async (error) => {
        LOG.debug(
          'error during registration confirm',
          error.status,
          error.statusCode,
          error.message
        )
        code = error.status ?? error.statusCode ?? 407

        return undefined
      })

    // handle unexpected result of type Response
    if (result && result.status) {
      LOG.debug('error during registration confirm', result.status, result.statusText)
      code = result.status
    }
    if (code && code !== 204 && code !== 200) {
      // do not use return for navigateToRoute
      findNavigationToRoute(this.router, 'confirm-failure', { code }).catch(async (error) =>
        LOG.debug(error.message)
      )
    } else {
      const route = params.bean && params.bean.length > 40 ? 'confirm-success' : 'confirm-email'
      findNavigationToRoute(this.router, route).catch((e) => reportErr(e))
    }
  }
}
