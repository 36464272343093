const getImsiRegex = (location: { hostname: string; port: string }) =>
  new RegExp(
    //
    // eslint-disable-next-line no-useless-escape
    `^(https?:\\/\\/)?${location.hostname}(:${location.port})?\\/#\\/app\\/luminaires\\/device-add\\?imsi=(?<imsi>\\d{15})\$`
  )

export function extractImsiFromDeviceUrl(
  rawUrl: string,
  location: { hostname: string; port: string }
): string | undefined {
  const regex = getImsiRegex(location)
  return rawUrl.match(regex)?.groups?.imsi
}
