import { bindable } from 'aurelia-templating'
import * as chrono from 'chrono-node'
import {
  DateString,
  doesBrowserSupportDateInput,
  isValidDate,
  toISODateString
} from '../../../../_utils/timeUtils'

export class DateField {
  //region Refs
  private inputRef: HTMLInputElement
  //endregion

  //region Props
  @bindable name: string

  @bindable label: string

  @bindable value: DateString

  @bindable readonly = false

  @bindable required = false
  //endregion

  private _inputValue: string

  /** Temporarily disable `valueChanged` so that we can update the binding without triggering a computation */
  private ignoreValueChanges = false

  private internallySetValue(newValue: string) {
    this.ignoreValueChanges = true
    this.value = newValue
    setImmediate(() => (this.ignoreValueChanges = false))
  }

  private get inputValue() {
    return this._inputValue
  }

  /** Changed by the binding to the input field. Sets the internal value and then computes the exposed value */
  private set inputValue(it: string) {
    this._inputValue = it

    if (!it) {
      this.inputRef.setCustomValidity('')
      this.internallySetValue('')
      return
    }

    const parsedDate = chrono.parseDate(it)

    if (!isValidDate(parsedDate)) {
      this.internallySetValue('')
      this.inputRef.setCustomValidity('Bitte geben Sie ein gültiges Datum ein')

      return
    }

    this.inputRef.setCustomValidity('')
    this.internallySetValue(toISODateString(parsedDate))
  }

  private valueChanged() {
    if (this.ignoreValueChanges) return

    if (doesBrowserSupportDateInput()) {
      this._inputValue = this.value
    } else {
      if (!this.value) {
        this.inputValue = ''
      } else {
        const parsedDate = new Date(this.value)
        this._inputValue = parsedDate.toLocaleDateString('de-DE') // 12.4.2020
      }
    }
  }
}
