export function containsTextToLower(source?: string, containing?: string): boolean {
  if (!source || !containing) return false

  return source.toLowerCase().indexOf(containing.toLowerCase()) > -1
}

export function isEmptyText(a?: string): boolean {
  return !a || a.length === 0
}

export function compareText(a?: string, b?: string): number {
  const emptyA = isEmptyText(a)
  const emptyB = isEmptyText(b)
  if (emptyA && emptyB) return 0
  // !a !b needed to silence debugger on return
  if (emptyA || !a) return 1
  if (emptyB || !b) return -1

  return a.localeCompare(b)
}

export function concatText(...strings: (string | undefined)[]): string {
  return strings.filter((it) => it !== undefined).join('')
}
