import { ContactInvitationWithCompany } from '@nubix/spica-cloud-backend-client'
import { autoinject, bindable } from 'aurelia-framework'
import { ModalService } from '../../_controls/presentation/layout/modal-service'
import { CreateFacilityGroup } from '../../facility-groups/create-facility-group'

import styles from './empty-screen.module.scss'

@autoinject
export class EmptyScreen {
  styles = styles

  @bindable invitations: ContactInvitationWithCompany

  constructor(private readonly modalService: ModalService) {}

  createFacilityGroup() {
    this.modalService.showModal({ viewModel: CreateFacilityGroup, model: undefined })
  }
}
