import { FacilityGroup } from '@nubix/spica-cloud-backend-client'
import { bindable, computedFrom } from 'aurelia-framework'
import { ContactRole } from 'spica-cloud-shared/lib/model/permissions'
import { ROLE_DESCRIPTIONS } from '../../../model/permissions'
import * as styles from './facility-group-description.module.scss'

export class FacilityGroupDescription {
  styles = styles
  @bindable facilityGroup: FacilityGroup | undefined

  /** If unset, the role is determined from the company object */
  @bindable myRole: ContactRole | undefined

  @computedFrom('facilityGroup')
  get roleDescription() {
    if (this.myRole) return ROLE_DESCRIPTIONS[this.myRole]
    if (!this.facilityGroup?.myRole) return ''
    return ROLE_DESCRIPTIONS[this.facilityGroup.myRole]
  }
}
