import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { UserApi } from '@nubix/spica-cloud-backend-client'
import { ContactInvitationWithCompany, User } from '@nubix/spica-cloud-backend-client/models'
import { autoinject } from 'aurelia-dependency-injection'
import { bindable } from 'aurelia-framework'
import styles from './invitation-list.module.scss'

@autoinject()
export class InvitationList extends subscriberMixin() {
  styles = styles

  private currentUser?: User

  @bindable invitations: ContactInvitationWithCompany[]

  matcher(a: ContactInvitationWithCompany, b: ContactInvitationWithCompany) {
    return a.id === b.id
  }

  constructor(private readonly userApi: UserApi) {
    super()
  }

  attached() {
    this.userApi.getCurrentUser().then((u) => (this.currentUser = u))
  }
}
