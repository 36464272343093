import { RenderInstruction, ValidateResult, ValidationRenderer } from 'aurelia-validation'

/**
 * Uses HTML5 features to display aurelia validation results.
 * Works with custom templates such as `_controls/text-field` and `_controls/selector`
 */
export class HtmlValidationRenderer implements ValidationRenderer {
  private static add(element: Element, result: ValidateResult) {
    const input = element.querySelector('input')
    if (!input) return
    if (result.valid) return

    input.setCustomValidity(result.message || 'Bitte überprüfen')
  }

  private static remove(element: Element) {
    const input = element.querySelector('input')
    if (!input) return

    input.setCustomValidity('')
  }

  public render(instruction: RenderInstruction) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const { result, elements } of instruction.unrender) {
      for (const element of elements) {
        HtmlValidationRenderer.remove(element)
      }
    }

    for (const { result, elements } of instruction.render) {
      for (const element of elements) {
        HtmlValidationRenderer.add(element, result)
      }
    }
  }
}
