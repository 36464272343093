import { getLogger } from 'aurelia-logging'
import { NavigationInstruction, Next, PipelineStep, RedirectToRoute } from 'aurelia-router'

import { AuthService } from 'services/auth-service'
import { IRouteConfigSettings } from './routing'

const LOG = getLogger('auth-step')

/**
 * Aurelia history pipeline step that checks if the current user has the permission to access the targeted page.
 * This step incorporates authentication and checks if the user is logged in and if he has the permission to enter
 * the navigated page. Since every page can have permissions as requirement this class is the gate keeper that allows
 * or disalows access to every page in the web app.
 */
export class AuthorizeStep implements PipelineStep {
  private static getRequiredPermission(ni: NavigationInstruction) {
    return (ni.config.settings as IRouteConfigSettings).requiresAuthentication
  }

  constructor(private readonly authService: AuthService) {}

  public async run(instruction: NavigationInstruction, next: Next): Promise<any> {
    const needsAuthorization = instruction
      .getAllInstructions()
      .map((ni) => (ni.config.settings as IRouteConfigSettings).requiresAuthentication)
      .some((it) => !!it)

    if (needsAuthorization && !this.authService.isAuthenticated) {
      LOG.warn('not authorized, redirecting to home screen')

      return next.cancel(
        new RedirectToRoute(
          'home',
          instruction.getWildcardPath().indexOf('logout') >= 0
            ? {}
            : { target: `${instruction.getBaseUrl()}${instruction.getWildcardPath()}` }
        )
      )
    }

    return next()
  }
}
