import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { throwError } from '@nubix/npm-utils/src/cache/utils'
import { Facility, Inspection, InspectionApi, Luminaire } from '@nubix/spica-cloud-backend-client'
import { SseHandler } from '_utils/sseHandler'
import { computedFrom } from 'aurelia-binding'

import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { RoutableComponentActivate, RoutableComponentDeactivate, Router } from 'aurelia-router'
import { ValidationControllerFactory } from 'aurelia-validation'
import * as _ from 'lodash'
import { cloneDeep } from 'lodash'
import { cpLuminaireStatic, cpLuminaireStatus } from 'model/utils'
import { merge, of } from 'rxjs'
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators'
import { AuthService } from 'services/auth-service'
import { FacilityService } from 'services/facility-service'
import { LuminaireService } from 'services/luminaire-service'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { doAfterNavigation } from '../_utils/app-history/utils'
import { findNavigationToRoute, Route } from '../_utils/routing'
import { LuminaireDevice } from '../model/device'
import { getInspectionStatus } from '../model/device-status'
import { isInspectionInFinishedState } from '../model/inspections'
import { InspectionService } from '../services/inspection-service'
import { LocaleService } from '../services/locale-service'

const LOG = getLogger('lumi-edit')

// TODO: multiple type errors in this file

/**
 * A route to display and edit information of a luminaire
 */
@autoinject()
export class LuminaireDetails
  extends subscriberMixin()
  implements RoutableComponentActivate, RoutableComponentDeactivate
{
  public cachedLuminaire?: LuminaireDevice
  public cachedFacility?: Facility
  public cachedInspections?: (Inspection | undefined)[]

  public formRoot: HTMLFormElement

  private updateHandler: SseHandler
  private updateHandlerFac: SseHandler
  private archivedLuminaire: Luminaire

  constructor(
    private readonly router: Router,
    private readonly authServ: AuthService,
    private readonly testApi: InspectionApi,
    private readonly _facilityService: FacilityService,
    private readonly _luminaireService: LuminaireService,
    private readonly locale: LocaleService,
    private readonly validationFactory: ValidationControllerFactory,
    private readonly inspectionService: InspectionService
  ) {
    super()
  }

  public get serialNumber(): string {
    if (!this.cachedLuminaire) return '-'

    const sn = this.cachedLuminaire.serial

    return `${sn.substring(0, 2)}-${sn.substring(2, 4)}-${sn.substring(4, 8)}-${sn.substring(
      8,
      12
    )}`.toUpperCase()
  }

  @computedFrom(
    'cachedLuminaire.name',
    'cachedLuminaire.installationDate',
    'cachedLuminaire.circuit.powerDistributor',
    'cachedLuminaire.circuit.luminaireNumber',
    'cachedLuminaire.circuit.circuitNumber',
    'archivedLuminaire'
  )
  public get dirty(): boolean {
    if (!this.cachedLuminaire || !this.archivedLuminaire) return false

    return (
      this.cachedLuminaire.name !== this.archivedLuminaire.name ||
      this.cachedLuminaire.installationDate !== this.archivedLuminaire.installationDate ||
      this.cachedLuminaire.circuit.powerDistributor !==
        this.archivedLuminaire.circuit.powerDistributor ||
      this.cachedLuminaire.circuit.luminaireNumber !==
        this.archivedLuminaire.circuit.luminaireNumber ||
      this.cachedLuminaire.circuit.circuitNumber !== this.archivedLuminaire.circuit.circuitNumber
    )
  }

  @computedFrom('cachedLuminaire')
  get may() {
    return getPermissionTable(this.cachedLuminaire?.myRole)
  }

  @computedFrom('cachedInspections')
  public get cachedFunctionInspection() {
    return this.cachedInspections?.find((it) => it?.typeOf === 'F')
  }

  @computedFrom('cachedInspections')
  public get cachedDurationInspection() {
    return this.cachedInspections?.find((it) => it?.typeOf === 'D')
  }

  @computedFrom(
    'cachedFunctionInspection',
    'cachedDurationInspection',
    'cachedLuminaire.state.deactivated ',
    'cachedLuminaire.state.powerSupply',
    'cachedLuminaire.state.blocked',
    'mayWrite'
  )
  public get canRunTests() {
    const isLuminaireInTestableState =
      !this.cachedLuminaire?.state.deactivated &&
      !this.cachedLuminaire?.state.blocked &&
      this.cachedLuminaire?.state.powerSupply

    return (
      isInspectionInFinishedState(this.cachedDurationInspection) &&
      isInspectionInFinishedState(this.cachedFunctionInspection) &&
      isLuminaireInTestableState
    )
  }

  @computedFrom('cachedLuminaire')
  public get signalStrength() {
    const lum = this.cachedLuminaire ?? throwError()
    const signal = lum.state.signal
    // 99 means "Not known or not detectable". See SPICA-512
    if (signal === 99) return 0
    else return signal
  }

  @computedFrom('cachedLuminaire')
  public get inspectionStatus() {
    if (!this.cachedLuminaire) return undefined
    return getInspectionStatus(this.cachedLuminaire)
  }

  /**
   * On activation, load the luminaire from the server and display it. If its unavailable, return to the previous route.
   * @param params - contains the imsi of the luminaire to display
   */
  public async activate(params: any) {
    if (params.imsi === undefined) return doAfterNavigation(this.router, this.navigateUp)

    // # load luminaire

    const luminaire$ = this._luminaireService.getEntityByImsi$(params.imsi)

    this.subscribeUntilDeactivated({
      to: luminaire$,
      onNext: (lum) => {
        if (lum === undefined) return doAfterNavigation(this.router, this.navigateUp)

        // If the Luminaire isn't owned by anyone, open the Add-Dialog
        // This should be done earlier in the Lifecycle, so we don't have to load this route first
        if (!lum.companyId) {
          findNavigationToRoute(this.router, 'device-add', {
            imsi: lum.imsi,
            deviceType: 'luminaire'
          })

          return
        }

        this.dirty ? this.updateLumiNonEdit(lum) : this.updateLumiFull(lum)

        if (!this.updateHandler) this.autorefreshLuminaireStatus(lum)
      }
    })

    // # load facility

    const facility$ = luminaire$.pipe(
      distinctUntilChanged((a, b) => a?.location?.facilityId === b?.location?.facilityId), // the facility cache is updated by its own SSE handler. It therefore only needs to be refreshed if the facility is reassigned.
      switchMap((lum) => {
        if (!lum) throw new Error()
        if (lum.location.facilityId === 0) return of(undefined)

        return this._facilityService.facilityEntityCache.get$(lum.location.facilityId)
      }),
      map((it) => {
        if (!it) throw new Error()
        else return it
      })
    )

    this.subscribeUntilDeactivated({
      to: facility$,
      onNext: (it) => {
        this.cachedFacility = it
        if (!this.updateHandlerFac) this.autorefreshDataFacility(it.id)
      }
    })

    // 3. load latest inspections

    const inspections$ = luminaire$.pipe(
      switchMap((lum) => {
        if (!lum) throw new Error()

        return this.inspectionService.getLatestInspectionForEachType(lum.id)
      })
    )

    this.subscribeUntilDeactivated({
      to: inspections$,
      onNext: (it) => (this.cachedInspections = it)
    })

    // wait for the first result
    await merge(luminaire$.pipe(first()), facility$.pipe(first()), inspections$.pipe(first()))
  }

  /**
   * On deactivation, unregister SSE connection with server
   */
  public override deactivate() {
    super.deactivate()

    if (this.updateHandler) {
      this.updateHandler.close()
    }
    if (this.updateHandlerFac) {
      this.updateHandlerFac.close()
    }
  }

  /**
   * Navigate up to the luminaire-list of the parent facility.
   * @param overrideFacilityId - if set, navigate to the facility with the specified id instead.
   */
  public navigateUp(overrideFacilityId?: number) {
    const facId = this.cachedFacility
      ? this.cachedFacility.id
      : overrideFacilityId !== undefined
      ? overrideFacilityId
      : undefined

    const parentRoute: Route = facId
      ? { name: 'device-list', params: { id: facId.toString() } }
      : { name: 'facilities', params: {} }
    this.router.navigateToRoute(parentRoute.name, parentRoute.params)
  }

  /**
   * Validate form and submit to server.
   */
  public async onSave(): Promise<ActionResult> {
    const lum = this.cachedLuminaire ?? throwError()

    // send updated data to backend
    if (!lum) throw new Error('cachedLuminaire is undefined')

    await this._luminaireService.updateEntity(lum)
    // reset dirty state / all temporary changes are lost
    await this.updateLumiFull(lum)
  }

  /**
   * Submit a deletion request for the current luminaire to the server
   */
  public async onDelete() {
    const lumId = this.cachedLuminaire?.id ?? throwError()
    try {
      await this._luminaireService.removeEntity(lumId)
      this.navigateUp()
    } catch (e) {
      throw new Error(this.locale.translate('luminaire.delete.failed'))
    }
  }

  public onCancel() {
    hideAll()
  }

  public async onStartFunctionTest() {
    const lumId = this.cachedLuminaire?.id ?? throwError()
    const requestProgress = this.testApi
      .startFunctionTest({ luminaireId: lumId })
      .finally(() => this.hideMenuLater())

    this.inspectionService.latestInspectionCache.mutate({
      where: (key) => key.luminaireId === lumId,
      requestProgress
    })

    return requestProgress
  }

  public async onStartDurationTest() {
    const lumId = this.cachedLuminaire?.id ?? throwError()

    const requestProgress = this.testApi
      .startDurationTest({ luminaireId: lumId })
      .finally(() => this.hideMenuLater())

    this.inspectionService.latestInspectionCache.mutate({
      where: (key) => key.luminaireId === lumId,
      requestProgress
    })

    return requestProgress
  }

  /**
   * Register a SSE connection with the server and handle status updates
   */
  private autorefreshLuminaireStatus(lumi: Luminaire) {
    LOG.debug('start event tracking')

    const revalidateCache = _.debounce(() => {
      this._luminaireService.luminaireEntityCache.revalidate(lumi.id)
      this._luminaireService.luminaireFacilityCache.invalidate(lumi.location.facilityId)
      this.inspectionService.latestInspectionCache.revalidate({
        luminaireId: lumi.id,
        type: 'D'
      })
      this.inspectionService.latestInspectionCache.revalidate({
        luminaireId: lumi.id,
        type: 'F'
      })
    }, 200)

    const fullRevalidation = async () => {
      // reset dirty state / all temporary changes are lost
      this.updateLumiFull(this.cachedLuminaire)
      revalidateCache()
    }

    const listener4Del = (event: any) => {
      if (event.type === 'deletedluminaire') {
        // revalidate cache since entity was deleted
        revalidateCache()
        // invalidate device list
        this._luminaireService.luminaireFacilityCache.invalidate(lumi.location.facilityId)
        // leave page
        this.navigateUp()
      }
    }

    this.updateHandler = new SseHandler(`/events/luminaire/${lumi.id}`, this.authServ)
    this.updateHandler.addEventListener('deletedluminaire', listener4Del)
    this.updateHandler.addEventListener('statusluminaire', revalidateCache)
    this.updateHandler.addEventListener('newmsgluminaire', revalidateCache)
    this.updateHandler.addEventListener('changeluminaire', revalidateCache)
    this.updateHandler.addEventListener('dataluminaire', fullRevalidation)
    this.updateHandler.onReconnect(fullRevalidation)
    this.updateHandler.connectStream()
  }

  /**
   * Register an SSE connection with the server and handle all updates related to a
   */
  private async autorefreshDataFacility(facilityId: number) {
    const listener4Del = (event: any) => {
      if (event.type === 'deletedfacility') {
        this.navigateUp()
      }
    }
    const revalidateCache = _.debounce(() => {
      this._facilityService.facilityEntityCache.invalidate(facilityId)
    }, 200)

    this.updateHandlerFac = new SseHandler(`/events/facility/${facilityId}`, this.authServ)
    this.updateHandlerFac.addEventListener('deletedfacility', listener4Del)
    this.updateHandlerFac.addEventListener('statusfacility', revalidateCache)
    this.updateHandlerFac.addEventListener('changefms', revalidateCache)
    this.updateHandlerFac.addEventListener('datafacility', revalidateCache)
    this.updateHandlerFac.onReconnect(revalidateCache)
    this.updateHandlerFac.connectStream()
  }

  /**
   * Update all shown information of the lumi with data from backend. Might trigger a visible refresh of the page. Will
   * refresh data in editable input fields too. Will reset dirty state.
   */
  private async updateLumiFull(update?: Luminaire) {
    if (!update) return

    this.cachedLuminaire = { deviceType: 'luminaire', ...cloneDeep(update) }
    // we have to copy it
    this.archivedLuminaire = update
  }

  /**
   * Load the lumi data from the server and update the state and lumi information only. Does not update data that is
   * stored in editable input fields on the page. This is done to prevent that the user edit some information and an
   * update triggered by an event is overriding the change before it could be stored in the DB. By updating the
   * viewmodel this update should not trigger a visible refresh of the page or parts of it.
   */
  private async updateLumiNonEdit(update?: Luminaire) {
    if (!this.cachedLuminaire) return
    if (!update) return

    // state too
    cpLuminaireStatus(this.cachedLuminaire, update)
    // non editable infos
    cpLuminaireStatic(this.cachedLuminaire, update)
  }

  /**
   * hide the menu after a short period
   */
  private hideMenuLater() {
    setTimeout(() => this.onCancel(), 5000)
  }
}
