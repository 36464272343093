import { property$ } from '@nubix/npm-utils/src/aurelia/propertyStream'
import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import {
  errorResult,
  idleResult,
  loadingResult,
  Result,
  successResult,
  toResult
} from '@nubix/npm-utils/src/cache/result'
import {
  Contact,
  ContactApi,
  ContactInvitation,
  ContactInvitationApi,
  CreatedInvitation,
  FacilityGroup,
  User,
  UserApi
} from '@nubix/spica-cloud-backend-client'
import { autoinject, bindable, BindingEngine, computedFrom } from 'aurelia-framework'
import { I18N } from 'aurelia-i18n'
import _ from 'lodash'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ContactRole, getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { ROLE_DESCRIPTIONS } from '../model/permissions'
import { ContactInvitationService } from '../services/contact-invitation-service'
import { ContactService } from '../services/contact-service'
import { showLoadingToast, ToastService } from '../services/toast-service'

import * as styles from './participant-list.module.scss'

@autoinject
export class ParticipantList extends subscriberMixin() {
  readonly styles = styles

  readonly roleOptions: ContactRole[] = ['ADMIN', 'READONLY', 'DEVICE_MANAGEMENT']

  isSameUser(a?: User, b?: User) {
    if (!a || !b) return false
    // the backend passes 0 for user ids. Therefore, compare emails.
    return a.email === b.email
  }

  getDescriptionForRole(role: ContactRole) {
    return ROLE_DESCRIPTIONS[role]
  }

  @bindable facilityGroup: FacilityGroup

  invitationDraft?: CreatedInvitation
  invitations: Result<ContactInvitation[]> = idleResult()
  contacts: Result<Contact[]> = idleResult()
  currentUser: Result<User> = loadingResult()

  @computedFrom('facilityGroup')
  get may() {
    return getPermissionTable(this.facilityGroup.myRole)
  }

  constructor(
    private readonly invitationApi: ContactInvitationApi,
    private readonly contactApi: ContactApi,
    private readonly contactService: ContactService,
    private readonly contactInvitationService: ContactInvitationService,
    private readonly toastService: ToastService,
    private readonly i18n: I18N,
    private readonly bindingEngine: BindingEngine,
    private readonly userApi: UserApi
  ) {
    super()
  }

  public attached() {
    this.userApi
      .getCurrentUser()
      .then((u) => (this.currentUser = successResult(u)))
      .catch((e) => (this.currentUser = errorResult(e)))

    const contacts$ = this.contactService.getContactsWithRemoteUpdates()
    const invitations$ = this.contactInvitationService.getOutgoingInvitationsWithRemoteUpdates()
    const group$: Observable<FacilityGroup> = property$(this.bindingEngine, this, 'facilityGroup')

    const invitationsForCompany = combineLatest(group$, invitations$).pipe(
      map(([group, invitations]) =>
        _.orderBy(
          invitations.filter((i) => i.companyId === group.id),
          (c) => c.email
        )
      ),
      toResult()
    )

    const contactsForCompany = combineLatest(group$, contacts$).pipe(
      map(([group, contacts]) => {
        const orderBy = _.orderBy(
          contacts
            .filter((i) => this.roleOptions.includes(i.role))
            .filter((i) => i.companyId === group.id),
          (c) => c.user.email
        )
        return orderBy
      }),
      toResult()
    )

    this.assignWithLifecycle(this, invitationsForCompany, 'invitations')
    this.subscribeUntilDetached({
      to: contactsForCompany,
      onNext: (v) => {
        this.contacts = v
      }
    })
    // this.assignWithLifecycle(this, contactsForCompany, 'contacts')
  }

  //region Creating Invitations
  public onNewInviteClicked() {
    this.invitationDraft = { companyId: this.facilityGroup.id, email: '', role: 'READONLY' }
  }

  public onNewInvitationCanceled() {
    this.invitationDraft = undefined
  }

  public async onNewInvitationSubmitted() {
    if (!this.invitationDraft) throw new Error()
    const requestProgress = this.invitationApi.createContactInvitation({
      body: this.invitationDraft
    })
    this.contactInvitationService.outgoingInvitationQueryCache.mutate({ requestProgress })
    await requestProgress
    this.invitationDraft = undefined
  }
  //endregion

  //region Managing Invitations
  public changeInvitationRole(invitation: ContactInvitation, role: ContactRole) {
    const requestProgress = this.invitationApi.changeRoleOfContactInvitation({
      id: invitation.id,
      body: { role }
    })
    this.contactInvitationService.outgoingInvitationQueryCache.mutate({ requestProgress })
    showLoadingToast(
      this.toastService,
      this.i18n,
      requestProgress,
      'Rolle wird geändert',
      'Rolle wurde geändert'
    )
  }

  public async revokeInvitation(invitation: ContactInvitation) {
    const requestProgress = this.invitationApi.revokeContactInvitation({ id: invitation.id })
    this.contactInvitationService.outgoingInvitationQueryCache.mutate({ requestProgress })
    await requestProgress
  }
  //endregion

  public async kickContact(contact: Contact) {
    const requestProgress = this.contactApi.kickContact({ id: contact.id })
    this.contactService.contactQueryCache.mutate({ requestProgress })
    await requestProgress
  }

  public changeContactRole(contact: Contact, role: ContactRole) {
    const requestProgress = this.contactApi.changeRoleOfContact({
      contactId: contact.id,
      body: { role }
    })
    this.contactService.contactQueryCache.mutate({ requestProgress })
    showLoadingToast(
      this.toastService,
      this.i18n,
      requestProgress,
      'Rolle wird geändert',
      'Rolle wurde geändert'
    )
  }
}
