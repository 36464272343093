import { computedFrom } from 'aurelia-binding'
import { bindable } from 'aurelia-templating'

/**
 * Subheader as a component. Shows a small icon on the side of the header.
 */
export class Subheader {
  /**
   * Icon that is shown beside the headers text. Bindable so the using page can set it.
   */
  @bindable public image: string
  /**
   * Text of the header. Bindable so the using page can set it.
   */
  @bindable public text: string

  /**
   * Get the path of an image based on it's location in the source directory.
   */
  @computedFrom('image')
  public get resolvedImage() {
    // `../images/` is important, because that's how Webpack knows to replace this path
    return import(`../images/${this.image}`).then((imported) => imported.default)
  }
}
