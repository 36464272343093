export function arrayIndexOf<T>(params: { array: T[]; value: T }): number {
  return params.array.indexOf(params.value)
}

export function arrayContains<T>(params: { array: T[]; value: T }): boolean {
  return arrayIndexOf(params) > -1
}

export function arrayRemoveIdx<T>(params: { array: T[]; idx: number }): void {
  if (params.idx > -1 && params.array.length > params.idx) {
    params.array.splice(params.idx, 1)
  }
}

export function arrayRemoveVal<T>(params: { array: T[]; value: T }): void {
  const idx = arrayIndexOf(params)
  if (idx > -1) {
    arrayRemoveIdx({ array: params.array, idx })
  }
}

export function arrayRemoveLast<T>(params: { array: T[] }): void {
  arrayRemoveIdx({ array: params.array, idx: params.array.length - 1 })
}

export function arrayRemoveFirst<T>(params: { array: T[] }): void {
  arrayRemoveIdx({ array: params.array, idx: 0 })
}

export function arrayAddLast<T>(params: { array: T[]; value: T }): void {
  params.array.push(params.value)
}

export function arrayAdd<T>(params: { array: T[]; afterIdx: number; value: T }): void {
  if (params.afterIdx >= params.array.length) {
    arrayAddLast({ array: params.array, value: params.value })

    return
  }
  if (params.afterIdx < 0) {
    params.array.push.apply([params.value], params.array)
  }
}

export function arraySet<T>(params: { array: T[]; idx: number; value: T }): void {
  if (params.idx >= params.array.length) {
    arrayAddLast({ array: params.array, value: params.value })

    return
  }
  if (params.idx < 0) {
    params.array.push.apply([params.value], params.array)
  }
}
