import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import { findNavigationToRoute } from '../_utils/routing'

/**
 * A route to notify the user about a confirmation email
 */
@autoinject
export class ConfirmEmail {
  public mailTo: string

  constructor(private readonly router: Router) {}

  public activate(params: any) {
    this.mailTo = params.userEmail
  }

  public async home() {
    return findNavigationToRoute(this.router, 'home')
  }
}
