import { bindable, computedFrom } from 'aurelia-framework'
import { memoize } from 'lodash'
import { Device } from 'model/device'
import { getDeviceStatuses } from '../../../model/device-status'

import styles from './device-status-section.module.scss'

const getStatuses = memoize(getDeviceStatuses)

export class DeviceStatusSection {
  styles = styles

  @bindable device?: Device

  @computedFrom('device')
  get status() {
    if (!this.device) return {}
    return getStatuses(this.device)
  }
}
