import { Facility } from '@nubix/spica-cloud-backend-client'
import { autoinject, useView } from 'aurelia-framework'
import { PLATFORM } from 'aurelia-pal'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { FacilityService } from '../services/facility-service'

@autoinject()
@useView(PLATFORM.moduleName('facilities/emergency-block-prompt.html'))
export class EmergencyBlockPrompt {
  facility: Facility
  close: () => void

  constructor(private readonly facilityService: FacilityService) {}

  activate(p: { facility: Facility; close: () => void }) {
    this.facility = p.facility
    this.close = p.close
  }

  async toggleBlocked(): Promise<ActionResult> {
    const newBlockState = !this.facility.state.manualBlocked
    await this.facilityService.setBlocked(this.facility.id, newBlockState)
    this.close()
  }
}
