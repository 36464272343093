import { bindable } from 'aurelia-framework'

export class CircuitLabel {
  @bindable circuit: CircuitLabel
  @bindable readonly = false

  powerDistributorInput: HTMLInputElement | undefined

  focusInput() {
    const i = this.powerDistributorInput
    i?.focus()
    i?.setSelectionRange(0, i?.value.length)
  }
}
