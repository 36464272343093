import { NavigationOptions } from 'aurelia-history'
import { Router } from 'aurelia-router'
import { AppHistory } from './index'

/**
 * Update the url-parameters of the current route.
 * > **CAUTION**: Depending on the `activationStrategy` for this route, the site might reload.
 */
export function updateNavigationParams(
  router: Router,
  params: any,
  moduleId?: string,
  options?: NavigationOptions
) {
  if (!router.currentInstruction) return
  if (router.currentInstruction.queryParams === params) return
  if (router.isNavigating) return
  if (moduleId && router.currentInstruction.config.moduleId !== moduleId) return

  router.navigateToRoute(
    router.currentInstruction.config.name!,
    params,
    options ? options : { trigger: false, replace: true }
  )
}

/**
 * execute function after router execution finished
 * @param execute function that should be executed
 */
export function doAfterNavigation(router: Router, execute: () => void) {
  if (!router.isNavigating) return execute()

  let count = 0
  const timer = setInterval(() => {
    if (!router.isNavigating || count > 7) {
      clearInterval(timer)

      execute()
    }
    count++
  }, 50)
}

export function deleteHistory(router: Router) {
  const history = router.history as AppHistory

  if (history) {
    history.deleteHistory()
  }
}
