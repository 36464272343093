import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import { findNavigationToRoute } from '../_utils/routing'

/**
 * Confirmation page shown after the email confirmation has succeeded
 */
@autoinject
export class ConfirmSuccess {
  constructor(private readonly router: Router) {}

  public async home() {
    return findNavigationToRoute(this.router, 'home')
  }
}
