import { computedFrom, observable } from 'aurelia-binding'
import { cloneDeep, flatMap } from 'lodash'
import { Observable, of, OperatorFunction, Subscription } from 'rxjs'
import { catchError, map, startWith } from 'rxjs/operators'
import { DeepPartial, diffDeep } from './diffDeep'

//region Result
export const resultState = Symbol()

export type SuccessResult<T> = { [resultState]: 'success'; value: T }
export const successResult = <T>(v: T): SuccessResult<T> => ({ [resultState]: 'success', value: v })
export const isSuccess = <T>(r: Result<T>): r is SuccessResult<T> => r[resultState] === 'success'

export type FailureResult = { [resultState]: 'failure'; error: unknown }
export const failureResult = (e: unknown): FailureResult => ({ [resultState]: 'failure', error: e })
export const isFailure = <T>(r: Result<T>): r is FailureResult => r[resultState] === 'failure'

export type LoadingResult = { [resultState]: 'loading' }
export const loadingResult = (): LoadingResult => ({ [resultState]: 'loading' })
export const isLoading = <T>(r: Result<T>): r is LoadingResult => r[resultState] === 'loading'

/**
 * An alternative attempt at a Result type, with state-strings instead of `isLoading` and `isInvalidated`.
 * May eventually replace the Result in `npm-utils`
 **/
export type Result<T> = SuccessResult<T> | FailureResult | LoadingResult

export function toResult<T>(p?: { prependLoading: boolean }): OperatorFunction<T, Result<T>> {
  const prependLoading = p?.prependLoading ?? false

  return (input$) => {
    const base = input$.pipe(
      map(successResult),
      catchError((e) => of(failureResult(e)))
    )

    if (prependLoading) return base
    else return base.pipe(startWith(loadingResult()))
  }
}

//endregion

export class FormCache<T> {
  private _sourceSubscription?: Subscription
  changes: DeepPartial<T>

  constructor(...dependencies: string[]) {
    Object.defineProperty(this, 'changes', {
      get: () => {
        if (!FormCache.isSuccess(this)) return {}
        return diffDeep(this.remoteState as any, this.workingCopy) // TODO fix typing
      }
    })
    const prefixedDeps = flatMap(dependencies, (d) => [`workingCopy.${d}`, `remoteState.${d}`])
    const changes = Object.getOwnPropertyDescriptor(this, 'changes')!
    Object.assign(changes.get as any, { dependencies: prefixedDeps })
  }

  @observable() result: Result<T>

  @observable() workingCopy?: T

  @computedFrom('result')
  get remoteState(): T | undefined {
    return 'value' in this.result ? this.result.value : undefined
  }

  @computedFrom('changes')
  get hasChanges() {
    return !!Object.keys(this.changes).length
  }

  setSource(o: Observable<T>, onError?: (e: unknown) => void) {
    this._sourceSubscription?.unsubscribe()
    this._sourceSubscription = o.subscribe(
      (n) => {
        // For now, updates will simply replace the working copy.
        // Future solutions may use more refined conflict resolution.
        this.result = successResult(n)
        this.workingCopy = cloneDeep(n)
      },
      (e) => {
        onError?.(e)
        this.result = failureResult(e)
        this.workingCopy = undefined
      }
    )
  }

  dispose() {
    this._sourceSubscription?.unsubscribe()
  }

  static isSuccess<T>(
    cache: FormCache<T>
  ): cache is FormCache<T> & { remoteState: T; workingCopy: T } {
    return isSuccess(cache.result)
  }
}
