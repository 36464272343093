import * as Sentry from '@sentry/browser'
import { HttpResponseMessage } from 'aurelia-http-client'
import { reportResponseErr } from 'errorReportingAurelia'
import * as buildInfo from './buildInfo.json'

export function initErrorReporting(
  environment: 'development' | 'production',
  backendBasePath: string
) {
  Sentry.init({
    dsn: 'https://e5f38099b2f548d983c0ee60b9e81698@sentry.nubix.de/3',
    release: `spica-cloud-webapp@${buildInfo.version.full}`,
    tracesSampleRate: 0.001,
    environment,
    enabled: environment !== 'development',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: backendBasePath ? [backendBasePath] : undefined
      })
    ]
  })
}

type OptsAndOrMessage =
  | [opts: Record<any, any>, message: string]
  | [message: string]
  | [opts: Record<any, any>]

function getOptsAndMessage(args: OptsAndOrMessage | []) {
  const opts = typeof args[0] === 'object' ? args[0] : {}
  const message =
    typeof args[0] === 'string' ? args[0] : typeof args[1] === 'string' ? args[1] : undefined

  return [opts, message] as const
}

export const reportErr = (error: unknown, ...optsAndOrMessage: OptsAndOrMessage | []) => {
  const [opts, message] = getOptsAndMessage(optsAndOrMessage)
  console.error(error, ...optsAndOrMessage)
  // aurelia-http-client throws response 🤦 and `Sentry.captureException`
  // does not like non-Error exceptions.
  if (!(error instanceof Error) && error instanceof HttpResponseMessage) {
    reportResponseErr(error, opts, message)
    return
  }
  Sentry.captureException(error, { extra: { ...opts, message } })
}

export const reportWarn = (...optsAndOrMessage: OptsAndOrMessage) => {
  const [opts, message] = getOptsAndMessage(optsAndOrMessage)
  console.warn(...optsAndOrMessage)
  Sentry.captureMessage(message!, { extra: { ...opts } })
}
