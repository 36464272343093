import { computedFrom } from 'aurelia-binding'
import { bindable } from 'aurelia-templating'

/**
 * A view that shows a readonly text.
 */
export class InfoText {
  /**
   * current value that is shown in the view. Bindable so the using page can set it.
   */
  @bindable public value = ''
  /**
   * index of character where
   */
  @bindable public splitright?: number

  @bindable public clazzinfo?: string

  @bindable public separator = ''

  @computedFrom('value')
  public get leftPart(): string {
    if (
      !this.value ||
      !this.splitright ||
      this.splitright < 0 ||
      this.splitright > this.value.length - 1
    ) {
      return ''
    }

    return this.value.substring(0, this.value.length - this.splitright)
  }

  @computedFrom('value')
  public get rightPart(): string {
    if (
      !this.value ||
      !this.splitright ||
      this.splitright < 0 ||
      this.splitright > this.value.length - 1
    ) {
      return this.value
    }

    return this.value.substring(this.value.length - this.splitright, this.value.length)
  }
}
