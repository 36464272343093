import { autoinject, bindable } from 'aurelia-framework'
import tippy, { Instance, Props } from 'tippy.js'

@autoinject
export class Popover {
  private tippy?: Instance
  content: HTMLElement

  /**
   * The element that the popover should attach itself to.
   * If undefined, no tippy is created.
   * Changing the anchor will destroy and create a new tippy
   **/
  @bindable({ changeHandler: 'rebuildTippy' }) anchor?: HTMLElement
  @bindable options: Partial<Props>

  /** see https://atomiks.github.io/tippyjs/v6/methods/#disable */
  @bindable({ changeHandler: 'changeEnabled' }) enabled = true

  attached() {
    this.rebuildTippy()
  }

  setOpen(isOpen: boolean) {
    if (isOpen) this.tippy?.show()
    else this.tippy?.hide()
  }

  detached() {
    // For some reason, sometimes `this.tippy` was an empty array. Therefore, the call to destroy is made optional.
    this.tippy?.destroy?.()
    this.tippy = undefined
  }

  rebuildTippy() {
    this.tippy?.destroy()
    if (!this.anchor) {
      this.tippy === undefined
      return
    }
    this.tippy = tippy(this.anchor, {
      trigger: 'manual',
      content: this.content,
      maxWidth: Infinity,
      theme: 'base-dark',
      animation: false,
      ...this.options
    }) as Instance
    this.changeEnabled()
  }

  changeEnabled() {
    if (this.enabled) this.tippy?.enable()
    else this.tippy?.disable()
  }
}
