import { bindable } from 'aurelia-templating'
import { v4 as uuid } from 'uuid'

/**
 * Input field for text as a component.
 */
export class TextField {
  public fieldRoot: HTMLElement
  public input: HTMLInputElement

  /**
   * generated id of HTML element. Bindable so the using page can set it.
   */
  public id = uuid()
  /**
   * Text label that is shown beside dropdown box. Bindable so the using page can set it.
   */
  @bindable public label: string | undefined
  /**
   * Text that is shown to the the user to show the intented use. Bindable so the using page can set it.
   */
  @bindable public help = ''
  /**
   * current value that is shown in the input field. Bindable so the using page can set it.
   */
  @bindable public value: string
  /**
   * type of the text field. For example text, email or password. Bindable so the using page can set it.
   */
  @bindable public type = 'text'
  /**
   * Name of this text field. Bindable so the using page can set it.
   */
  @bindable public name = ''
  /**
   * true = a text is required in this field, false = the user does not need to enter a value. Bindable so the
   * using page can set it.
   */
  @bindable public required = false
  /**
   * true = the value of the feld can not be changed. Bindable so the using page can set it.
   */
  @bindable public readonly = false
  /**
   * enable or disable autocompletion of entered text. Bindable so the using page can set it.
   */
  @bindable public autocomplete = ''

  @bindable public autofocus = false
  /**
   * function that is called when the user leaves the input box. Can be used to start validation or process the input
   * value. Bindable so the using page can set it.
   */
  @bindable public onblur: () => void
  /**
   * maximum length of entered text. Bindable so the using page can set it.
   */
  @bindable public maxlength = 9999

  @bindable public options: { [k in keyof HTMLInputElement]?: HTMLInputElement[k] } = {}

  public doOnBlur() {
    this.fieldRoot.blur()
  }

  public attached() {
    const input = this.input as any
    const options = this.options as any
    for (const key in this.options) {
      input[key] = options[key]
    }
  }
}
