import { Cps, Fms, Gateway, Luminaire, PairedGateway } from '@nubix/spica-cloud-backend-client'
import { pick } from 'lodash'

export type GatewayDevice = { deviceType: 'gateway' } & Gateway

export type FmsDevice = { deviceType: 'fms' } & Fms
export type CpsDevice = { deviceType: 'cps' } & Cps
export type LuminaireDevice = { deviceType: 'luminaire' } & Luminaire
export type Device = LuminaireDevice | GatewayDevice | FmsDevice | CpsDevice

export type DeviceType = Device['deviceType']

export type DeviceId = {
  deviceType: DeviceType
  id: number
}

export function getDeviceId(dev: Device): DeviceId {
  return pick(dev, ['id', 'deviceType'])
}

export function isSameDeviceId(a: DeviceId, b: DeviceId) {
  return a.id === b.id && a.deviceType === b.deviceType
}

export function getDeviceList(p: {
  luminaires: Luminaire[]
  cpss: Cps[]
  fmss: Fms[]
  gateways: Gateway[]
}): Device[] {
  return [
    ...p.cpss.map((l) => ({ ...l, deviceType: 'cps' } as const)),
    ...p.fmss.map((l) => ({ ...l, deviceType: 'fms' } as const)),
    ...p.gateways.map((l) => ({ ...l, deviceType: 'gateway' } as const)),
    ...p.luminaires.map((l) => ({ ...l, deviceType: 'luminaire' } as const))
  ]
}

//region Connection Type
export function getDeviceType(d: Device) {
  switch (d.deviceType) {
    case 'luminaire':
      return d.article
    case 'gateway':
      return 'Gateway'
    case 'fms':
      return 'Fernmeldesystem'
    case 'cps':
      return 'CPS/LPS System'
  }
}

export type SpicaConnectedDevice = Extract<
  Device,
  { state: { signal: number; connected: boolean; definedOffline?: boolean } }
>

export function isSpicaConnectedDevice(d: Device): d is SpicaConnectedDevice {
  if (['gateway', 'cps'].includes(d.deviceType)) return true
  if (['fms'].includes(d.deviceType)) return false // This is wrong - the Server just doesn't show lastMessage and Signal for FMS (for some reason)

  return !isGatewayConnectedDevice(d)
}

export type GatewayConnectedDevice = LuminaireDevice & { pairedGateway?: PairedGateway }

export function isGatewayConnectedDevice(d: Device): d is GatewayConnectedDevice {
  return d.deviceType === 'luminaire' && d.requiresGateway
}

//endregion
