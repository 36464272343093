import { bindable } from 'aurelia-framework'
import styles from './collapsible-layout.module.scss'

export class CollapsibleLayout {
  styles = styles

  @bindable open: false

  @bindable innerClass = ''
}
