import { autoinject } from 'aurelia-framework'
import { I18N } from 'aurelia-i18n'
import { getLogger } from 'aurelia-logging'

/**
 * This service handles multiple translations of pages in the web app. The user can switch the translation in their
 * browser without interfering with other users translation.
 */
@autoinject
export class LocaleService {
  private static readonly LOG = getLogger('locale')

  public constructor(private readonly i18n: I18N) {}

  public async loadLanguage() {
    // we only support German at the moment
    return this.setLanguage('de').catch(async (error) => {
      LocaleService.LOG.warn('could not load language', error)
    })
  }

  public async setLanguage(language: string) {
    if (!language) return undefined

    LocaleService.LOG.info('refresh language', language)
    return this.i18n.setLocale(language)
  }

  public getLanguage(): string {
    return this.i18n.getLocale()
  }

  /**
   * Get translation for current language.
   * @param key - key to translation record
   * @param options - data that is stored in the translation
   */
  public translate(key: string, options?: any): string {
    return this.i18n.tr(key, options)
  }
}
