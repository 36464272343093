import { autoinject } from 'aurelia-framework'
import { I18N } from 'aurelia-i18n'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { getErrorPresentation } from '../_utils/error-handling/getErrorPresentation'
import { wait } from '../_utils/wait'
import { reportErr } from '../errorReporting'

type Toast = {
  message: string
  variant: 'default' | 'error'
}

@autoinject()
export class ToastService {
  openToasts: Toast[] = []

  showToast(toast: Toast) {
    this.openToasts.push(toast)
    wait(10000).then(() => removeElement(this.openToasts, toast))
  }
}

function removeElement<T>(array: T[], element: T) {
  const index = array.indexOf(element)
  array.splice(index, 1)
}

export function showLoadingToast(
  toastService: ToastService,
  i18n: I18N,
  requestProgress: Promise<ActionResult>,
  loadingMessage: string,
  completionMessage: string
) {
  const loadingToast: Toast = { variant: 'default', message: `${loadingMessage}...` }
  toastService.openToasts.push(loadingToast)

  function showFinalToast(message: string, variant: 'error' | 'default') {
    removeElement(toastService.openToasts, loadingToast)
    const toast = { variant, message }
    toastService.openToasts.push(toast)
    const delay = variant === 'error' ? 10000 : 3000
    wait(delay).then(() => removeElement(toastService.openToasts, toast))
  }

  requestProgress
    .then((r) => {
      if (r && 'hasError' in r)
        showFinalToast(r.errorMessage ?? 'Es ist ein interner Fehler aufgetreten.', 'error')
      else showFinalToast(completionMessage ?? 'Fertig', 'default')
    })
    .catch((e) => {
      reportErr(e)
      const p = getErrorPresentation(e, i18n)
      showFinalToast(p.errorMessage, 'error')
    })
}
