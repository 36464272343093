/** @deprecated can be replaced with `_.compact` */
export function getData<T>(array: (T | undefined)[]): T[] {
  if (!array || array.length < 1) return []

  // we have to trick TypeScript since it thinks that filter will give back (T | undefined)[]
  return [...array.filter((it) => it !== undefined)] as any as T[]
}

/** Intersperses an array with the delimiter.
 *
 * # Example
 * ```
 * [1,2,3] => [1, d, 2, d, 3]
 * ```
 */
export const intersperse = <T>(array: T[], delimiter: T) =>
  array.reduce((acc, next) => [...acc, delimiter, next], [] as T[]).slice(1)
