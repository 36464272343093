import { bindable } from 'aurelia-framework'
import { DeviceStatus } from '../../../model/device-status'

import styles from './device-status-section-entry.module.scss'

export class DeviceStatusSectionEntry {
  styles = styles

  @bindable status: DeviceStatus
}
