import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { throwError } from '@nubix/npm-utils/src/cache/utils'
import { Facility, GatewayApi, GatewayLuminaireConnection } from '@nubix/spica-cloud-backend-client'
import { PLATFORM, autoinject, computedFrom } from 'aurelia-framework'
import { Router } from 'aurelia-router'
import { of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { ModalService } from '../_controls/presentation/layout/modal-service'
import { ActionResult } from '../_controls/presentation/widget/base-button'
import { FormCache, isSuccess, loadingResult, Result, toResult } from '../_utils/FormCache'
import { Route } from '../_utils/routing'
import { Device, DeviceId, getDeviceId, isSameDeviceId, LuminaireDevice } from '../model/device'
import { FacilityService } from '../services/facility-service'
import { GatewayService } from '../services/gateway-service'
import { LuminaireService } from '../services/luminaire-service'
import { GatewayDeletionPrompt } from './gateway-deletion-prompt'
PLATFORM.moduleName('./gateway-deletion-prompt')

import styles from './gateway-details.module.scss'

type GatewayDevice = Extract<Device, { deviceType: 'gateway' }>

export type GatewayDetailsParams = {
  imsi: string
}

@autoinject()
export class GatewayDetails extends subscriberMixin() {
  styles = styles

  gateway = new FormCache<GatewayDevice | undefined>(
    'name',
    'circuit.powerDistributor',
    'circuit.circuitNumber',
    'circuit.luminaireNumber'
  )

  luminaires: Result<GatewayLuminaireConnection[]> = loadingResult()
  facility: Result<Facility | undefined> = loadingResult()

  selectedPairedLuminaires: DeviceId[] = []
  selectedUnpairedLuminaires: DeviceId[] = []

  //region Lifecycle
  constructor(
    private readonly gatewayService: GatewayService,
    private readonly router: Router,
    private readonly gatewayApi: GatewayApi,
    private readonly facilityService: FacilityService,
    private readonly luminaireService: LuminaireService,
    private readonly modalService: ModalService
  ) {
    super()
  }

  async activate(params: GatewayDetailsParams) {
    const gateway$ = this.gatewayService.getGatewayWithRemoteUpdates$(params.imsi)
    const luminaires$ = gateway$.pipe(
      switchMap((g) => {
        if (!g) return of([])
        return this.gatewayService.getLuminairesWithRemoteUpdates$(g.id, params.imsi)
      })
    )
    const facility$ = gateway$.pipe(
      switchMap((g) => {
        if (!g) return of(undefined)
        return this.facilityService.facilityEntityCache.get$(g.location.facilityId)
      })
    )

    this.gateway.setSource(gateway$)
    this.subscribeUntilDeactivated({
      to: luminaires$.pipe(toResult()),
      onNext: (v) => (this.luminaires = v)
    })
    this.subscribeUntilDeactivated({
      to: facility$.pipe(toResult()),
      onNext: (v) => (this.facility = v)
    })

    await facility$.pipe(first()).toPromise()
  }

  override deactivate() {
    super.deactivate()
    this.gateway.dispose()
  }

  //endregion

  @computedFrom('luminaires')
  get pairedLuminaires(): LuminaireDevice[] {
    if (!isSuccess(this.luminaires)) return []
    return this.luminaires.value
      .filter((l) => l.isPaired.value)
      .map((l) => ({ deviceType: 'luminaire', ...l.luminiare }))
  }

  @computedFrom('luminaires')
  get unpairedLuminaires(): LuminaireDevice[] {
    if (!isSuccess(this.luminaires)) return []
    return this.luminaires.value
      .filter((l) => !l.isPaired.value)
      .map((l) => ({ deviceType: 'luminaire', ...l.luminiare }))
  }

  @computedFrom('gateway')
  get may() {
    return getPermissionTable(this.gateway.remoteState?.myRole)
  }

  async submitDetails() {
    if (!FormCache.isSuccess(this.gateway)) return
    const g = this.gateway.workingCopy ?? throwError()
    const requestProgress = this.gatewayApi.updateGateway({ id: g.id, body: g })
    this.gatewayService.gatewayEntityCache.mutate({ requestProgress })
    this.gatewayService.gatewayQueryCache.mutate({ requestProgress })
    await requestProgress
  }

  showDeletionPrompt() {
    this.modalService.showModal({
      viewModel: GatewayDeletionPrompt,
      model: { confirm: () => this.delete() }
    })
  }

  async delete() {
    if (!FormCache.isSuccess(this.gateway)) return
    if (!this.gateway.workingCopy) throw Error()
    const requestProgress = this.gatewayApi.deleteGateway({ id: this.gateway.workingCopy.id })
    this.gatewayService.gatewayQueryCache.mutate({ requestProgress })
    this.gatewayService.gatewayEntityCache.mutate({ requestProgress })
    await requestProgress

    this.navigateUp()
  }

  navigateUp() {
    const facId = isSuccess(this.facility) ? this.facility.value?.id : undefined
    const parentRoute: Route = facId
      ? { name: 'device-list', params: { id: facId.toString() } }
      : { name: 'facilities', params: {} }
    this.router.navigateToRoute(parentRoute.name, parentRoute.params)
  }

  hideTippies() {
    hideAll()
  }

  toggleSelectAllUnpaired() {
    if (this.unpairedLuminaires.length === this.selectedUnpairedLuminaires.length)
      this.selectedUnpairedLuminaires = []
    else this.selectedUnpairedLuminaires = this.unpairedLuminaires.map(getDeviceId)
  }

  toggleSelectAllPaired() {
    if (this.pairedLuminaires.length === this.selectedPairedLuminaires.length)
      this.selectedPairedLuminaires = []
    else this.selectedPairedLuminaires = this.pairedLuminaires.map(getDeviceId)
  }

  sendSearchCommand(): Promise<ActionResult> | ActionResult {
    if (!FormCache.isSuccess(this.gateway) || !this.gateway.workingCopy) return
    this.gatewayService.gatewayLuminaireCache.revalidate(this.gateway.workingCopy.id)

    return Promise.resolve() // Causes a checkmark to appear on the search button
  }

  async pairSelected(): Promise<ActionResult> {
    if (!FormCache.isSuccess(this.gateway) || !this.gateway.workingCopy) return
    const gatewayId = this.gateway.workingCopy.id

    const lumImsis = this.unpairedLuminaires
      .filter((luminaire) =>
        this.selectedUnpairedLuminaires.some((id) => isSameDeviceId(id, getDeviceId(luminaire)))
      )
      .map((l) => l.imsi)
    const requestProgress = this.gatewayApi.setLuminariesPairing({
      imsi: this.gateway.workingCopy.imsi,
      body: { lumImsis, isPaired: true }
    })

    await this.gatewayService.gatewayLuminaireCache.mutate({
      where: (key) => key === gatewayId,
      requestProgress
    })

    await this.luminaireService.luminaireFacilityCache.mutate({ requestProgress })
  }

  async unpairSelected(): Promise<ActionResult> {
    if (!FormCache.isSuccess(this.gateway) || !this.gateway.workingCopy) return
    const gatewayId = this.gateway.workingCopy.id

    const lumImsis = this.pairedLuminaires
      .filter((luminaire) =>
        this.selectedPairedLuminaires.some((id) => isSameDeviceId(id, getDeviceId(luminaire)))
      )
      .map((l) => l.imsi)
    const requestProgress = this.gatewayApi.setLuminariesPairing({
      imsi: this.gateway.workingCopy.imsi,
      body: { lumImsis, isPaired: false }
    })

    await this.gatewayService.gatewayLuminaireCache.mutate({
      where: (key) => key === gatewayId,
      requestProgress
    })

    await this.luminaireService.luminaireFacilityCache.mutate({ requestProgress })
  }

  selectionIcon(selected: unknown[], all: unknown[]) {
    if (selected.length === 0) return 'square'
    if (selected.length === all.length) return 'square-check'
    else return 'square-minus'
  }

  replace() {
    if (!FormCache.isSuccess(this.gateway) || !this.gateway.workingCopy) return
    this.router.navigateToRoute('gateway-replace', { imsi: this.gateway.workingCopy.imsi })
  }
}
