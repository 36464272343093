import { autoinject } from 'aurelia-dependency-injection'
import { Router } from 'aurelia-router'
import { findNavigationToRoute } from '../_utils/routing'

export interface IFailureParams {
  /**
   * Error code to display
   */
  code: string
}

/**
 * Display a failure during the email confirmation process.
 */
@autoinject
export class ConfirmFailure {
  public messageKey: string

  constructor(private readonly router: Router) {}

  /**
   * On activation, display a message based on the type of failure.
   *
   * @param params - see {@link IFailureParams}
   */
  public activate(params: IFailureParams) {
    switch (params.code) {
      case '401': {
        this.messageKey = 'signup.failure-confirm.expired'
        break
      }
      case '405': {
        this.messageKey = 'signup.failure-confirm.internal-error'
        break
      }
      case '407': {
        this.messageKey = 'signup.failure-confirm.internal-error'
        break
      }
      default: {
        this.messageKey = 'signup.failure-confirm.unexpected-error'
      }
    }
  }

  public async home() {
    return findNavigationToRoute(this.router, 'home')
  }
}
