import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Fms, FmsApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { FmsDevice } from '../model/device'
import { FacilityId } from './facility-service'

export type FmsId = Fms['id']
export type FmsImsi = Fms['imsi']

const LOG = getLogger('fms-srvc')

@autoinject
export class FmsService {
  public fmsEntityCache = new RxCache<FmsId, FmsDevice | undefined>({
    fetcher: async (id) => {
      const fms = await this.fmsApi.getFmsById({ id })
      this.fmsEntityImsiCache.prime(fms.imsi, fms.id)

      return { ...fms, deviceType: 'fms' }
    }
  })

  public fmsEntityImsiCache = new RxCache<FmsImsi, FmsId | undefined>({
    fetcher: async (imsi) => {
      const fms = await this.fmsApi.getFmsByImsi({ imsi })
      this.fmsEntityCache.prime(fms.id, { ...fms, deviceType: 'fms' })

      return fms?.id
    }
  })

  public fmsFacilityCache = new RxCache<number, Fms[]>({
    fetcher: async (facilityId) => {
      const fms = await this.fmsApi.getFmsByFacility({ id: facilityId })
      fms.forEach((it) => {
        this.fmsEntityCache.prime(it.id, { ...it, deviceType: 'fms' })
        this.fmsEntityImsiCache.prime(it.imsi, it.id)
      })

      return fms
    }
  })

  constructor(private readonly fmsApi: FmsApi) {}

  public async getEntityByImsi(imsi: FmsImsi): Promise<Fms | undefined> {
    const id = await this.fmsEntityImsiCache.get(imsi)
    if (!id) return undefined

    return this.fmsEntityCache.get(id)
  }

  public async getEntity(id: FmsId): Promise<Fms | undefined> {
    return this.fmsEntityCache.get(id)
  }

  /**
   * Register device at another facility.
   * @param imsi
   *  IMSI of device
   * @param facilityId
   *  ID of new facility
   */
  public async addEntity(imsi: FmsImsi, facilityId: FacilityId) {
    // add on backend
    const requestProgress = this.fmsApi.addFms({ imsi, facilityId })

    this.fmsFacilityCache.mutate({ requestProgress })
    this.fmsEntityCache.mutate({ requestProgress })
    this.fmsEntityImsiCache.mutate({ requestProgress })

    return requestProgress
  }

  public async removeEntity(id: FmsId) {
    const fms = await this.getEntity(id)

    // delete entity in DB but ignore errors since we remove all traces anyway
    await this.fmsApi.deleteFms({ id }).catch(() => undefined)
    this.fmsEntityCache.prime(id, undefined)

    if (fms) {
      this.fmsEntityImsiCache.prime(fms.imsi, undefined)
      if (fms.location.facilityId) {
        this.fmsFacilityCache.invalidate(fms.location.facilityId)
      }
    }
  }

  public async updateEntity(changed: Fms): Promise<Fms> {
    let error = 200
    let msg = undefined
    const result: Response | undefined = await this.fmsApi
      .updateFms({
        id: changed.id,
        body: changed
      })
      .catch(async (e) => {
        error = e.status ?? e.statusCode ?? 407
        msg = e.message

        return undefined
      })

    this.fmsEntityCache.invalidate(changed.id)

    // handle unexpected result of type Response
    const response = result as any
    if (response && (response.status || response.statusCode)) {
      msg = response.statusText
      error = response.status ?? response.statusCode
    }

    if (error && error !== 200 && error !== 204) {
      LOG.warn('error: %d %s during update of fms %d', error, msg, changed.id)
      throw new Error(`${error} ${msg}`)
    }

    return changed
  }
}
