import { getLogger, Logger } from 'aurelia-logging'
import * as process from 'process'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'

const TODO_LOG = getLogger('TODO')

export function todo(message = 'not implemented yet!') {
  TODO_LOG.error(message)
}

/**
 * Regex for valid password. 8 characters, with small and big characters and numbers required
 */
export const passwortRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

/**
 * Flag if the GUI is running from in a productive environment, true = GUI is running in productive mode
 */
export const isProduction = process.env.NODE_ENV === 'production'

export function hasDuplicatesBy<T>(array: T[], mapper: (inp: T) => any) {
  return new Set(array.map(mapper)).size !== array.length
}

export function firstEmission<T>(observable: Observable<T>) {
  return observable.pipe(first()).toPromise()
}

export function logError(LOG: Logger) {
  return (e: Error) => LOG.error(e.stack ?? e.message)
}

/**
 * Use to statically assert that e.g: all cases in a switch statement are exhausted.
 * @param recovery - react if the function is unexpectedly executed. Throws error by default.
 *
 * ```
 * const x: 1 | 2 | 3 = ....
 * if (x === 1) doA()
 * else if (x === 2) doB()
 * else assertUnreachable(x) // won't compile since 3 isn't handled
 * ```
 *
 * It's a #candidateForNubixNpmUtils
 */
export function assertUnreachable<T = never>(
  x: never,
  recovery: () => T = (): never => {
    throw new Error()
  }
) {
  return recovery()
}
