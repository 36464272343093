import { autoinject } from 'aurelia-framework'

export type ConstructorFor<C> = { new (...args: any[]): C }

export type ViewModelProps<VM extends ModalViewModel<any>> = VM extends ModalViewModel<infer T>
  ? T
  : never

export interface ModalViewModel<T> {
  activate?: (model: T & { close: () => void }) => Promise<void> | void
  deactivate?: () => void
}
export interface ModalDefinition<T> {
  viewModel: ConstructorFor<ModalViewModel<T>>
  model: T
  titleBar?: { title: string }
  desiredWidth?: 'default' | 'wide'
}

@autoinject()
export class ModalService {
  openModal?: ModalDefinition<any>

  showModal<T>(m: ModalDefinition<T>) {
    this.openModal = m as ModalDefinition<any>
  }
}
