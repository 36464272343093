import { Inspection } from '@nubix/spica-cloud-backend-client'
import { Luminaire, LumTestType } from '@nubix/spica-cloud-backend-client/models'

export const inspectionTypes: LumTestType[] = ['D', 'F']

export function isInspectionInFinishedState(inspection?: Inspection) {
  return inspection?.result !== 'DUE' && inspection?.result !== 'RUNNING'
}

// Currently unused
export function hasInspectionFailure(lum: Luminaire) {
  return (
    lum.state.lastFunctionTestResult === 'F' ||
    lum.state.lastFunctionTestResult === 'X' ||
    lum.state.lastDurationTestResult === 'F' ||
    lum.state.lastDurationTestResult === 'X'
  )
}
