exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".yP4blQgkOXk-4mJrRYOZa[open]{margin-bottom:24px}.UMPftxZll_4jby6OnLLHt{padding-right:12px;padding-left:8px;margin-left:-8px;display:flex;flex-direction:row;align-items:center;cursor:default;min-height:32px}.UMPftxZll_4jby6OnLLHt:hover{background-color:#ccc}._1lGyvl6NIORGSWYIMVQ9jy{transition-duration:.1s;transition-property:rotate;transition-timing-function:ease-out;rotate:0deg}._1lGyvl6NIORGSWYIMVQ9jy.vnOMjGn_JtYqCieGoBBxF{rotate:90deg}._33BiPbblLr94gp8yYGMnz-{display:flex;align-items:stretch;flex-direction:row;flex-wrap:wrap;gap:12px;justify-content:space-between;-webkit-user-select:none;-moz-user-select:none;user-select:none;list-style:none}._33BiPbblLr94gp8yYGMnz-::-webkit-details-marker,._33BiPbblLr94gp8yYGMnz-::marker{display:none}._1BOA65KWNXCy6UsjKQr1uw{display:flex;flex-direction:row;flex-wrap:wrap;gap:12px}.yP4blQgkOXk-4mJrRYOZa[open] summary{margin-bottom:24px}", ""]);

// Exports
exports.locals = {
	"container": "yP4blQgkOXk-4mJrRYOZa",
	"foldingButton": "UMPftxZll_4jby6OnLLHt",
	"arrow": "_1lGyvl6NIORGSWYIMVQ9jy",
	"open": "vnOMjGn_JtYqCieGoBBxF",
	"summary": "_33BiPbblLr94gp8yYGMnz-",
	"actionContainer": "_1BOA65KWNXCy6UsjKQr1uw"
};