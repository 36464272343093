import { invalidateAllCaches } from '@nubix/npm-utils/src/cache/GlobalControl'
import { AuthStorage, Session, UserApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import * as Config from 'config'
import { reportErr } from '../errorReporting'

const LOG = getLogger('auth-service')

/**
 * Authentication service to handle login and logout of user on Cloud Backend and internal session storage.
 */
@autoinject()
export class AuthService {
  /**
   * getter of a single stored user session.
   * @returns session of user or undefined if no session is stored
   */
  private static get sessionStore(): Session | undefined {
    if (localStorage) {
      const result = localStorage.getItem(Config.tokenName)

      if (result) {
        return JSON.parse(result)
      }
    }

    return undefined
  }
  /**
   * setter of a single stored user session.
   * @param value
   *  session of user or undefined if session should be deleted
   */
  private static set sessionStore(value: Session | undefined) {
    if (value) {
      localStorage.setItem(Config.tokenName, JSON.stringify(value))
    } else {
      localStorage.removeItem(Config.tokenName)
    }
  }

  /**
   * Function extracts the session token from a session.
   * @param session a user session object
   * @returns session token or empty session token if no valid session was provided
   */
  private static getSessionToken(session?: Session) {
    if (session) {
      return {
        access_token: session.key
      }
    } else {
      return {
        access_token: ''
      }
    }
  }

  private _session?: Session

  // As soon as the AuthService is created, we query local storage to
  // see if the login information has been stored. If so, we immediately
  // load it into the session object on the AuthService.
  constructor(private readonly userApi: UserApi, private readonly authStorage: AuthStorage) {
    this.loadedSession = AuthService.sessionStore
  }

  /** Throws if the login is unsuccessful. The possible error conditions are defined by the server and localized in `t('error:login.*')` */
  public async login(username: string, password: string): Promise<void> {
    const session = await this.userApi.loginUser({ body: { username, password } })
    this.storedSession = session
    invalidateAllCaches()
  }

  public logout() {
    try {
      if (this.isAuthenticated) {
        LOG.debug('logout user')
        this.userApi
          .logoutUser()
          .catch((e) => reportErr(e, 'Could not logout at server'))
          .finally(() => invalidateAllCaches())
      }
    } catch (error) {
      reportErr(error, 'Could not logout at server')
    }
    this.storedSession = undefined
  }

  public get isAuthenticated() {
    return this.loadedSession !== undefined
  }

  public get user(): string | undefined {
    const s = this.loadedSession

    if (s === undefined) return undefined
    else return s.username
  }

  public get userId(): number {
    const s = this.loadedSession

    if (s !== undefined) {
      return s.userid
    }

    return -1
  }

  public get sessionToken(): string | undefined {
    const accessToken = this.authStorage.getspica_auth()

    return accessToken ?? undefined
  }

  private get loadedSession() {
    return this._session
  }

  private set loadedSession(value: Session | undefined) {
    this._session = value

    if (value) {
      this.authStorage.setspica_auth(AuthService.getSessionToken(value).access_token)
    } else {
      this.authStorage.removespica_auth()
    }
  }

  private set storedSession(value: Session | undefined) {
    this.loadedSession = value
    AuthService.sessionStore = value
  }
}
