import { Cps, Facility, Fms, Luminaire } from '@nubix/spica-cloud-backend-client'
import * as moment from 'moment'

export function cpLuminaireStatus(target: Luminaire, source: Luminaire) {
  if (!target.lastMessage) {
    target.lastMessage = {
      timestamp: moment().toISOString()
    }
  }
  if (target.lastMessage) {
    target.lastMessage.timestamp = moment(source.lastMessage?.timestamp).toISOString()
    target.lastMessage.typeCode = source.lastMessage?.typeCode
  }
  target.state.deactivated = source.state.deactivated
  target.state.blocked = source.state.blocked
  target.state.signal = source.state.signal
  target.state.brightness = source.state.brightness
  target.state.powerSupply = source.state.powerSupply
  target.state.failure = source.state.failure
  target.state.connected = source.state.connected
  target.state.lastDurationTestResult = source.state.lastDurationTestResult
  target.state.lastFunctionTestResult = source.state.lastFunctionTestResult
}

export function cpLuminaireStatic(target: Luminaire, source: Luminaire) {
  target.companyId = source.companyId
  target.article = source.article
  target.imsi = source.imsi
  target.serial = source.serial
  target.location.facilityId = source.location.facilityId
  target.type = source.type
}

export function cpLuminaireUser(target: Luminaire, source: Luminaire) {
  target.name = source.name
  target.installationDate = source.installationDate
  target.circuit.luminaireNumber = source.circuit.luminaireNumber
  target.circuit.powerDistributor = source.circuit.powerDistributor
  target.circuit.powerDistributor = source.circuit.powerDistributor
}

export function cpLuminaireFull(target: Luminaire, source: Luminaire) {
  cpLuminaireStatic(target, source)
  cpLuminaireUser(target, source)
  cpLuminaireStatus(target, source)
}

export function cpFmsStatus(target: Fms, source: Fms) {
  target.state.deactivated = source.state.deactivated
  target.state.powerSupply = source.state.powerSupply
  target.state.batteryActive = source.state.batteryActive
  target.state.luminaireFailure = source.state.luminaireFailure
  target.state.connectFailure = source.state.connectFailure
  target.blocked = source.blocked
}

export function cpFmsStatic(target: Fms, source: Fms) {
  target.companyId = source.companyId
  target.imsi = source.imsi
  target.ean = source.ean
  target.location.facilityId = source.location.facilityId
}

export function cpFmsUser(target: Fms, source: Fms) {
  target.name = source.name
  target.installationDate = source.installationDate
}

export function cpFmsFull(target: Fms, source: Fms) {
  cpFmsStatic(target, source)
  cpFmsUser(target, source)
  cpFmsStatus(target, source)
}

export function cpCpsStatus(target: Cps, source: Cps) {
  if (!target.lastMessage) {
    target.lastMessage = {
      timestamp: moment().toISOString()
    }
  }
  if (target.lastMessage) {
    target.lastMessage.timestamp = moment(source.lastMessage?.timestamp).toISOString()
    target.lastMessage.typeCode = source.lastMessage?.typeCode
  }
  target.state.deactivated = source.state.deactivated
  target.state.operation = source.state.operation
  target.state.batteryActive = source.state.batteryActive
  target.state.luminaireFailure = source.state.luminaireFailure
  target.state.malfunction = source.state.malfunction
  target.state.connected = source.state.connected
  target.state.signal = source.state.signal
}

export function cpCpsStatic(target: Cps, source: Cps) {
  target.companyId = source.companyId
  target.imsi = source.imsi
  target.ean = source.ean
  target.location.facilityId = source.location.facilityId
}

export function cpCpsUser(target: Cps, source: Cps) {
  target.name = source.name
  target.installationDate = source.installationDate
  target.config.enableBatteryActive = source.config.enableBatteryActive
  target.config.enableLuminaireFailure = source.config.enableLuminaireFailure
  target.config.enableMalfunction = source.config.enableMalfunction
  target.config.enableOperation = source.config.enableOperation
}

export function cpCpsFull(target: Cps, source: Cps) {
  cpCpsStatic(target, source)
  cpCpsUser(target, source)
  cpCpsStatus(target, source)
}

export function cpFacilityStatus(target: Facility, source: Facility) {
  target.state.deactivated = source.state.deactivated
  target.state.emergencyBlocked = source.state.emergencyBlocked
  target.state.emergency = source.state.emergency
  target.state.manualBlocked = source.state.manualBlocked
  target.state.failure = source.state.failure
  target.state.connectionFailure = source.state.connectionFailure
  target.state.isBlockedByFms = source.state.isBlockedByFms
}

export function cpFacilityStatic(target: Facility, source: Facility) {
  target.companyId = source.companyId
  target.fmsId = source.fmsId
}

export function cpFacilityUser(target: Facility, source: Facility) {
  target.name = source.name
  target.address.street = source.address.street
  target.address.streetNumber = source.address.streetNumber
  target.address.zipCode = source.address.zipCode
  target.address.city = source.address.city
  target.address.country = source.address.country
  target.manager.firstName = source.manager.firstName
  target.manager.lastName = source.manager.lastName
  target.manager.phone = source.manager.phone
  target.manager.email = source.manager.email
  target.maps.floorPlanFile = source.maps.floorPlanFile
}

export function cpFacilityFull(target: Facility, source: Facility) {
  cpFacilityStatic(target, source)
  cpFacilityUser(target, source)
  cpFacilityStatus(target, source)
}
