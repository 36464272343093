import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Inspection } from '@nubix/spica-cloud-backend-client'
import { InspectionApi } from '@nubix/spica-cloud-backend-client/index'
import { LumTestType } from '@nubix/spica-cloud-backend-client/models'
import { autoinject } from 'aurelia-dependency-injection'
import { asyncMap } from '../_utils/rxjs'
import { inspectionTypes } from '../model/inspections'

type LatestInspectionQuery = { luminaireId: number; type: LumTestType }

@autoinject()
export class InspectionService {
  constructor(private readonly inspectionApi: InspectionApi) {}

  public latestInspectionCache = new RxCache<LatestInspectionQuery, Inspection | undefined>({
    fetcher: async (params) => {
      const result = (await this.inspectionApi.getLatestInspection(params)) as Inspection | null
      if (!result) return undefined // the client can't handle 204. See https://github.com/OpenAPITools/openapi-generator/issues/7720

      return result
    }
  })

  public getLatestInspectionForEachType(luminaireId: number) {
    return asyncMap(inspectionTypes, (type) =>
      this.latestInspectionCache.get$({ luminaireId, type })
    )
  }
}
