import { bindable } from 'aurelia-templating'

import styles from './subheader-layout.module.scss'

export class SubheaderLayout {
  styles = styles

  @bindable() innerClass = ''
  @bindable() collapsible = false
  @bindable() collapsed = false
  @bindable() indentContent = true

  toggleCollapse() {
    this.collapsed = !this.collapsed
  }
}
