import { HttpResponseMessage } from 'aurelia-http-client'
import { I18N } from 'aurelia-i18n'

/** Based on: https://www.rfc-editor.org/rfc/rfc7807 */
export type ErrorResponse = {
  /** A URI reference [RFC3986] that identifies the problem type. */
  type: `fisc-error:${string}`

  status: number

  /**
   * A short, human-readable summary of the problem type.
   * It SHOULD NOT change from occurrence to occurrence of the problem
   */
  title?: string

  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: any

  /** A URI reference that identifies the specific occurrence of the problem. */
  instance?: string
}

export function isErrorResponse(e: unknown): e is ErrorResponse {
  const type = (e as any).type
  if (typeof type !== 'string') return false
  return type.startsWith('fisc-error:')
}

export function getErrorResponseType(e: ErrorResponse) {
  return e.type.substr(11)
}

export function handleErrorResponse(e: unknown, i18n: I18N): { errorMessage: string } | undefined {
  if (!(e instanceof HttpResponseMessage)) return undefined

  let errorResponse = undefined
  try {
    errorResponse = JSON.parse(e.response)
  } catch (e) {
    return undefined
  }
  if (!isErrorResponse(errorResponse)) return undefined

  const key = getErrorResponseType(errorResponse)
  const errorMessage = i18n.tr(`error:${key}`)
  return { errorMessage }
}
