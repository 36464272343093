import { bindable, computedFrom } from 'aurelia-framework'
import { PermissionTableEntry } from 'spica-cloud-shared/lib/model/permissions'
import * as styles from './dropdown-menu-item.module.scss'

export class DropdownMenuItem {
  styles = styles
  @bindable permission: PermissionTableEntry | undefined
  @bindable disabled: false | string | true
  @bindable onClick: (() => void) | undefined

  @computedFrom('disabled', 'permission')
  get isDisabled() {
    if (this.disabled) return this.disabled
    if (this.permission && !this.permission.hasPermission) return true
    return false
  }

  onContainerClick() {
    if (this.isDisabled) return
    this.onClick?.()
  }
}
