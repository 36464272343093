import { Observable, OperatorFunction, Subscription, TeardownLogic } from 'rxjs'

export function doWhileObserved<T>(action: () => TeardownLogic): OperatorFunction<T, T> {
  return (upstream) =>
    new Observable((subscriber) => {
      const sub = new Subscription()
      sub.add(upstream.subscribe(subscriber))
      sub.add(action())

      return () => sub.unsubscribe()
    })
}
