import { autoinject, computedFrom } from 'aurelia-framework'
import { bindable } from 'aurelia-templating'
import { getIconForDeviceType } from 'model/device-icon'
import { AureliaComponent } from '../../_utils/AureliaComponent'
import { hasDefined } from '../../_utils/objectUtils'
import { isCircuitEmpty } from '../../model/circuitLabel'
import { Device, getDeviceType } from '../../model/device'
import { getDeviceStatuses } from '../../model/device-status'

import styles from './device-card.module.scss'

@autoinject()
export class DeviceCard extends AureliaComponent {
  styles = styles

  constructor(element: Element) {
    super(element)
  }

  @bindable() device: Device

  @bindable() isSelected = false

  //region Callbacks

  @bindable() onClick: () => void

  @bindable() onSecondaryClick: () => void

  //endregion

  @computedFrom('device')
  get shouldShowCircuit() {
    if (!hasDefined(this.device, 'circuit')) return false
    return !isCircuitEmpty(this.device.circuit)
  }

  @computedFrom('device')
  get deviceType() {
    return getDeviceType(this.device)
  }

  @computedFrom('device')
  get deviceIcon() {
    return getIconForDeviceType(this.device)
  }

  @computedFrom('device')
  get status() {
    return getDeviceStatuses(this.device)
  }
}
