import { RxCache } from '@nubix/npm-utils/src/cache/RxCache'
import { Luminaire, LuminaireApi } from '@nubix/spica-cloud-backend-client'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { LuminaireDevice } from 'model/device'
import { from, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { FacilityId } from './facility-service'

export type LuminaireId = Luminaire['id']
export type LuminaireImsi = Luminaire['imsi']

const LOG = getLogger('lumi-srvc')

@autoinject
export class LuminaireService {
  public luminaireEntityCache = new RxCache<LuminaireId, LuminaireDevice | undefined>({
    fetcher: async (id) => {
      const luminaire = await this.luminaireApi.getLuminaireById({ id })
      this.luminaireEntityImsiCache.prime(luminaire.imsi, luminaire.id)

      return { ...luminaire, deviceType: 'luminaire' }
    }
  })

  public luminaireEntityImsiCache = new RxCache<LuminaireImsi, LuminaireId | undefined>({
    fetcher: async (imsi) => {
      const luminaire = await this.luminaireApi.getLuminaireByImsi({ imsi })
      this.luminaireEntityCache.prime(luminaire.id, { ...luminaire, deviceType: 'luminaire' })

      return luminaire?.id
    }
  })

  public luminaireFacilityCache = new RxCache<number, LuminaireId[]>({
    fetcher: async (facilityId) => {
      const luminaires = await this.luminaireApi.getLuminairesByFacility({ id: facilityId })
      luminaires.forEach((it) => {
        this.luminaireEntityCache.prime(it.id, { ...it, deviceType: 'luminaire' })
        this.luminaireEntityImsiCache.prime(it.imsi, it.id)
      })

      return luminaires.map((it) => it.id)
    }
  })

  constructor(private readonly luminaireApi: LuminaireApi) {}

  public async getEntities(ids: LuminaireId[]): Promise<(Luminaire | undefined)[]> {
    return await Promise.all(ids.map((id) => this.luminaireEntityCache.get(id)))
  }

  public async getEntity(id: LuminaireId): Promise<Luminaire | undefined> {
    return this.luminaireEntityCache.get(id)
  }

  public async getEntityByImsi(imsi: LuminaireImsi): Promise<Luminaire | undefined> {
    const id = await this.luminaireEntityImsiCache.get(imsi)
    if (!id) return undefined

    return this.luminaireEntityCache.get(id)
  }

  public getEntityByImsi$(imsi: LuminaireImsi): Observable<LuminaireDevice | undefined> {
    const id$ = from(this.luminaireEntityImsiCache.get(imsi))

    return id$.pipe(
      switchMap((id) => {
        if (!id) return of(undefined)

        return this.luminaireEntityCache.get$(id)
      })
    )
  }

  /**
   * Register device at another facility.
   * @param imsi
   *  IMSI of device
   * @param facilityId
   *  ID of new facility
   */
  public async addEntity(imsi: LuminaireImsi, facilityId: FacilityId) {
    // add on backend
    const requestProgress = this.luminaireApi.addLuminaire({ imsi, facilityId })

    this.luminaireFacilityCache.mutate({ requestProgress })
    this.luminaireEntityCache.mutate({ requestProgress })
    this.luminaireEntityImsiCache.mutate({ requestProgress })

    return requestProgress
  }

  public async removeEntity(id: LuminaireId) {
    const lumi = await this.getEntity(id)

    // delete entity in DB but ignore errors since we remove all traces anyway
    await this.luminaireApi.deleteLuminaire({ id }).catch(() => undefined)
    this.luminaireEntityCache.prime(id, undefined)

    if (lumi) {
      this.luminaireEntityImsiCache.prime(lumi.imsi, undefined)
      if (lumi.location.facilityId) {
        this.luminaireFacilityCache.invalidate(lumi.location.facilityId)
      }
    }
  }

  public async updateEntity(changed: Luminaire): Promise<Luminaire> {
    let error = 200
    let msg = undefined
    const result: Response | undefined = await this.luminaireApi
      .updateLuminaire({ id: changed.id, body: changed })
      .catch(async (e) => {
        error = e.status ?? e.statusCode ?? 407
        msg = e.message

        return undefined
      })

    this.luminaireEntityCache.invalidate(changed.id)

    // handle unexpected result of type Response
    const response = result as any
    if (response && (response.status || response.statusCode)) {
      msg = response.statusText
      error = response.status ?? response.statusCode
    }

    if (error && error !== 200 && error !== 204) {
      LOG.warn('error: %d %s during update of luminaire %d', error, msg, changed.id)
      throw new Error(`${error} ${msg}`)
    }

    return changed
  }
}
