import { LogManager } from 'aurelia-framework'
import { RenderInstruction, ValidateResult } from 'aurelia-validation'

const LOG = LogManager.getLogger('form-renderer')

/**
 * Renderer of form data that can be used to validate input data in HTML forms. Used to add validation of input data
 * with Aurelia.
 */
export class CloudFormRenderer {
  public render(instruction: RenderInstruction) {
    LOG.debug('render', instruction)

    for (const { result, elements } of instruction.unrender) {
      for (const element of elements) {
        this.remove(element, result)
      }
    }

    for (const { result, elements } of instruction.render) {
      for (const element of elements) {
        this.add(element, result)
      }
    }
  }

  private add(element: Element, result: ValidateResult) {
    if (!result.valid) {
      const errorMarker = document.createElement('span')
      errorMarker.id = `error-marker-${result.id}`
      errorMarker.className = 'error-marker'

      if (element.parentElement !== null) {
        element.parentElement.insertBefore(errorMarker, element)
      }
    }

    const formGroup = element.closest('.group')
    if (!formGroup) {
      return
    }

    if (!result.valid) {
      // add the has-error class to the enclosing form-group div
      formGroup.classList.add('has-error')

      // add help-block
      const flash = document.createElement('div')
      flash.className = 'group-footer validation-message flash-error'
      flash.id = `validation-message-${result.id}`

      const message = document.createElement('span')
      message.textContent = result.message

      flash.appendChild(message)
      formGroup.appendChild(flash)
    }
  }

  private remove(element: Element, result: ValidateResult) {
    if (element.parentElement !== null) {
      const marker = element.parentElement.querySelector(`#error-marker-${result.id}`)
      if (marker) {
        element.parentElement.removeChild(marker)
      }
    }

    const formGroup = element.closest('.group')
    if (!formGroup) {
      return
    }

    // remove help-block
    const message = formGroup.querySelector(`#validation-message-${result.id}`)
    if (message) {
      formGroup.removeChild(message)

      // remove the has-error class from the enclosing form-group div
      if (formGroup.querySelectorAll('.help-block .validation-message').length === 0) {
        formGroup.classList.remove('has-error')
      }
    }
  }
}
