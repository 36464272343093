import { flow } from 'lodash/fp'

/**
 * Why using chain is a mistake: https://medium.com/bootstart/why-using-chain-is-a-mistake-9bc1f80d51ba
 *
 * How to make `flow` bearable with Typescript: https://stackoverflow.com/questions/56699850/lodash-flow-and-typescript
 *
 * ```ts
 * const x = [1, 2, 3, 4]
 *
 * const y = chainFlow(
 *   x,
 *   filter((x) => x > 3),
 *   map((x) => x + 'abc')
 * )
 * ```
 */

export function chainFlow<T, R1, R2, R3, R4, R5>(
  values: T,
  f1: (t: T) => R1,
  f2: (t: R1) => R2,
  f3: (t: R2) => R3,
  f4: (t: R3) => R4,
  f5: (t: R4) => R5
): R5
export function chainFlow<T, R1, R2, R3, R4>(
  values: T,
  f1: (t: T) => R1,
  f2: (t: R1) => R2,
  f3: (t: R2) => R3,
  f4: (t: R3) => R4
): R4
export function chainFlow<T, R1, R2, R3>(
  values: T,
  f1: (t: T) => R1,
  f2: (t: R1) => R2,
  f3: (t: R2) => R3
): R3
export function chainFlow<T, R1, R2>(values: T, f1: (t: T) => R1, f2: (t: R1) => R2): R2
export function chainFlow<T, R1>(values: T, f1: (t: T) => R1): R1
export function chainFlow<T>(values: T, ...funcs: ((...args: any[]) => any)[]): any {
  return flow(funcs)(values)
}
