import { bindable, computedFrom } from 'aurelia-framework'
import { PermissionTableEntry } from 'spica-cloud-shared/lib/model/permissions'
import styles from './base-select.module.scss'
import clickableStyles from './clickable-layouts.module.scss'
import variants from './clickable-variants.module.scss'

export class BaseSelect {
  styles = styles

  @bindable layout: 'toolbar' | 'default' = 'default'
  @bindable variant: keyof typeof variants = 'secondary'
  @bindable options: string[]
  @bindable isOptionDisabled: (p: { option: string }) => boolean = () => false
  @bindable textForOption: (p: { option: string }) => void
  @bindable disabled = false
  @bindable permission: PermissionTableEntry | undefined

  @computedFrom('disabled', 'permission')
  get isDisabled() {
    if (this.disabled) return true
    if (this.permission && !this.permission.hasPermission) return true
    return false
  }

  @bindable({ changeHandle: 'valueChanged' }) value: string
  @bindable onValueChanged?: (p: { value: string }) => void

  get selectClass() {
    const layout = clickableStyles[this.layout]
    let variant = variants[this.variant]
    if (this.isDisabled) variant = variants.disabled
    return `${styles.select} ${layout} ${variant}`
  }

  valueChanged(newValue: string, oldValue: string) {
    if (!oldValue) return
    this.onValueChanged?.({ value: this.value })
  }
}
