exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1DgGOCRIWFyFs9G1dw8bF9{display:flex;flex-direction:column;align-items:center;justify-items:center}._2199RpapIG-tw80-C9TWC8{display:grid;grid-auto-flow:row;margin-top:12px;margin-bottom:12px;max-width:40em}._1mTUrL6RHUdtIzGzE00xQj{margin-bottom:8px}.a0Ey70dr8TZuVn1S5TrG3{margin-bottom:12px}._119w5QlXEL5qByEHfFEy2n{margin-top:48px}.n61VEAgf2Nq518SQM41J0{display:flex;margin-top:16px}", ""]);

// Exports
exports.locals = {
	"outerContainer": "_1DgGOCRIWFyFs9G1dw8bF9",
	"innerContainer": "_2199RpapIG-tw80-C9TWC8",
	"title": "_1mTUrL6RHUdtIzGzE00xQj",
	"icon": "a0Ey70dr8TZuVn1S5TrG3",
	"invitationList": "_119w5QlXEL5qByEHfFEy2n",
	"buttonContainer": "n61VEAgf2Nq518SQM41J0"
};