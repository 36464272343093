import { subscriberMixin } from '@nubix/npm-utils/src/aurelia/subscriberMixin'
import { Facility } from '@nubix/spica-cloud-backend-client'
import { bindingMode, computedFrom } from 'aurelia-binding'
import { autoinject } from 'aurelia-dependency-injection'
import { getLogger } from 'aurelia-logging'
import { Router } from 'aurelia-router'
import { bindable } from 'aurelia-templating'
import { getInspectionDownloadUrl } from 'main'
import * as moment from 'moment'
import { AuthService } from 'services/auth-service'
import { getPermissionTable } from 'spica-cloud-shared/lib/model/permissions'
import { hideAll } from 'tippy.js'
import { ModalService } from '../_controls/presentation/layout/modal-service'
import { reportErr } from '../errorReporting'
import { FacilityService } from '../services/facility-service'
import { LocaleService } from '../services/locale-service'
import { EmergencyBlockPrompt } from './emergency-block-prompt'
import { DeletionPrompt } from './facility-list/deletion-prompt'

const LOG = getLogger('facility-action-bar')

/**
 * A section containing actions for a single facility.
 */
@autoinject()
export class FacilityActionBar extends subscriberMixin() {
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public facility: Facility

  @bindable public showeditbutton = false

  @bindable public editbackoption?: string

  constructor(
    private readonly router: Router,
    private readonly authServIn: AuthService,
    private readonly facilityService: FacilityService,
    private readonly locale: LocaleService,
    private readonly modalService: ModalService
  ) {
    super()
  }

  @computedFrom('facility')
  get may() {
    return getPermissionTable(this.facility.myRole)
  }

  @computedFrom('facility')
  get details() {
    return this.facility
  }

  /**
   * Request a inspection book for the current facility from the server
   */
  public async onPruefbuchExportClicked() {
    hideAll()

    // hacked download link that works with IOS Chrome and Android app
    const link = document.createElement('a')

    link.href = getInspectionDownloadUrl(
      this.facility.id,
      this.getExportFileName(),
      this.authServIn.sessionToken
    )
    link.download = this.getExportFileName()
    link.target = '_blank'
    link.click()
  }

  /**
   * Activate or deactivate the facility depending on it's current state
   */
  public toggleDeactivate() {
    hideAll()

    if (this.facility) {
      const newState = !this.facility.state.deactivated
      this.facilityService
        .setActive(this.facility.id, newState)
        .then(() => {
          this.facility.state.deactivated = newState
        })
        .catch((e) => reportErr(e))
    }
  }

  /**
   * Send a deletion request to the server for the current facility
   */
  public openDeleteModal() {
    this.modalService.showModal({
      viewModel: DeletionPrompt,
      model: { facility: this.facility }
    })
  }

  /**
   * Navigate to the edit-route for the current facility
   */
  public async onEditClicked() {
    await this.router.navigate(
      `/app/facilities/facility-edit?facilityId=${this.facility.id}&back=${this.editbackoption}`
    )
  }

  public openBlockingModal() {
    this.modalService.showModal({
      viewModel: EmergencyBlockPrompt,
      model: { facility: this.facility }
    })
  }

  /**
   * close all open tooltips
   */
  public onCancel() {
    hideAll()
  }

  private getExportFileName(): string {
    return `${moment().format('DD.MM.YYYY')}_${this.facility.name}.pdf`
  }
}
