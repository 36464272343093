import { CircuitLabel } from '@nubix/spica-cloud-backend-client'

export function matchCircuitLabel(testPattern: CircuitLabel, toTest: CircuitLabel) {
  for (const k of ['powerDistributor', 'circuitNumber', 'luminaireNumber'] as const) {
    if (!testPattern[k]) continue
    const doesMatch = toTest[k].toLowerCase().includes(testPattern[k].toLowerCase())
    if (!doesMatch) return false
  }
  return true
}

type ComparisonResult = -1 | 0 | 1

export function compareCircuitLabel(a: CircuitLabel, b: CircuitLabel): ComparisonResult {
  for (const k of ['powerDistributor', 'circuitNumber', 'luminaireNumber'] as const) {
    if (a[k] && !b[k]) return 1
    if (!a[k] && b[k]) return -1
    if (a[k] < b[k]) return 1
    if (a[k] > b[k]) return -1
  }
  return 0
}

export function isCircuitEmpty(circuit: CircuitLabel) {
  return !circuit.circuitNumber && !circuit.luminaireNumber && !circuit.powerDistributor
}

export const EMPTY_CIRCUIT_LABEL: CircuitLabel = {
  circuitNumber: '',
  luminaireNumber: '',
  powerDistributor: ''
}
