import { AppHistory } from '_utils/app-history'

import { AuthorizeStep } from '_utils/auth-step'
import { findNavigationToRoute, IExtRouteConfig } from '_utils/routing'
import { inject } from 'aurelia-dependency-injection'
import { EventAggregator } from 'aurelia-event-aggregator'
import { getLogger } from 'aurelia-logging'
import { PLATFORM } from 'aurelia-pal'

import {
  AppRouter,
  NavigationInstruction,
  Next,
  PipelineResult,
  RouteConfig,
  Router,
  RouterConfiguration
} from 'aurelia-router'
import { AuthService } from 'services/auth-service'
import { hideAll } from 'tippy.js'

export const HOME_ROUTE = 'home'
export const APP_HOME = 'facility-list'

/**
 * Starting index page of the Spica web app. It configures all default routes and the starting page.
 */
@inject(AuthService, EventAggregator)
export class Index {
  private static readonly LOG = getLogger('root-router')

  public router: Router

  constructor(
    private readonly authService: AuthService,
    private readonly eventAggregator: EventAggregator
  ) {}

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.addAuthorizeStep(new AuthorizeStep(this.authService))
    // observe router history
    const appHistory = router.history as AppHistory
    const appRouter = router as AppRouter
    appHistory.setObservedRouter(appRouter)
    appHistory.setHome(APP_HOME)

    config.addPreActivateStep(appHistory)

    // Scroll to top if new page
    config.addPostRenderStep({
      async run(navigationInstruction: NavigationInstruction, next: Next) {
        if (navigationInstruction.router.isNavigatingNew) {
          window.scroll(0, 0)
        }

        return next()
      }
    })

    // Close all open popups
    config.addPostRenderStep({
      async run(navigationInstruction: NavigationInstruction, next: Next) {
        hideAll()

        return next()
      }
    })

    // Default routes of Cloud starting page
    const routes: IExtRouteConfig[] = [
      // starting page
      { route: '', redirect: 'home' },
      {
        route: 'home',
        name: HOME_ROUTE,
        moduleId: PLATFORM.moduleName('home'),
        nav: true,
        title: 'Fischer Service Cloud',
        settings: {
          b: false,
          d: true
        }
      },
      // logged in users only, customer / member area
      {
        route: 'app',
        name: 'app',
        moduleId: PLATFORM.moduleName('app'),
        nav: true,
        title: 'Home',
        settings: {
          b: false,
          requiresAuthentication: true
        }
      },
      // new user registration
      {
        route: 'signup',
        name: 'signup',
        moduleId: PLATFORM.moduleName('signup/index'),
        title: 'Registrierung'
      },
      // confirm email send page
      {
        route: 'confirm-email-home',
        name: 'confirm-email-home',
        moduleId: PLATFORM.moduleName('signup/confirm-email'),
        title: 'Bestätigungs-Email versendet',
        settings: {
          b: true,
          d: true
        }
      },
      // error route
      {
        route: 'error',
        name: 'error',
        moduleId: PLATFORM.moduleName('error'),
        title: 'Es ist ein Fehler aufgetreten',
        settings: {
          b: false
        }
      },
      { route: 'logout', name: 'logout', redirect: 'home' }
    ]
    config.map(routes)
    config.fallbackRoute('home')
    config.mapUnknownRoutes((_instruction: NavigationInstruction): RouteConfig => {
      return { route: 'logout', name: 'logout', redirect: '#/' }
    })

    this.eventAggregator.subscribe(
      'router:navigation:error',
      async (message: INavigationMessage) => {
        return this.handleAuthErrors(message)
      }
    )
    this.eventAggregator.subscribe(
      'router:navigation:canceled',
      async (message: INavigationMessage) => {
        return this.handleAuthErrors(message)
      }
    )

    this.router = router
  }

  private async handleAuthErrors(message: INavigationMessage) {
    const instruction = message.instruction
    const config: IExtRouteConfig = instruction.config

    const result = message.result

    const response: any = result.output

    if (response.status === 401 || response.statusCode === 401) {
      if (config && config.settings && config.settings.requiresAuthentication) {
        Index.LOG.info('required permission:', config.settings.requiresAuthentication)
      }

      Index.LOG.warn('authentication error:', response.statusText)

      this.authService.logout()
      await findNavigationToRoute(this.router, HOME_ROUTE)
    }
  }
}

interface INavigationMessage {
  instruction: NavigationInstruction
  result: PipelineResult
}
