import * as mimeTypes from '../../static/mime/types.json'

const MIME_BY_EXTENSION = mimeDefine()

function mimeDefine() {
  const extensions = new Map<string, string>()
  // strangely the real collection is stored within a sub member 'default'
  const collection = (mimeTypes as any)['default']
  for (const type of Object.keys(collection)) {
    const ext = (collection as any)[type]
    if (ext && ext.length > 0) {
      for (const ex of ext) {
        extensions.set(ex, type)
      }
    }
  }

  return extensions
}

export function mimeLookup(extension: string): string {
  return MIME_BY_EXTENSION.get(extension) ?? MIME_DEFAULT_TYPE ?? ''
}

export function mimeByExtension(path: string): string {
  const ext = path.replace(/^.*[./\\]/, '').toLowerCase()

  return mimeLookup(ext)
}

// Default type
const MIME_DEFAULT_TYPE = mimeLookup('bin')
