import { autoinject } from 'aurelia-framework'
import { bindable } from 'aurelia-templating'
import { formatWeekday, Weekday, WeekdaySelection } from '../_utils/timeUtils'

const defaultWeekdaySelection: WeekdaySelection = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true
}

@autoinject()
export class WeekdayPicker {
  @bindable name: string

  @bindable value: WeekdaySelection = defaultWeekdaySelection

  public id(appendix: string) {
    return `${name}-${appendix}`
  }

  public label(day: Weekday) {
    return formatWeekday(day)
  }
}
